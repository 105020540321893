import axios from 'axios';

async function BaseRequest(url: string, param?: FormData, headersOption?: any, type?: 'get' | 'post') {
  const baseURL = 'https://backend.shoshanim.net/api/';
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, headersOption },
  };
  if (type === 'get')
    return axios
      .get(`${baseURL}${url}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

  return axios
    .post(`${baseURL}${url}`, param, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export { BaseRequest };

export default BaseRequest;
