import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import styles from './styles.module.css';
import { QuestionCard } from './question-card';
import type { FormResponses } from './types';

export const selectStudentIDtitle = 'בחירת מספר זיהוי';

type SelectStudentIdProps = {
  callback: (studentid: string) => void;
  initialFormResponses?: FormResponses;
  availableStudentsID?: number[];
};

export function SelectStudentID({ callback, initialFormResponses, availableStudentsID }: SelectStudentIdProps) {
  const [studentId, setStudentId] = useState(initialFormResponses?.studentId || '');

  const handleStudentIdSelect = (event: any) => {
    setStudentId(event.target.value);
    callback(event.target.value);
  };

  return (
    <QuestionCard title="מספר ילד/ה">
      <FormControl component="fieldset" variant="outlined" className={styles.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">מספר ילד/ה</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={studentId}
          onChange={handleStudentIdSelect}
          label="מספר ילד/ה"
        >
          {availableStudentsID?.map((value, index) => (
            <MenuItem key={`${index}-${value}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </QuestionCard>
  );
}
