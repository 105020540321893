import { ChildData } from './PersonalData';
export function MedicalData() {
  const arr: ChildData[] = [
    {
      type: 'radio',
      id: 'medication',
      lable: 'האם הילד מקבל תרופות כרגע ?',
      condition: false,
      value: null,
      childrenLables: [
        { id: 'medicationYes', lable: 'כן' },
        { id: 'medicationNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'medicationKind',
      lable: 'אלו תרופות ?',
      condition: true,
      conditionById: ['medication'],
      conditionByIdValueRadio: ['medicationYes'],
    },
    {
      type: 'textArea',
      id: 'medicationReasons',
      lable: 'מאלו סיבות ?',
      condition: true,
      conditionById: ['medication'],
      conditionByIdValueRadio: ['medicationYes'],
    },
    {
      type: 'textArea',
      id: 'medicationCommentary',
      lable: 'הערות',
      condition: true,
      conditionById: ['medication'],
      conditionByIdValueRadio: ['medicationYes'],
    },
    {
      type: 'textArea',
      id: 'cMedicalHistory',
      lable: 'רקע רפואי של הילד',
      condition: false,
    },

    // {
    //   type: "textArea",
    //   id: "fMedicalHistory",
    //   lable: "רקע רפואי של קרובי המשפחה",
    //   condition: false,
    // },
    {
      type: 'radio',
      id: 'neurologist',
      lable: 'האם הילד הופנה לנוירולוג ?',
      condition: false,
      childrenLables: [
        { id: 'neurologistYes', lable: 'כן' },
        { id: 'neurologistNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'neurologistName',
      lable: 'שם הנוירולוג',
      kind: 'text',
      condition: true,
      conditionById: ['neurologist'],
      conditionByIdValueRadio: ['neurologistYes'],
    },
    {
      type: 'text',
      id: 'neurologistReason',
      lable: 'מהי האבחנה ?',
      kind: 'text',
      condition: true,
      conditionById: ['neurologist'],
      conditionByIdValueRadio: ['neurologistYes'],
    },
    {
      type: 'radio',
      id: 'epileptic',
      lable: 'האם הילד אי פעם עבר עוויתות או התקפים אפילפטיים ?',
      condition: false,
      childrenLables: [
        { id: 'epilepticYes', lable: 'כן' },
        { id: 'epilepticNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'epilepticToday',
      lable: 'האם מתמשך גם כיום ?',
      condition: true,
      conditionById: ['epileptic'],
      conditionByIdValueRadio: ['epilepticYes'],
    },
    {
      type: 'text',
      id: 'epilepticDoctor',
      lable: 'שם הנוירולוג',
      condition: true,
      conditionById: ['epileptic'],
      conditionByIdValueRadio: ['epilepticYes'],
    },
    {
      type: 'radio',
      id: 'epilepticMedication',
      lable: 'האם הוא נמצא במעקב רפואי ?',
      condition: true,
      conditionById: ['epileptic'],
      conditionByIdValueRadio: ['epilepticYes'],
      childrenLables: [
        { id: 'epilepticMedicationYes', lable: 'כן' },
        { id: 'epilepticMedicationNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'epilepticMedicationDetails',
      lable: 'נא לפרט',
      condition: true,
      conditionById: ['epilepticMedication'],
      conditionByIdValueRadio: ['epilepticMedicationYes'],
    },

    // {
    //   type: "text",
    //   id: "epilepticAge",
    //   lable: "באיזה גיל ?",
    //   condition: true,
    //   conditionById: ["epileptic"],
    //   conditionByIdValueRadio: ['epilepticYes'],
    //   kind: "number",
    // },
    // {
    //   type: "radio",
    //   id: "tillNow",
    //   lable: "עדיין היום ?",
    //   condition: true,
    //   conditionById: ["epileptic"],
    //   conditionByIdValueRadio: ['epilepticYes'],
    //   childrenLables: [
    //     { id: "tillNowYes", lable: "כן"},
    //     { id: "tillNowNo", lable: "לא" },
    //   ],
    // },
    // {
    //   type: "text",
    //   id: "epilepticFrequency",
    //   lable: "תדירות",
    //   kind: "text",
    //   condition: true,
    //   conditionById: ["epileptic"],
    //   conditionByIdValueRadio: ['epilepticYes'],
    // },
    // {
    //   type: "textArea",
    //   id: "epilepticComments",
    //   lable: "הערות",
    //   condition: false,
    // },

    // New questions
    {
      type: 'radio',
      id: 'hospitalized',
      lable: 'האם הילד אושפז בבי"ח/מוסד רפואי ?',
      childrenLables: [
        { id: 'hospitalizedYes', lable: 'כן' },
        { id: 'hospitalizedNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'text',
      id: 'whyHospitalized',
      lable: 'סיבה',
      condition: true,
      conditionById: ['hospitalized'],
      conditionByIdValueRadio: ['hospitalizedYes'],
    },
    {
      type: 'text',
      id: 'whenHospitalized',
      lable: 'באיזה גיל ?',
      condition: true,
      conditionById: ['hospitalized'],
      conditionByIdValueRadio: ['hospitalizedYes'],
    },
    {
      type: 'text',
      id: 'hospitalizedInfluence',
      lable: 'הערות',
      condition: true,
      conditionById: ['hospitalized'],
      conditionByIdValueRadio: ['hospitalizedYes'],
    },
    {
      type: 'radio',
      id: 'doctorVerified',
      lable: 'האם הילד נבדק בשנתיים האחרונות על ידי רופאים מומחים ?',
      childrenLables: [
        { id: 'doctorVerifiedYes', lable: 'כן' },
        { id: 'doctorVerifiedNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'text',
      id: 'doctorVerifiedDomain',
      lable: 'תחום הרופא',
      condition: true,
      conditionById: ['doctorVerified'],
      conditionByIdValueRadio: ['doctorVerifiedYes'],
    },
    {
      type: 'text',
      id: 'doctorVerifiedReason',
      lable: 'סיבה לפניה',
      condition: true,
      conditionById: ['doctorVerified'],
      conditionByIdValueRadio: ['doctorVerifiedYes'],
    },
    // {
    //   type: "radio",
    //   id: "childInjured",
    //   lable: 'האם הילד נפגע בתאונה כלשהי ?',
    //   childrenLables: [
    //     { id: "childInjuredYes", lable: "כן" },
    //     { id: "childInjuredNo", lable: "לא" },
    //   ],
    //   condition: false,
    // },
    // {
    //   type: "text",
    //   id: "childInjuredWhen",
    //   lable: "מתי?",
    //   condition: true,
    //   conditionById: ["childInjured"],
    //   conditionByIdValueRadio: ["childInjuredYes"],
    // },
    // {
    //   type: "text",
    //   id: "childInjuredResult",
    //   lable: "מה הן תוצאות הפגיעה ?",
    //   condition: true,
    //   conditionById: ["childInjured"],
    //   conditionByIdValueRadio: ["childInjuredYes"],
    // },
    {
      type: 'label',
      lable: '? האם הילד סובל או סבל מהמחלות או התסמינים הבאים',
      condition: false,
    },
    {
      type: 'radio',
      id: 'firstDiseaseQuestion',
      lable:
        'כאבי ראש כרוניים, מחלות המוח, מחלות עצבים, אפילפסיה, שיתוקים, הפרעות בתחושה, סחרחורות או התעלפויות, אובדן הכרה, דיכאון, או הפרעה נפשית אחרת / התאבדות',
      childrenLables: [
        { id: 'firstDiseaseQuestionYes', lable: 'כן' },
        { id: 'firstDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'firstDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['firstDiseaseQuestion'],
      conditionByIdValueRadio: ['firstDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'firstDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['firstDiseaseQuestion'],
      conditionByIdValueRadio: ['firstDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'secondDiseaseQuestion',
      lable:
        'שחפת ריאתית, אסטמה, שיעול ממושך, דלקות ריאה חוזרות, ברונכיטיס כרונית, אמפיזמה, מחלות קרום הריאה ,גניחת דם',
      childrenLables: [
        { id: 'secondDiseaseQuestionYes', lable: 'כן' },
        { id: 'secondDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'secondDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['secondDiseaseQuestion'],
      conditionByIdValueRadio: ['secondDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'secondDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['secondDiseaseQuestion'],
      conditionByIdValueRadio: ['secondDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'thirdDiseaseQuestion',
      lable:
        'מחלות לב וכלי דם, דפיקות לב או הפרעות בקצב הלב, לחצים/כאבים/לחץ בחזה, לחץ דם גבוה, נפיחות הרגליים, כאבי שוקיים בהליכה',
      childrenLables: [
        { id: 'thirdDiseaseQuestionYes', lable: 'כן' },
        { id: 'thirdDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'thirdDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['thirdDiseaseQuestion'],
      conditionByIdValueRadio: ['thirdDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'thirdDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['thirdDiseaseQuestion'],
      conditionByIdValueRadio: ['thirdDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'fourthDiseaseQuestion',
      lable:
        'הפרעות בבליעה, צרבת, כיב קיבה או תריסריון, מחלות מעיים, דימום מדרכי העיכול, מחלות כיס מרה, הקאה חוזרת, דם סמוי בצואה, חולה/נשא של צהבת נגיפית מסוג A, B, C',
      childrenLables: [
        { id: 'fourthDiseaseQuestionYes', lable: 'כן' },
        { id: 'fourthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'fourthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['fourthDiseaseQuestion'],
      conditionByIdValueRadio: ['fourthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'fourthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['fourthDiseaseQuestion'],
      conditionByIdValueRadio: ['fourthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'fifthDiseaseQuestion',
      lable: 'מחלות עיניים, הפרעות ראיה, מחלות אף, אוזן, גרון, סינוסיטיס, ירידה בכושר השמיעה, צרידות כרונית',
      childrenLables: [
        { id: 'fifthDiseaseQuestionYes', lable: 'כן' },
        { id: 'fifthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'fifthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['fifthDiseaseQuestion'],
      conditionByIdValueRadio: ['fifthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'fifthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['fifthDiseaseQuestion'],
      conditionByIdValueRadio: ['fifthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'sixthDiseaseQuestion',
      lable: 'רגישות יתר (אלרגיה) כולל לתרופות, סוגי מזונות וגורמים מגרים אחרים ',
      childrenLables: [
        { id: 'sixthDiseaseQuestionYes', lable: 'כן' },
        { id: 'sixthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'sixthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['sixthDiseaseQuestion'],
      conditionByIdValueRadio: ['sixthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'sixthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['sixthDiseaseQuestion'],
      conditionByIdValueRadio: ['sixthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'seventhDiseaseQuestion',
      lable: 'מחלות או זיהומים בדרכי השתן, דם, סוכר או חלבון בשתן, הגדלת הפרוסטטה, הפרעות במתן השתן',
      childrenLables: [
        { id: 'seventhDiseaseQuestionYes', lable: 'כן' },
        { id: 'seventhDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'seventhDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['seventhDiseaseQuestion'],
      conditionByIdValueRadio: ['seventhDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'seventhDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['seventhDiseaseQuestion'],
      conditionByIdValueRadio: ['seventhDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'eighthDiseaseQuestion',
      lable: 'מחלות עור, פצע שאיננו מתרפא, שינויים בנקודות חן או יבלות לאחרונה',
      childrenLables: [
        { id: 'eighthDiseaseQuestionYes', lable: 'כן' },
        { id: 'eighthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'eighthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['eighthDiseaseQuestion'],
      conditionByIdValueRadio: ['eighthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'eighthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['eighthDiseaseQuestion'],
      conditionByIdValueRadio: ['eighthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'ninthDiseaseQuestion',
      lable: 'מחלות חילוף חומרים או בלוטות, סוכרת, שומנים בדם',
      childrenLables: [
        { id: 'ninthDiseaseQuestionYes', lable: 'כן' },
        { id: 'ninthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'ninthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['ninthDiseaseQuestion'],
      conditionByIdValueRadio: ['ninthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'ninthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['ninthDiseaseQuestion'],
      conditionByIdValueRadio: ['ninthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'tenthDiseaseQuestion',
      lable: 'הרניה, אנמיה, הפרעות קרישה או המוליזה, פוליציטמיה, המופיליה',
      childrenLables: [
        { id: 'tenthDiseaseQuestionYes', lable: 'כן' },
        { id: 'tenthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'tenthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['tenthDiseaseQuestion'],
      conditionByIdValueRadio: ['tenthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'tenthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['tenthDiseaseQuestion'],
      conditionByIdValueRadio: ['tenthDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'eleventhDiseaseQuestion',
      lable: ' כאבי גב, כאבי צוואר, מגבלות בתנועה והפעלת עמוד השדרה, הפרעות בתנועה',
      childrenLables: [
        { id: 'eleventhDiseaseQuestionYes', lable: 'כן' },
        { id: 'eleventhDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'eleventhDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['eleventhDiseaseQuestion'],
      conditionByIdValueRadio: ['eleventhDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'eleventhDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['eleventhDiseaseQuestion'],
      conditionByIdValueRadio: ['eleventhDiseaseQuestionYes'],
    },
    {
      type: 'radio',
      id: 'twelfthDiseaseQuestion',
      lable: 'הפרעות אחרות שמקורן במחלות אחרות שלא פורטו לעיל, או מגבלות בריאותיות אחרות',
      childrenLables: [
        { id: 'twelfthDiseaseQuestionYes', lable: 'כן' },
        { id: 'twelfthDiseaseQuestionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'twelfthDiseaseQuestion_Start',
      lable: 'פרט מתי התחיל',
      condition: true,
      conditionById: ['twelfthDiseaseQuestion'],
      conditionByIdValueRadio: ['twelfthDiseaseQuestionYes'],
    },
    {
      type: 'textArea',
      id: 'twelfthDiseaseQuestion_Kind',
      lable: 'סוג המחלה והשפעה',
      condition: true,
      conditionById: ['twelfthDiseaseQuestion'],
      conditionByIdValueRadio: ['twelfthDiseaseQuestionYes'],
    },
    // {
    //   type: "textArea",
    //   id: "visualDisturbanceKinds",
    //   lable: "אם כן : סוג מחלה ? מתי התחיל ? השפעה ?",
    //   condition: false,
    // },

    {
      type: 'radio',
      id: 'visualDisturbance',
      lable: 'האם לילד יש קושי בראייה ?',
      condition: false,
      childrenLables: [
        { id: 'visualDisturbanceYes', lable: 'כן' },
        { id: 'visualDisturbanceNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'visualDisturbanceKinds',
      lable: 'מהן ?',
      condition: true,
      conditionById: ['visualDisturbance'],
      conditionByIdValueRadio: ['visualDisturbanceYes'],
    },
    {
      type: 'text',
      id: 'visualDisturbanceAge',
      lable: 'מאיזה גיל ?',
      condition: true,
      conditionById: ['visualDisturbance'],
      conditionByIdValueRadio: ['visualDisturbanceYes'],
    },
    {
      type: 'textArea',
      id: 'visualDisturbanceToday',
      lable: 'האם נמשך גם כיום ?',
      condition: true,
      conditionById: ['visualDisturbance'],
      conditionByIdValueRadio: ['visualDisturbanceYes'],
    },
    {
      type: 'textArea',
      id: 'visualDisturbanceComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'hearingDisturbance',
      lable: 'האם לילד יש לקוי שמיעה ?',
      condition: false,
      childrenLables: [
        { id: 'hearingDisturbanceYes', lable: 'כן' },
        { id: 'hearingDisturbanceNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'hearingDisturbanceKinds',
      lable: 'אלו ?',
      condition: true,
      conditionById: ['hearingDisturbance'],
      conditionByIdValueRadio: ['hearingDisturbanceYes'],
    },
    {
      type: 'textArea',
      id: 'hearingDisturbanceComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'ENTProblems',
      lable: 'האם לילד יש קושי באף אוזן גרון ?',
      condition: false,
      childrenLables: [
        { id: 'ENTProblemsYes', lable: 'כן' },
        { id: 'ENTProblemsNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'ENTProblemsFrequency',
      lable: 'תדירות',
      kind: 'text',
      condition: true,
      conditionById: ['ENTProblems'],
      conditionByIdValueRadio: ['ENTProblemsYes'],
    },
    {
      type: 'textArea',
      id: 'ENTProblemsKinds',
      lable: 'מהן ?',
      condition: true,
      conditionById: ['ENTProblems'],
      conditionByIdValueRadio: ['ENTProblemsYes'],
    },
    {
      type: 'textArea',
      id: 'ENTProblemsToday',
      lable: 'האם נמשך גם כיום ?',
      condition: true,
      conditionById: ['ENTProblems'],
      conditionByIdValueRadio: ['ENTProblemsYes'],
    },
    {
      type: 'textArea',
      id: 'ENTProblemsComments',
      lable: 'הערות',
      condition: false,
    },

    {
      type: 'label',
      lable: 'מידע על שינת הילד',
      condition: false,
    },
    {
      type: 'radio',
      id: 'troubleSleeping',
      lable: 'האם הילד מתקשה לישון ?',
      childrenLables: [
        { id: 'troubleSleepingYes', lable: 'כן' },
        { id: 'troubleSleepingSometimes', lable: 'לפעמים' },
        { id: 'troubleSleepingNo', lable: 'לא' },
      ],
      condition: false,
    },
    // {
    //   type: "textArea",
    //   id: "troubleSleepingKind",
    //   lable: "אלו?",
    //   condition: true,
    //   conditionById: ["troubleSleeping"],
    //   conditionByIdValueRadio: [
    //     "troubleSleepingYes",
    //     "troubleSleepingSometimes",
    //   ],
    // },
    {
      type: 'textArea',
      id: 'troubleSleepingComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['troubleSleeping'],
      conditionByIdValueRadio: ['troubleSleepingYes', 'troubleSleepingSometimes'],
    },
    {
      type: 'radio',
      id: 'escortSleeping',
      lable: 'האם הילד צריך ליווי לשינה ?',
      childrenLables: [
        { id: 'escortSleepingYes', lable: 'כן' },
        { id: 'escortSleepingSometimes', lable: 'לפעמים' },
        { id: 'escortSleepingNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'escortSleepingComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['escortSleeping'],
      conditionByIdValueRadio: ['escortSleepingYes', 'escortSleepingSometimes'],
    },
    {
      type: 'radio',
      id: 'sleepWithParents',
      lable: 'האם הילד יושן עם הוריו ?',
      childrenLables: [
        { id: 'sleepWithParentsYes', lable: 'כן' },
        { id: 'sleepWithParentsSometimes', lable: 'לפעמים' },
        { id: 'sleepWithParentsNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'sleepWithParentsComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['sleepWithParents'],
      conditionByIdValueRadio: ['sleepWithParentsYes', 'sleepWithParentsSometimes'],
    },
    {
      type: 'radio',
      id: 'sleepWithBrothers',
      lable: 'האם הילד יושן עם אחים/אחיות בחדר ?',
      childrenLables: [
        { id: 'sleepWithBrothersYes', lable: 'כן' },
        // { id: "sleepWithBrothersSometimes", lable: "לפעמים" },
        { id: 'sleepWithBrothersNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'sleepWithBrothersComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['sleepWithBrothers'],
      conditionByIdValueRadio: [
        'sleepWithBrothersYes',
        // "sleepWithBrothersSometimes",
      ],
    },

    {
      type: 'text',
      id: 'timeSleeping',
      lable: 'באיזו שעה הילד הולך לישון ?',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'timeToSleep',
      lable: 'כמה זמן לוקח לו להרדם ?',
      condition: false,
      kind: 'text',
    },
    {
      type: 'text',
      id: 'timeWakeUp',
      lable: 'באיזו שעה הוא קם ?',
      kind: 'text',
      condition: false,
    },
    {
      type: 'radio',
      id: 'wakeUpMood',
      lable: 'האם הילד נמצא במצב רוח טוב כשהוא קם ?',
      condition: false,
      childrenLables: [
        { id: 'wakeUpMoodYes', lable: 'כן' },
        { id: 'wakeUpMoodNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'howLongWakeUp',
      lable: 'כמה זמן לוקח לו ליהיות ער לגמרי ?',
      condition: true,
      conditionById: ['wakeUpMood'],
      conditionByIdValueRadio: ['wakeUpMoodYes'],
      conditionByIdValue: false,
    },
    {
      type: 'textArea',
      id: 'wakeUpMoodComments',
      lable: 'הערות',
      condition: false,
    },

    // {
    //   type: "label",
    //   lable: "מידע על האכילת הילד",
    //   condition: false,
    // },
    // {
    //   type: "radio",
    //   id: "eatingDisorder",
    //   lable: "האם לילד יש הפרעות באכילה ?",
    //   condition: false,
    //   childrenLables: [
    //     { id: "eatingDisorderYes", lable: "כן" },
    //     { id: "eatingDisorderNo", lable: "לא" },
    //   ],
    // },
    // {
    //   type: "textArea",
    //   id: "eatingDisorderKinds",
    //   lable: "אלו ?",
    //   condition: true,
    //   conditionById: ["eatingDisorder"],
    //   conditionByIdValueRadio: ["eatingDisorderYes"],
    // },
  ];
  return arr;
}
