import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Checkbox,
  // Button,
  FormLabel,
  TextField,
  FormHelperText,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Course from '../models/Course';
import BaseRequest from '../helpers/BaseRequest';
import { AutoCompleteList } from './AddCourse';
import { CustomAutoComplete } from './custom-autocomplete';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { getRole, Roles } from './utils/roles';
import { getUser } from './utils/user';
import { ConfirmationModal } from './confirmation-modal';
import { useBranches } from '../branches/use-branches';
import { Button } from './buttons/button';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  txt: {
    width: '100%',
    direction: 'ltr',
  },
  width: {
    width: '100%',
  },
}));

interface EditCourseProps {
  course: Course;
  ok: Function;
}

export default function EditCourse(props: EditCourseProps) {
  const classes = useStyles();
  const cancellationFee = ['ללא תשלום', ' 100% תשלום'];
  // const cancellationFeeMetapel = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(props.course.date_cours ? props.course.date_cours + 'T' + props.course.hour_cours : ''),
  );
  const [checkCancelCourse, setCheckCancelCourse] = React.useState(props.course.batel == '1' ? true : false);
  // const [cancellationFees, setCancellationFees] = useState('0');
  const [course, setCourse] = useState({ ...props.course });
  const [hitpatchout, setHitpatchout] = useState<any>(props.course.hitpatchout);
  const [teachers, setTeachers] = useState<AutoCompleteList[]>([]);
  const [students, setStudents] = useState<AutoCompleteList[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>(props.course.yeled_tachloum);
  const [selectValueMetapel, setSelectValueMetapel] = useState<any>(props.course.metapel_tachloum);
  const [batelComment, setBatelComment] = useState<any>(props.course.batel_comment);
  const [chiourComment, setChiourComment] = useState(props.course.chiour_comment);
  const [selectedClass, setSelectedClass] = useState<any>(props.course.id_salle);
  const [tarifCours, setTarifCours] = useState<any>(props.course.tarif_cours);
  // Si עלות מיוחדת
  const [tachloumMichpacha, setTachloumMichpacha] = useState<any>(props.course.tachloum_mishpaha);
  const [tachloumShoshanim, setTachloumShoshanim] = useState<any>(props.course.tachloum_shoshanim);
  const [tachloumLeumit, setTachloumLeumit] = useState<any>(props.course.tachloum_leumit);
  const [tachloumRevacha, setTachloumRevacha] = useState<any>(props.course.tachloum_revacha);
  const [tachloumIriya, setTachloumIriya] = useState<any>(props.course.tachloum_iriya);
  const [salle, setSalle] = useState<any[]>([]);
  const hitpatchoutOptions = [
    { label: 'כן', value: '1' },
    { label: 'לא', value: '0' },
  ];
  const [hourCours, setHourCours] = useState<any>(props.course.hour_cours);
  const [child, setChild] = useState<AutoCompleteList>({
    id: props.course.id_elev,
    firstName: props.course.eleve_name,
    lastName: props.course.eleve_lastname,
  });
  const [teacher, setTeacher] = useState<AutoCompleteList>({
    id: props.course.id_interv,
    firstName: props.course.interv_prenom,
    lastName: props.course.interv_nom,
  });
  const [errors, setErrors] = useState({
    id_interv: false,
    id_elev: false,
    date_cours: false,
    hour_cours: false,
    kavoua: false,
    id_salle: false,
    hitpatchout: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const location = useLocation();
  let pathname = location.pathname; // history.location.pathname;
  const numberCours = `${props.course.number_cours}/${props.course.total_number_cours}`;
  const userRole = getRole();
  const user = getUser();
  const { branchesList } = useBranches();
  const [selectedBrancheId, setSelectedBrancheId] = useState(
    props.course.branche_id === null ? '0' : props.course.branche_id,
  );
  const [isEditLoading, setEditLoading] = useState(false);

  useEffect(() => {
    getTeachers();
    getStudents();
  }, []);

  // useEffect(() => {
  //   if (selectedBranche?.id) setSelectedBrancheId(selectedBranche?.id);
  // }, [selectedBranche?.id]);

  const getStudents = () => {
    BaseRequest('getStudent')
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({
            id: item.id,
            value: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
          });
        });
        setStudents(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getClasses = useCallback(async () => {
    await BaseRequest('getSalle').then((res) => {
      const result: any[] = res.data;
      setSalle(result.filter((salle) => (salle.branche_id !== null ? salle.branche_id === selectedBrancheId : true)));
    });
  }, [selectedBrancheId]);

  useEffect(() => {
    getClasses();
  }, [selectedBrancheId, getClasses]);

  const getTeachers = () => {
    BaseRequest('getIntervenant')
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({
            id: item.id_interv,
            value: `${item.prenom} ${item.nom}`,
            firstName: item.prenom,
            lastName: item.nom,
          });
        });
        setTeachers(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const handleChangex = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setCancellationFees((event.target as HTMLInputElement).value);
  // };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleHourChange = (e: any) => {
    setHourCours(e.target.value);
  };

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  const editCourse = async () => {
    let allValid = true;
    var final_date = '';
    if (new Date(selectedDate || '').toString() != 'Invalid Date') {
      var curr = new Date(selectedDate ? selectedDate : '');
      final_date =
        curr.getFullYear() + '-' + appendLeadingZeroes(curr.getMonth() + 1) + '-' + appendLeadingZeroes(curr.getDate());
    }

    let updateCourse: any;
    const obj: Course = {
      id_cours: props.course.id_cours,
      id_interv: teacher?.id,
      id_elev: child?.id,
      date_cours: final_date,
      // hour_cours: selectedDate ? selectedDate.toLocaleTimeString() : "",
      hour_cours: hourCours,
      kavoua: course.kavoua ? course.kavoua : '',
      id_salle: selectedClass,
      // @ts-ignore
      branche_id: selectedBrancheId === '0' ? null : selectedBrancheId,
      tarif_cours: tarifCours,
      tachloum_mishpaha: tachloumMichpacha,
      tachloum_shoshanim: tachloumShoshanim,
      tachloum_leumit: tachloumLeumit,
      tachloum_revacha: tachloumRevacha,
      tachloum_iriya: tachloumIriya,
      metapel_tachloum: selectValueMetapel,
      yeled_tachloum: selectedValue,
      batel: checkCancelCourse == true ? '1' : '0',
      batel_comment: batelComment,
      hitpatchout: hitpatchout,
      chiour_comment: chiourComment,
    };

    updateCourse = { ...obj };
    let keys = Object.keys(errors);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (!updateCourse[k]) {
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    if (allValid) {
      setEditLoading(true);
      const formData = new FormData();
      formData.append('data', JSON.stringify(updateCourse));
      await BaseRequest('UpdateCours', formData)
        .then((res: any) => {
          console.log({ res });
        })
        .finally(() => {
          setEditLoading(false);
          props.ok();
        });
    } else setErrors(errorsHelper);
    // if (pathname === '/home') {
    //   props.ok();
    // }
  };

  const deleteChiour = () => {
    setLoadingDelete(true);
    const obj = {
      course_id: props.course.id_cours,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    BaseRequest('DeleteChiour', formData)
      .then((res: any) => {
        if (res.success == 1) {
          props.ok();
        }
      })
      .finally(() => setLoadingDelete(false));
  };

  return (
    <Grid spacing={3} direction="column" container>
      <Grid container justifyContent="center" item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            עידכון טיפול
          </Typography>
        </Grid>
      </Grid>
      <Chip label={`טיפול מספר ${numberCours}`} size="small" />
      <Grid item xs={12}>
        <FormControl color="primary" variant="outlined" className={classes.formControl}>
          <CustomAutoComplete
            label="שם המטפל"
            data={teachers}
            setValue={setTeacher}
            value={course.id_interv}
            isLabelShrink={false}
            isError={errors['id_interv']}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl color="primary" variant="outlined" className={classes.formControl}>
          <CustomAutoComplete
            label="שם הילד"
            data={students}
            value={course && course.id_elev}
            setValue={setChild}
            isLabelShrink={false}
            isError={errors['id_elev']}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            PopoverProps={{ dir: 'rtl' }}
            className={classes.width}
            color="primary"
            inputVariant="outlined"
            margin="normal"
            id="date-picker-dialog-outlined"
            label="תאריך"
            format="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            variant="inline"
            error={!selectedDate && errors.date_cours}
            helperText={!selectedDate && errors.date_cours ? 'תאריך חובה' : ''}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="time"
          label="שעה"
          type="time"
          defaultValue={hourCours}
          onChange={handleHourChange}
          className={classes.width}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          error={!hourCours && errors.hour_cours}
          helperText={!hourCours && errors.hour_cours ? 'שעה חובה' : ''}
        />
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            PopoverProps={{ dir: 'rtl' }}
            color="primary"
            inputVariant="outlined"
            className={classes.width}
            variant="inline"
            margin="normal"
            id="time-picker"
            label="שעה"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            error={!selectedDate && errors.hour_cours}
            helperText={!selectedDate && errors.hour_cours ? "שעה חובה" : ""}
            autoOk={true}
          />
        </MuiPickersUtilsProvider> */}
      </Grid>
      {localStorage.getItem('role') != '2' && (
        <Grid item xs={12}>
          <TextField
            className={classes.txt}
            id="outlined-basic"
            label="עלות מיוחדת"
            value={tarifCours}
            onChange={(e) => {
              setTarifCours(e.target.value);
            }}
            type="number"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faShekelSign} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}

      {parseFloat(tarifCours) > 0 && tarifCours.toString() !== '' && (
        <>
          <Grid item xs={12}>
            <TextField
              className={classes.txt}
              id="outlined-basic"
              label="תשלום מהמשפחה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumMichpacha}
              onChange={(e) => setTachloumMichpacha(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.txt}
              id="outlined-basic"
              label="הנחת שושנים בשקלים"
              type="number"
              variant="outlined"
              value={tachloumShoshanim}
              onChange={(e) => setTachloumShoshanim(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.txt}
              id="outlined-basic"
              label="הנחת תכנית הלאומית בשקלים"
              type="number"
              variant="outlined"
              value={tachloumLeumit}
              onChange={(e) => setTachloumLeumit(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.txt}
              id="outlined-basic"
              label="הנחת רווחה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumRevacha}
              onChange={(e) => setTachloumRevacha(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.txt}
              id="outlined-basic"
              label="הנחת ערייה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumIriya}
              onChange={(e) => setTachloumIriya(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>סניף</InputLabel>
          <Select
            value={selectedBrancheId}
            onChange={(e) => setSelectedBrancheId(e.target.value as string)}
            style={{ textAlign: 'right' }}
          >
            <MenuItem style={{ direction: 'rtl' }} value="0">
              לא הוגדר סניף
            </MenuItem>
            {branchesList?.map((branche, index) => (
              <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
                {branche.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className="fieldSelect">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ color: errors.id_salle && !selectedClass ? '#f44336' : '' }}
            id="demo-simple-select-outlined-label"
          >
            חדר
          </InputLabel>
          <Select
            // className="rtl"
            onChange={(e) => {
              setSelectedClass(e.target.value);
            }}
            label="חדר"
            value={selectedClass}
            error={errors.id_salle && !selectedClass}
            style={{ textAlign: 'right' }}
          >
            {salle.map((s, idx) => {
              return (
                <MenuItem style={{ display: 'flex', flexDirection: 'row-reverse' }} key={idx} value={s.id_salle}>
                  {s.salle}
                </MenuItem>
              );
            })}
          </Select>
          {errors.id_salle && !selectedClass && <FormHelperText error={true}>חדר שדה חובה</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} className="fieldSelect">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ color: errors.hitpatchout && !hitpatchout ? '#f44336' : '' }}
            id="demo-simple-select-outlined-label"
          >
            התפתחות הילד
          </InputLabel>
          <Select
            // className="rtl"
            onChange={(e) => {
              setHitpatchout(e.target.value);
            }}
            label="התפתחות הילד"
            value={hitpatchout}
            error={errors.hitpatchout && !hitpatchout}
          >
            {hitpatchoutOptions.map((s, idx) => {
              return (
                <MenuItem style={{ display: 'flex', flexDirection: 'row-reverse' }} key={idx} value={s.value}>
                  {s.label}
                </MenuItem>
              );
            })}
          </Select>
          {errors.hitpatchout && !hitpatchout && <FormHelperText error={true}>התפתחות הילד שדה חובה</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="הערה כללית על הטיפול"
          variant="outlined"
          multiline
          value={chiourComment}
          onChange={(e) => setChiourComment(e.target.value)}
        />
      </Grid>
      {(user.role === Roles.Menahel || user.role === Roles.Secretaire) && (
        <Grid container xs={12} justifyContent="center">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkCancelCourse}
                  onChange={() => {
                    setCheckCancelCourse(!checkCancelCourse);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="ביטול טיפול"
            />
          </Grid>
        </Grid>
      )}
      {checkCancelCourse ? (
        <Grid
          direction="row"
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ direction: 'rtl' }}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{ fontWeight: 'bold', display: 'flex' }}>
              ילד
            </FormLabel>
            <FormGroup row>
              {cancellationFee.map((a, idx) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedValue == a}
                        onChange={(e) => {
                          setSelectedValue(e.target.value);
                        }}
                        color="primary"
                        value={a}
                      />
                    }
                    label={a}
                  />
                );
              })}
            </FormGroup>
          </FormControl>

          {/* <FormControl className='maxWidth' color="primary" variant="outlined" style={{marginTop: '15px', marginBottom: '15px'}}>
              <InputLabel id="מטפל">
                מטפל      
              </InputLabel>
              <Select
                labelId="מטפל"
                label="מטפל"
                value={selectValueMetapel}
                onChange={ (e) => { setSelectValueMetapel(e.target.value) } }
              >
                {cancellationFeeMetapel.map((p:string, idx:number) => { 
                  return <MenuItem key={idx} value={p}>{p}</MenuItem> 
                })}
              </Select>
          </FormControl> */}
          <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="מטפל"
              variant="outlined"
              value={selectValueMetapel}
              onChange={(e) => {
                setSelectValueMetapel(e.target.value);
              }}
            />
          </Grid>

          <Grid xs={12}>
            <TextField
              className={classes.txt}
              variant="outlined"
              placeholder="הערות"
              multiline
              value={batelComment}
              onChange={(e) => {
                setBatelComment(e.target.value);
              }}
            ></TextField>
          </Grid>
        </Grid>
      ) : null}

      {(user.role === Roles.Menahel || user.role === Roles.Secretaire) && (
        <Grid container xs={12} justifyContent="center" style={{ marginBottom: '10px' }}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => setOpenDeleteModal(true)}
              style={{ backgroundColor: 'rgb(220, 0, 78)', color: '#fff', width: '400px' }}
            >
              למחוק טיפול
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid spacing={1} item container alignItems="flex-start" justifyContent="flex-start" direction="row" xs={12}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={editCourse} loading={isEditLoading}>
            אישור
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              props.ok();
            }}
          >
            ביטול
          </Button>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onSubmit={deleteChiour}
        dangerButton
        title="מחיקת טיפול"
        text={<span>? האם אתה בטוח שברצונך למחוק את הטיפול</span>}
        loading={isLoadingDelete}
      />
    </Grid>
  );
}
