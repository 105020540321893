import { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import { DialogProps } from '@material-ui/core';
import { Button } from '../buttons/button';

type ConfirmationModalProps = {
  open?: boolean;
  title?: string;
  text?: ReactNode;
  submitText?: string;
  disabledSubmit?: boolean;
  dangerButton?: boolean;
  onSubmit?: () => void;
  cancelText?: string;
  onClose?: () => void;
  loading?: boolean;
} & DialogProps;

export function ConfirmationModal({
  open = false,
  title,
  text,
  submitText = 'כן',
  disabledSubmit,
  dangerButton,
  onSubmit,
  cancelText = 'לא',
  onClose,
  loading,
  ...rest
}: ConfirmationModalProps) {
  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      {title && <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>}
      <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <DialogContentText style={{ textAlign: 'right' }}>{text}</DialogContentText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          <Button onClick={onSubmit} danger={dangerButton} loading={loading} disabled={loading || disabledSubmit}>
            {submitText}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {cancelText}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
