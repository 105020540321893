import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { AutoCompleteList } from '../AddCourse';
import styles from './custom-autocomplete.module.css';

interface CustomAutoCompleteProps {
  label: string;
  data: any[];
  isLabelShrink?: boolean;
  value?: any;
  setValue?: Function;
  isError?: boolean;
}
export function CustomAutoComplete({ label, data, isLabelShrink, value, setValue, isError }: CustomAutoCompleteProps) {
  const [selected, setSelected] = useState<AutoCompleteList | string>('');
  const handleChange = (e: any, newValue: AutoCompleteList) => {
    setSelected(newValue);
    setValue?.(newValue);
  };

  useEffect(() => {
    if (value !== undefined) {
      let select = data.find((item) => item.id === value);
      setSelected(select);
    }
  }, [value, data]);

  return (
    <Autocomplete
      dir="rtl"
      classes={{ paper: styles['MuiAutocomplete-paper'] }}
      onChange={handleChange}
      id="combo-box-demo"
      options={data}
      getOptionLabel={(option) => (option ? option.value : '')}
      value={selected}
      renderInput={(params) => (
        <>
          {isLabelShrink ? (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              label={label}
              variant="outlined"
            />
          ) : (
            <TextField
              {...params}
              error={isError && !selected}
              helperText={isError && !selected ? `${label} חובה` : ''}
              label={label}
              variant="outlined"
            />
          )}
        </>
      )}
    />
  );
}
