import type { CategoryOfQuestions } from '../base-form';

export const categoryOne: CategoryOfQuestions = {
  id: 'category-one',
  title: 'היבטים למידתיים כלליים',
  questions: [
    { questionId: '1', questionTitle: 'הרגלי עבודה כמצופה: הבאת ציוד, הכנת שיעורי בית, התארגנות לקראת השיעור ובמהלכו' },
    {
      questionId: '2',
      questionTitle:
        'היבטים רגשיים אישיותיים: תפקוד חברתי,שיתוף פעולה עם ילדים/מבוגרים, התמודדות עם קשיים ומצבי לחץ, ביטחון עצמי',
    },
    {
      questionId: '3',
      questionTitle:
        'התנהגות למידתית: מידת מוטיבציה ללמידה, עצמאות בעבודה יחידנית/קבוצתית, סקרנות, עובד בצורה מאורגנת, מתוכננת ושיטתית',
    },
    { questionId: '4', questionTitle: 'קשב וריכוז: יכול להתרכז לאורך זמן' },
    { questionId: '5', questionTitle: 'קשב וריכוז: מסיים מטלות כנדרש' },
    { questionId: '6', questionTitle: 'קשב וריכוז: יושב כמצופה ממנו' },
    { questionId: '7', questionTitle: 'קשב וריכוז: ממתין לתורו' },
  ],
};

export const categoryTwo: CategoryOfQuestions = {
  id: 'category-two',
  title: 'שפה, הבעה ותקשורת',
  questions: [
    {
      questionId: '1',
      questionTitle: 'יוצר תקשורת באופן מילולי עם הסביבה, משוחח עם ילדים ומבוגרים, יוזם שיחה, שואל שאלות',
    },
    { questionId: '2', questionTitle: 'הבעה בע"פ (מדבר ועונה לעניין, ניסוח רעיונות, מבנה תחבירי)' },
    { questionId: '3', questionTitle: 'מבין הוראות מורכבות' },
    { questionId: '4', questionTitle: 'משתמש באוצר מילים תואם לגיל' },
  ],
};

export const categoryThree: CategoryOfQuestions = {
  id: 'category-three',
  title: 'קריאה',
  questions: [
    { questionId: '1', questionTitle: 'שליטה באבני היסוד (תנועה, צרופים, זיהוי מילים שלמות)' },
    { questionId: '2', questionTitle: 'רמת קריאה ביחס לגילו (מילים, משפטים, ספרים)' },
    { questionId: '3', questionTitle: 'הבנת הנקרא' },
  ],
};

export const categoryFour: CategoryOfQuestions = {
  id: 'category-four',
  title: 'כתיבה',
  questions: [
    { questionId: '1', questionTitle: 'איכות הכתיבה (כתב ברור, רווחים תקינים) וארגון הדף, קצב הכתיבה' },
    { questionId: '2', questionTitle: 'כתיבה ללא שגיאות' },
    { questionId: '3', questionTitle: 'הבעה בכתב (תוכן, ארגון, תחביר, ניסוח)' },
    { questionId: '4', questionTitle: 'הבעה בכתב ביחס לגיל (תוכן, ארגון, תחביר, ניסוח)' },
  ],
};

export const categoryFive: CategoryOfQuestions = {
  id: 'category-five',
  title: 'חשבון',
  questions: [
    { questionId: '1', questionTitle: 'הבנה חשבונית ביחס לגיל' },
    {
      questionId: '2',
      questionTitle: 'שליטה במיומנויות בסיסיות ביחס לגיל. (תפישה כמותית, סדרתית, מניה, ספירה, השוואה כמותית)',
    },
    { questionId: '3', questionTitle: 'שליטה והבנה ב 4 פעולות החשבון ביחס לגיל' },
    { questionId: '4', questionTitle: 'פטירת בעיות חשבוניות מורכבות ביחס לגיל' },
  ],
};
