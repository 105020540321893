import { Button as BaseButton } from '@material-ui/core';
import type { ButtonProps as BaseButtonProps } from '@material-ui/core';
import { Ring } from '@joshk/react-spinners-css.ring';

export type ButtonProps = {
  loading?: boolean;
  danger?: boolean;
} & BaseButtonProps;

export function Button({
  loading,
  danger,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  disabled,
  children,
  endIcon,
  startIcon,
  ...rest
}: ButtonProps) {
  return (
    <BaseButton
      variant={variant}
      color={color}
      size={size}
      disabled={disabled || loading}
      endIcon={!loading && endIcon}
      startIcon={!loading && startIcon}
      style={danger ? { backgroundColor: 'rgb(220, 0, 78)' } : undefined}
      {...rest}
    >
      {loading ? <Ring size={22} color="white" /> : children}
    </BaseButton>
  );
}
