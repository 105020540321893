import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  FormGroup,
  Typography,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormHelperText,
  Checkbox,
  Select,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import BaseRequest from '../helpers/BaseRequest';
import Meeting from '../models/Meeting';
import PeopleIcon from '@material-ui/icons/PeopleOutlineOutlined';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import ReactSelect from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { useBranches } from '../branches/use-branches';

const useStyles = makeStyles({
  select: {
    border: '1px solid red',
  },
  icon: {
    marginTop: 2,
  },
  erroeText: {
    marginRight: 12,
  },
  datePicker: {
    width: '100%',
    zIndex: 0,
  },
  grid: {
    direction: 'rtl',
  },
  okButton: {
    marginRight: '8px',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  width_input_time: {
    width: '100%',
  },
});

interface MeetingProps {
  ok: Function;
}

export default function AddMeeting(props: MeetingProps) {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>([]);
  const [salle, setSalle] = useState<any[]>([]);
  const [teacher, setTeacher] = useState([]);
  const [options, setOptions] = useState<Option[]>([]);
  const typesMeetingArr = ['שעה טכנית', 'ישיבת צוות'];
  const [errors, setErrors] = useState({
    sujet: false,
    date: false,
    hourMeeting: false,
    intervenant_list: false,
    id_salle: false,
    type_meeting: false,
  });
  const [subject, setSubject] = useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [selectedClass, setSelectedClass] = useState<any>('');
  const [typeMeeting, setTypeMeeting] = useState<any>('');
  const [hourMeeting, setHourMeeting] = useState('');
  const [tarifSpecial, setTarifSpecial] = useState('');
  const { branchesList, selectedBranche } = useBranches();
  const [selectedBrancheId, setSelectedBrancheId] = useState(selectedBranche?.id);

  useEffect(() => {
    getIntervenant();
  }, []);

  useEffect(() => {
    if (selectedBranche?.id) setSelectedBrancheId(selectedBranche?.id);
  }, [selectedBranche?.id]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleHourChange = (e: any) => {
    setHourMeeting(e.target.value);
  };

  const getClasses = useCallback(async () => {
    await BaseRequest('getSalle').then((res) => {
      const result: any[] = res.data;
      setSalle(result.filter((salle) => (salle.branche_id !== null ? salle.branche_id === selectedBrancheId : true)));
    });
  }, [selectedBrancheId]);

  useEffect(() => {
    getClasses();
  }, [selectedBrancheId, getClasses]);

  const getIntervenant = () => {
    BaseRequest('getIntervenant').then((res: any) => {
      let arr: any[] = [];
      res.data.forEach((item: any) => {
        arr.push({ label: `${item.nom} ${item.prenom}`, value: item });
      });
      setOptions(arr);
    });
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: 53,
      paddingButtom: 0,
      '&:hover': { borderColor: 'black' },
      '&:focus': { borderColor: '#214078' },
      '&:unFocus': { borderColor: '#214078' },
      borderColor: errors.intervenant_list && !selected.length ? 'red' : 'rgba(0, 0, 0, 0.23)',
    }),
  };

  const createReunion = () => {
    let allValid = true;
    var date: any;
    var curr = selectedDate ? new Date(selectedDate) : null;
    if (curr) {
      curr.setDate(curr.getDate());
      date = curr.toISOString().substr(0, 10);
    } else date = null;
    const t: string[] = [];
    selected.map((s) => {
      t.push(s.value.id_interv);
    });
    let meeting: any;
    const obj: Meeting = {
      intervenant_list: [...t],
      date: selectedDate?.toDateString() ? date : '',
      // heure: selectedDate?.toDateString() ? selectedDate?.toLocaleTimeString() : "",
      hourMeeting: hourMeeting,
      sujet: subject,
      id_salle: selectedClass,
      branche_id: selectedBrancheId,
      type_meeting: typeMeeting,
      tarif_special: tarifSpecial,
    };

    meeting = { ...obj };
    let keys = Object.keys(meeting);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (k != 'tarif_special') {
        if (!meeting[k] || (Array.isArray(meeting[k]) && !meeting[k].length)) {
          errorsHelper[k] = true;
          allValid = false;
        }
      }
    });
    if (allValid) {
      const formdata = new FormData();
      formdata.append('data', JSON.stringify(meeting));
      BaseRequest('createReunion', formdata).then((res) => props.ok());
    } else setErrors(errorsHelper);
  };

  return (
    <div>
      <Grid direction="column" spacing={3} container className="rtl">
        <Grid container direction="row-reverse" spacing={2} justifyContent="center" item xs={12}>
          <Grid item>
            <Typography color="primary" variant="h5">
              הוספת אסיפה
            </Typography>
          </Grid>
          <Grid item>
            <PeopleIcon className={classes.icon} color="primary" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactSelect
            placeholder="מוזמנים"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: errors.intervenant_list && !selected.length ? '#f44336' : 'rgba(0, 0, 0, 0.54)', // Placeholder color
              },
            })}
            closeMenuOnSelect={false}
            onChange={(e) => {
              if (Array.isArray(e)) {
                setSelected(e);
              } else setSelected([]);
            }}
            isMulti
            styles={customStyles}
            isRtl={true}
            name="colors"
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {errors.intervenant_list && !selected.length && (
            <FormHelperText className={classes.erroeText} error={true}>
              מוזמנים חובה
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              PopoverProps={{ dir: 'rtl' }}
              color="primary"
              style={{ width: '100%', zIndex: 0 }}
              inputVariant="outlined"
              label="תאריך"
              format="dd/MM/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"
              error={!selectedDate && errors.date}
              helperText={!selectedDate && errors.date ? 'תאריך חובה' : ''}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="time"
            label="שעה"
            type="time"
            defaultValue={hourMeeting}
            onChange={handleHourChange}
            className={classes.width_input_time}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            error={!hourMeeting && errors.hourMeeting}
            helperText={!hourMeeting && errors.hourMeeting ? 'שעה חובה' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>סניף</InputLabel>
            <Select
              value={selectedBrancheId}
              onChange={(e) => setSelectedBrancheId(e.target.value as string)}
              style={{ textAlign: 'right' }}
            >
              {branchesList?.map((branche, index) => (
                <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
                  {branche.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{ color: errors.id_salle && !selectedClass ? '#f44336' : '' }}
              id="demo-simple-select-outlined-label"
            >
              חדר
            </InputLabel>
            <Select
              onChange={(e) => {
                setSelectedClass(e.target.value);
              }}
              label="חדר"
              value={selectedClass}
              error={errors.id_salle && !selectedClass}
              style={{ textAlign: 'right' }}
            >
              {salle.map((s, idx) => {
                return (
                  <MenuItem key={idx} value={s.id_salle}>
                    {s.salle}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.id_salle && !selectedClass && <FormHelperText error={true}>חדר שדה חובה</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid direction="row" container item xs={12} alignItems="center" style={{ direction: 'rtl' }}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{ fontWeight: 'bold', display: 'flex' }}>
              לבחור סוג
            </FormLabel>
            <FormGroup row>
              {typesMeetingArr.map((a, idx) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeMeeting == a}
                        onChange={(e) => {
                          setTypeMeeting(e.target.value);
                        }}
                        color="primary"
                        value={a}
                      />
                    }
                    label={a}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="maxWidth"
            id="outlined-basic"
            label="עלות מיוחדת"
            type="number"
            variant="outlined"
            onChange={(e) => setTarifSpecial(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faShekelSign} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.datePicker}
            id="outlined-basic"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            label="נושא"
            variant="outlined"
            error={!subject && errors.sujet}
            helperText={!selectedDate && errors.sujet ? 'נושא חובה' : ''}
          />
        </Grid>
        <Grid item container className={classes.grid} direction="row" xs={12}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={createReunion}>
              אישור
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.ok();
              }}
              className={classes.okButton}
            >
              ביטול
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
