import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { Header } from './navigation/header';

export default function PrivateRoute(props: RouteProps) {
  const { path, element, children } = props;
  const location = useLocation();

  if (!localStorage.getItem('token')) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <>
      <Header />
      {children}
    </>
  );
}
