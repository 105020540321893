import React, { useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import { UnAuthorized } from '../../../pages/unauthorized';
import { Roles } from '../../utils/roles';
import { getUser } from '../../utils/user';
import { CreateFormLink } from './create-form-link';
import { ExistingFormLinks } from './existing-form-links';
import styles from './default.module.css';

export function DefaultFormIndex() {
  const { role } = getUser();
  const [triggerGetFormLinks, setTriggerGetFormLinks] = useState(false);

  if (role === Roles.Menahel || role === Roles.Secretaire || role === Roles.MenahelBliKsafim)
    return (
      <Container className={styles.container} component="main">
        <div>
          <Typography variant="h3" className={styles.marginBottom}>
            ניהול שאלונים
          </Typography>
        </div>
        <CreateFormLink callbackOnCreatedForm={() => setTriggerGetFormLinks(!triggerGetFormLinks)} />
        <ExistingFormLinks callGetFormLinks={triggerGetFormLinks} />
      </Container>
    );

  return <UnAuthorized />;
}
