import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  makeStyles,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import heLocale from 'date-fns/locale/he';
import { Facebook } from 'react-spinners-css';
import BaseRequest from '../../helpers/BaseRequest';
import { Student } from '../../models/Student';
import { getUser } from '../utils/user';
import { Roles } from '../utils/roles';
import styles from './student-comments.module.css';

export type StudentCommentsProps = {
  student: Student;
} & React.HTMLAttributes<HTMLDivElement>;

interface Comment {
  comment_id: string;
  user_id: string;
  student_id: string;
  date: Date;
  comment_text: string;
  username: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
}));

export function StudentComments({ student }: StudentCommentsProps) {
  const [newCommentText, setNewCommentText] = useState('');
  const [newCommentDate, setNewCommentDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removeComment, setRemoveComment] = useState<Comment | undefined>(undefined);
  const user = getUser();
  const classes = useStyles();

  useEffect(() => {
    getStudentComments();
  }, []);

  const getStudentComments = async () => {
    const formData: FormData = new FormData();
    formData.append('student_id', student.id);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('getStudentComments', formData, headersOptions)
      .then((result) => {
        if (result.data) {
          const studentComments: Comment[] = result.data.map((value: Comment) => value);
          setComments(studentComments);
        }
      })
      .catch((error) => {});
  };

  const handleNewCommentDate = (date: Date | null) => {
    if (date !== null) {
      new Date(date || '').toString() !== 'Invalid Date' && setNewCommentDate(date);
    }
  };

  const onSubmitNewComment = () => {
    const formData: FormData = new FormData();
    formData.append('user_id', user.user_id);
    formData.append('student_id', student.id);
    formData.append('date', newCommentDate.toString());
    formData.append('comment_text', newCommentText);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    setLoading(true);
    BaseRequest('addStudentComment', formData, headersOptions)
      .then((result) => {
        console.log('result', result);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
        setNewCommentText('');
        getStudentComments();
      });
  };

  const onSubmitRemoveComment = () => {
    const formData: FormData = new FormData();
    formData.append('comment_id', removeComment?.comment_id || '');
    formData.append('token', user.token);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    BaseRequest('removeStudentComment', formData, headersOptions)
      .then((result) => {
        console.log('result', result);
      })
      .catch((error) => {})
      .finally(() => {
        setOpenRemoveDialog(false);
        setRemoveComment(undefined);
        getStudentComments();
      });
  };

  if (!student) return null;
  const { id, firstName, lastName } = student;
  return (
    <div className={classNames(styles.container)}>
      <Typography variant="h3">הערות</Typography>
      <div className={styles.formHolder}>
        <div>
          שם הילד: {firstName} {lastName}
        </div>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocale}>
            <DateTimePicker
              PopoverProps={{ dir: 'rtl' }}
              color="primary"
              inputVariant="outlined"
              id="date-picker-dialog-outlined"
              format="dd/MM/yyyy"
              ampm={false}
              value={newCommentDate}
              onChange={handleNewCommentDate}
              InputLabelProps={{
                shrink: true,
              }}
              label="תאריך הערה"
              variant="inline"
              style={{ minWidth: 120, width: '100%' }}
              error={!newCommentDate}
              helperText={!newCommentDate && 'תאריך הערה חובה או תאריך שגוי'}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <TextField
          multiline
          minRows={4}
          maxRows={8}
          variant="outlined"
          placeholder="הכנס הערה חדשה"
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
        />
        <div className={styles.buttonHolder}>
          <Button
            disabled={!newCommentText || loading}
            variant="contained"
            color="primary"
            className={styles.addCommentButton}
            onClick={onSubmitNewComment}
          >
            הוסף הערה
          </Button>
          <Facebook size={40} color="#14738c" style={{ display: loading ? '' : 'none' }} />
        </div>
      </div>
      <div className={styles.commentsHistoryHolder}>
        <Typography variant="h4">היסטוריית הערות</Typography>
        <Timeline align="right">
          {comments.map((com, index) => {
            const comDate = new Date(com.date);
            const dateMinute = comDate.getMinutes() < 10 ? `0${comDate.getMinutes()}` : comDate.getMinutes();
            const dateString = `${comDate.getDate()}/${
              comDate.getMonth() + 1
            }/${comDate.getFullYear()} - ${comDate.getHours()}:${dateMinute}`;
            return (
              <TimelineItem key={`${com.comment_id}-${index}`}>
                <TimelineOppositeContent>
                  <div className={styles.commentInfoHolder}>
                    <div>
                      <Typography color="textPrimary">{com.username}</Typography>
                      <Typography color="textSecondary" className={styles.commentDate}>
                        {dateString}
                      </Typography>
                    </div>
                    <div>
                      {user.role === Roles.Menahel && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setOpenRemoveDialog(true);
                            setRemoveComment(com);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={{ textAlign: 'right' }}>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography>{com.comment_text}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
        {comments.length === 0 && <Typography>אין הערות להצגה</Typography>}
      </div>
      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.removeDialogContainer}
      >
        <DialogTitle id="alert-dialog-title">האם למחוק את ההודעה?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            האם למחוק את ההודעה של {removeComment?.username} על {student.firstName} {student.lastName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)}>ביטול</Button>
          <Button onClick={onSubmitRemoveComment} variant="contained" color="secondary">
            מחק
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
