import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} style={{ margin: '10px', direction: 'rtl' }}>
      <CardContent style={{ textAlign: 'right' }}>
        <Typography className={classes.title} color="textSecondary" gutterBottom style={{ marginBottom: '8px' }}>
          מסמכים הדרושים להגשת בקשה להנחה <br />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          על מנת שנוכל להעניק לכם את אחוז ההנחה הקבועה ולהתחיל לטפל בילדכם בהקדם האפשרי, נא למסור את האישורים הבאים :
        </Typography>
        <Typography className={classes.title} style={{ marginTop: '8px', marginBottom: '5px' }} color="textSecondary">
          1. מסמכים חובה :
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {bull} תעודת זהות ההורה עם ספח <br />
          {bull} 3 תלושי משכורת אחרונים של שני בני הזוג / שומת מס הכנסה האחרונה עבור הורה עצמאי או אישור חתום מרואה
          חשבון/יועץ מס על פעילות העסק ב-12 החודשים האחרונים <br />
          {bull} חו''ד של עובד סוציאלי / יועץ / מנהל / מחנך בית הספר <br />
        </Typography>
        <Typography className={classes.title} color="textSecondary" style={{ marginTop: '8px', marginBottom: '5px' }}>
          2. מסמכים שיש להגיש בנוסף, לפי העניין :
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{ marginBottom: '5px' }}>
          {bull} אישור זכאות בלשכת הרווחה <br />
          {bull} אישור ביטוח לאומי על מעמד ההורים (לא עובד, אבטלה...) / אישור קצבאות
          <br />
          {bull} מסמכים נוספים שיכול לעזור לקבלת ההנחה <br />
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '5px' }} component="p">
          האישורים הנדרשים לעיל הינם הבסיס להגשת בקשה להנחה. כל בקשה תיבדק לגופה על ידי ועדת ההנחות, ובמקרים מסוימים
          יתכן ותידרש/י להציג מסמכים נוספים.
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '5px' }} component="p">
          דיווח חלקי ו/או כוזב הינו עבירה על החוק. עמותת שושנים שומרת לעצמה את מלוא הזכות לבדוק ולאמת כל בקשה בכל
          האמצעים העומדים לרשותה בחוק.{' '}
        </Typography>
      </CardContent>
    </Card>
  );
}
