import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Button } from '../../components/buttons/button';
import { useBranches } from '../use-branches';
import type { Branche } from '../use-branches';
import { BaseRequest } from '../../helpers/BaseRequest';
import styles from './child-branche-section.module.css';

type ChildBrancheSectionProps = {
  childId: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function ChildBrancheSection({ childId }: ChildBrancheSectionProps) {
  const { branchesList } = useBranches();
  const [branche, setBranche] = useState<Branche | undefined>();
  const [initialStudentBranche, setInitialStudentBranche] = useState<Branche | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [showBrancheUpdated, setShowUpdated] = useState(false);

  useEffect(() => {
    const getStudent = async (id: string) => {
      const formData: FormData = new FormData();
      formData.append('student', id);

      await BaseRequest('getStudent', formData).then((result) => {
        if (result.data) {
          const studentBrancheId = result.data[0].branche_id;
          if (studentBrancheId) {
            const initialBranche = branchesList?.find((b) => b.id === studentBrancheId);
            setBranche(initialBranche);
            setInitialStudentBranche(initialBranche);
          }
        }
      });
    };

    getStudent(childId);
  }, [childId, branchesList]);

  const handleBrancheChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBranche(branchesList?.find((b) => b.id === (event.target.value as string)));
  };

  const handleSubmitSetStudentBranche = async () => {
    if (!branche) return;
    setLoading(true);
    const formData: FormData = new FormData();
    formData.append('branche_id', branche.id);
    formData.append('student_id', childId);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('setStudentBranche', formData, headersOptions)
      .then(async (result) => {
        if (result.data === 'Branche updated') {
          setShowUpdated(true);
          setTimeout(() => setShowUpdated(false), 3000);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const isButtonDisabled = () => {
    if (!branche) return true; // if there is no selected branche and also the student have no branche.
    if (branche && initialStudentBranche) {
      if (branche.id === initialStudentBranche.id) return true; // the selected branche is the actual student branche.
    }
    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.formHolder}>
        <FormControl variant="standard" className={styles.listHolder}>
          <InputLabel shrink id="branches-label">
            סניף
          </InputLabel>
          <Select
            labelId="branches-label"
            value={branche?.id || ''}
            onChange={handleBrancheChange}
            label="סניף"
            displayEmpty
          >
            <MenuItem value="" disabled>
              לא הוגדר סניף
            </MenuItem>
            {branchesList?.map((branche) => (
              <MenuItem key={branche.id} value={branche.id} style={{ direction: 'rtl' }}>
                {branche.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button loading={isLoading} onClick={handleSubmitSetStudentBranche} disabled={isButtonDisabled()}>
          עדכן סניף
        </Button>
      </div>
      <div className={styles.brancheUpdated}>{showBrancheUpdated && <span>הסניף עודכן בהצלחה</span>}</div>
    </div>
  );
}
