import React, { useState, useRef, useCallback } from 'react';
import { Dialog, DialogContent, makeStyles, Container } from '@material-ui/core';
import BaseRequest from '../helpers/BaseRequest';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import heLocale from '@fullcalendar/core/locales/he';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import EditCourse from './EditCourse';
import EditReunion from './EditReunion';
import Tooltip from '@material-ui/core/Tooltip';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getUser } from './utils/user';
import { useBranches } from '../branches/use-branches';

const useStyles = makeStyles((theme) => ({
  notefication: {
    cursor: 'pointer',
  },
  coursContent: {
    width: 500,
    height: 600,
  },
  content: {
    width: 500,
    height: 420,
  },
  buttonsParent: {
    display: 'flex',
    // flexDirection: 'column',
    // width: '300px',
    margin: '15px',
    fontSize: '20px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    direction: 'rtl!important' as any,
    alignItems: 'baseline',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: '12px',
    width: 200,
  },
  customWidth: {
    fontSize: '20px',
  },
}));

export default function Calendrier() {
  const classes = useStyles();
  const [course, setCourse] = useState<any>();
  const [reunion, setReunion] = useState<any>();
  const [showEditCourse, setShowEditCourse] = useState(false);
  const [showEditReunion, setShowEditReunion] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef<any>();
  const user = getUser();
  const { selectedBranche } = useBranches();

  const getCours = async (id_cours: any) => {
    const form = new FormData();
    const data = {
      id_cours: id_cours,
      id: user.interv_id,
      role: user.role,
    };
    form.append('data', JSON.stringify(data));
    await BaseRequest('getCours', form)
      .then((res: any) => {
        setCourse(res.data[0]);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getReunion = async (id_reunion: any) => {
    const form = new FormData();
    const data = {
      id_reunion: id_reunion,
    };
    form.append('data', JSON.stringify(data));
    await BaseRequest('GetReunion', form)
      .then((res: any) => {
        setReunion(res.data[0]);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getDateSet = (dateinfo: any) => {
    let date_current = dateinfo.start;
    setSelectedDate(date_current);
  };

  const getData = (start: any, successCallback: any) => {
    getEventsCalendar(start, successCallback);
  };

  const getEventsCalendar = useCallback(
    async (start: any, successCallback: any) => {
      let first_day = start['startStr'].split('T')[0];
      let next_day_month = start['endStr'].split('T')[0];
      let next_day_month_date = new Date(next_day_month);
      let get_last_day = next_day_month_date.setDate(next_day_month_date.getDate() - 1);
      let last_day = new Date(get_last_day).toISOString().split('T')[0];
      const form = new FormData();
      const data = {
        start: first_day,
        end: last_day,
        id: user.interv_id,
        role: user.role,
      };
      form.append('data', JSON.stringify(data));
      const response: any = await BaseRequest('getCoursAndReunion', form);
      if (response.data === 'No result') {
        return;
      }
      successCallback(
        response.data
          .filter((event: any) => (event.branche_id !== null ? event.branche_id === selectedBranche?.id : true))
          .map((event: any, idx: number) => {
            if ('id_cours' in event) {
              return {
                resourceId: event.id_salle,
                id: event.id_cours,
                // title: '<strong>מורה :</strong>'+event.interv_prenom+' '+event.interv_nom+'<br>'+'<strong>ילד :</strong>'+event.student_firstname+' '+event.student_lastname,
                start: event.date_cours + 'T' + event.hour_cours,
                extendedProps: {
                  chiour_comment: event.chiour_comment,
                  more_prenom: event.interv_prenom,
                  more_nom: event.interv_nom,
                  yeled_prenom: event.student_firstname,
                  yeled_nom: event.student_lastname,
                  number_cours: event.number_cours,
                  total_number_cours: event.total_number_cours,
                },
                // backgroundColor: '#007bff',
                backgroundColor: event.interv_color,
              };
            }
            if ('id_reunion' in event) {
              return {
                resourceId: event.salle,
                id: event.id_reunion,
                // title: '<strong>מורה :</strong>'+event.interv_prenom+' '+event.interv_nom+'<br>'+'<strong>ילד :</strong>'+event.student_firstname+' '+event.student_lastname,
                start: event.date + 'T' + event.heure,
                extendedProps: { type_meeting: event.type_meeting },
                backgroundColor: '#CFCFC4',
              };
            }
            if ('holiday' in event) {
              return {
                allDay: true,
                display: 'background',
                start: event.start,
                end: event.end,
                title: event.title,
              };
            }
          }),
      );
    },
    [selectedBranche?.id, user.interv_id, user.role],
  );

  const CloseEdit = () => {
    setShowEditCourse(false);
    window.location.reload();
  };

  const CloseEditReunion = () => {
    setShowEditReunion(false);
    window.location.reload();
  };

  const handleEvent = (event: any) => {
    if (calendarRef.current.getApi().view.type == 'resourceTimeGridDay') {
      if ('type_meeting' in event.event.extendedProps) {
        return (
          <>
            <div style={{ color: '#000000' }}>
              <span style={{ fontWeight: 'bold' }}> סוג אסיפה : </span>
              {event.event.extendedProps.type_meeting}
            </div>
          </>
        );
      }
      if ('chiour_comment' in event.event.extendedProps) {
        return (
          <>
            {event.event.extendedProps.chiour_comment != '' ? (
              <>
                <Tooltip
                  title={event.event.extendedProps.chiour_comment}
                  classes={{ tooltip: classes.customWidth }}
                  placement="top"
                >
                  <div style={{ color: '#000000', position: 'relative' }}>
                    <span style={{ fontWeight: 'bold' }}> מטפל : </span>
                    {event.event.extendedProps.more_prenom + ' ' + event.event.extendedProps.more_nom}
                    <span style={{ display: 'block' }}>
                      <span style={{ fontWeight: 'bold' }}>ילד :</span>
                      {event.event.extendedProps.yeled_prenom + ' ' + event.event.extendedProps.yeled_nom}
                    </span>
                    <span style={{ position: 'absolute', left: 0, bottom: 0 }}>
                      {event.event.extendedProps.number_cours}/{event.event.extendedProps.total_number_cours}
                    </span>
                  </div>
                </Tooltip>
              </>
            ) : (
              <div style={{ color: '#000000', position: 'relative' }}>
                <span style={{ fontWeight: 'bold' }}> מטפל : </span>
                {event.event.extendedProps.more_prenom + ' ' + event.event.extendedProps.more_nom}
                <span style={{ display: 'block' }}>
                  <span style={{ fontWeight: 'bold' }}>ילד :</span>
                  {event.event.extendedProps.yeled_prenom + ' ' + event.event.extendedProps.yeled_nom}
                </span>
                <span style={{ position: 'absolute', left: 0, bottom: 0 }}>
                  {event.event.extendedProps.number_cours}/{event.event.extendedProps.total_number_cours}
                </span>
              </div>
            )}
          </>
        );
      }
    }
  };

  const getResources = (fetchInfo: any, successCallback: any) => {
    getFunctionRessource(fetchInfo, successCallback);
  };

  async function getFunctionRessource(fetchInfo: any, successCallback: any) {
    const response: any = await BaseRequest('getRoom');
    successCallback(
      response.data.map((resource: any) => {
        return {
          id: resource.id_salle,
          title: resource.salle,
          backgroundColor: '#32ff3c',
        };
      }),
    );
  }

  const handleActionEvent = async (info: any) => {
    if ('type_meeting' in info.event.extendedProps) {
      let event_id = info.event.id;
      await getReunion(event_id);
      setShowEditReunion(true);
    } else {
      let event_id = info.event.id;
      await getCours(event_id);
      setShowEditCourse(true);
    }
  };

  const handleDateChange = (event: any) => {
    if (new Date(event || '').toString() != 'Invalid Date') {
      let event_date =
        event.getFullYear() +
        '-' +
        appendLeadingZeroes(event.getMonth() + 1) +
        '-' +
        appendLeadingZeroes(event.getDate());
      let calendarReference = calendarRef.current.getApi();
      calendarReference.gotoDate(event_date);
    }
  };

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  return (
    <>
      <div>
        <Container maxWidth="lg" style={{ height: '1150px' }}>
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: '2%',
              padding: '25px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            }}
          >
            <div className={classes.container}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  PopoverProps={{ dir: 'rtl' }}
                  className={classes.textField}
                  color="primary"
                  margin="normal"
                  id="date-picker-inline"
                  label=" חיפוש תאריך"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  variant="inline"
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </div>

            <FullCalendar
              ref={calendarRef}
              plugins={[resourceTimeGridPlugin, dayGridPlugin, timeGridPlugin]}
              initialView="resourceTimeGridDay"
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'resourceTimeGridDay,timeGridWeek',
              }}
              height="950px"
              locale={heLocale}
              showNonCurrentDates={false}
              resources={getResources}
              events={getData}
              eventContent={handleEvent}
              datesSet={getDateSet}
              datesAboveResources={true}
              eventTimeFormat={{
                // like '14:30:00'
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                meridiem: false,
              }}
              scrollTime="07:00:00"
              slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
              expandRows={true}
              eventClick={handleActionEvent}
              refetchResourcesOnNavigate={true}
              editable={true}
              eventTextColor="#000000"
            />
          </div>
        </Container>

        <Dialog
          onClose={() => {
            setShowEditCourse(false);
          }}
          open={showEditCourse}
        >
          <DialogContent style={{ width: 500 }}>
            <EditCourse ok={CloseEdit} course={course} />
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={() => {
            setShowEditReunion(false);
          }}
          open={showEditReunion}
        >
          <DialogContent style={{ width: 500 }}>
            <EditReunion ok={CloseEditReunion} reunion={reunion} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
