import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Typography, Card, CardContent } from '@material-ui/core';
import styles from './styles.module.css';

type QuestionCardProps = {
  title?: string;
} & HTMLAttributes<HTMLDivElement>;

export function QuestionCard({ title, children, className, ...rest }: QuestionCardProps) {
  return (
    <Card className={classNames(styles.cardContainer, className)} {...rest}>
      <CardContent>
        <Typography className={styles.cardContentTitle} variant="subtitle1">
          {title}
        </Typography>
        <div className={styles.cardContent}>{children}</div>
      </CardContent>
    </Card>
  );
}
