import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  Card,
  Button,
  FormHelperText,
  CircularProgress,
  Box,
  makeStyles,
} from '@material-ui/core';
import BaseRequest from '../helpers/BaseRequest';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  card: {
    margin: '1% 15%',
    padding: '0 2% 2% 2%',
    direction: 'rtl',
  },
  grid: {
    marginRight: '5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonPdfHidden: {
    display: 'none',
  },
  buttonPdfVisible: {
    display: 'block',
  },
  spinner_loader_visible: {
    display: 'block',
  },
  spinner_loader_hidden: {
    display: 'none',
  },
}));

export default function CourseList() {
  const classes = useStyles();

  const [dateStart, setDateStart] = React.useState<Date | null>(null);
  const [dateEnd, setDateEnd] = React.useState<Date | null>(null);
  const [institutionFinance, setInstitutionFinance] = useState<any>();

  const [pdfUrl, setPdfUrl] = useState<any>();
  const [classButton, setClassButton] = useState<any>(classes.buttonPdfHidden);
  const [classSpinner, setClassSpinner] = useState<any>(classes.spinner_loader_hidden);

  const institutionOptions = ['התפתחות הילד', 'תוכנית הלאומית', 'רווחה', 'עיריה', 'משפחה', 'שושנים'];

  const [errors, setErrors] = useState({
    dateStart: false,
    dateEnd: false,
    institution: false,
  });

  const handleDateStartChange = (date: Date | null) => {
    if (date === null) setDateStart(null);
    else new Date(date || '').toString() != 'Invalid Date' && setDateStart(date);
  };

  const handleDateEndChange = (date: Date | null) => {
    if (date === null) setDateEnd(null);
    else new Date(date || '').toString() != 'Invalid Date' && setDateEnd(date);
  };

  const createPdf = () => {
    setClassButton(classes.buttonPdfHidden);
    let allValid = true;
    let options: any;
    const obj = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      institution: institutionFinance,
    };
    options = { ...obj };
    let keys = Object.keys(options);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (!options[k]) {
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    if (allValid) {
      setClassSpinner(classes.spinner_loader_visible);
      const formData = new FormData();
      formData.append('data', JSON.stringify(options));
      let url_pdf = '';
      if (obj.institution == 'התפתחות הילד') {
        url_pdf = 'generePdfHitpatchout';
      } else {
        url_pdf = 'generePdfInstitution';
      }

      BaseRequest(url_pdf, formData).then((res) => {
        setPdfUrl(res);
        setClassButton(classes.buttonPdfVisible);
        setClassSpinner(classes.spinner_loader_hidden);
      });
    } else setErrors(errorsHelper);
  };

  const viewPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <Card className={classes.card}>
        <Grid spacing={2} direction="column" container>
          <Grid item xs={12}>
            <h1 className="primary">כספים</h1>
          </Grid>
          <Grid direction="row-reverse" item xs={12} justifyContent="center" container>
            <Grid item xs={3} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  PopoverProps={{ dir: 'rtl' }}
                  color="primary"
                  inputVariant="outlined"
                  id="date-picker-dialog-outlined"
                  format="dd/MM/yyyy"
                  value={dateStart}
                  onChange={handleDateStartChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label=" תאריך התחלה"
                  variant="inline"
                  className={classes.formControl}
                  error={!dateStart && errors.dateStart}
                  helperText={!dateStart && errors.dateStart ? 'תאריך התחלה חובה או תאריך שגוי' : ''}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  PopoverProps={{ dir: 'rtl' }}
                  color="primary"
                  inputVariant="outlined"
                  id="date-picker-dialog-outlined"
                  format="dd/MM/yyyy"
                  value={dateEnd}
                  onChange={handleDateEndChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label=" תאריך סיום"
                  variant="inline"
                  className={classes.formControl}
                  error={!dateEnd && errors.dateEnd}
                  helperText={!dateEnd && errors.dateEnd ? 'תאריך סיום חובה או תאריך שגוי' : ''}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <FormControl
                // style={{ width: "100%" }}
                color="primary"
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ color: errors.institution && !institutionFinance ? '#f44336' : '' }}
                >
                  מוסדות פיננסיים
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={institutionFinance}
                  onChange={(e) => setInstitutionFinance(e.target.value)}
                  label="מוסדות פיננסיים"
                >
                  {institutionOptions.map((institution, idx) => {
                    return (
                      <MenuItem key={idx} value={institution}>
                        {institution}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.institution && !institutionFinance && (
                  <FormHelperText error={true}>מוסדות פיננסיים שדה חובה</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '40px' }}>
            <Button onClick={createPdf} variant="contained" color="primary" style={{ width: '70%' }}>
              להוריד PDF
            </Button>
          </Grid>
          <Grid className={classSpinner}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="3.5em" />
            </Box>
          </Grid>
          <Grid style={{ marginTop: '10px' }} className={classButton}>
            <Button onClick={viewPdf} variant="contained" color="primary" style={{ width: '50%' }}>
              PDF
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
