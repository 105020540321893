import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default function AboutPopup() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div style={{ marginLeft: '10px' }}>
      <Button
        variant="contained"
        size="large"
        className="button_popup"
        color="primary"
        onClick={handleClickOpen('body')}
        style={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#fff' }}
      >
        מה זה טיפול קוגניטיבי &nbsp;
        <HelpOutlineIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">טיפול קוגניטיבי – עמותת שושנים לילדים</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{ direction: 'rtl' }}
          >
            <p>
              <strong>הטיפול הקוגניטיבי</strong> מתמקד בזיהוי דפוסי החשיבה הלקויים והקניית דפוסים סתגלניים במקומם.
              הטיפול הקוגניטיבי שם דגש על <strong>התהליכים הפנימיים של המטופל</strong>, ולא על ההתנהגות בלבד.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              <strong> מהי לקות קוגניטיבית ?</strong>
            </p>
            <p> ליקויים קוגניטיביים משפיעים על תפקודו היומיומי של הילד וכוללים :</p>
            <ul style={{ direction: 'rtl', listStyleType: 'circle' }}>
              <li>בלבול,</li>
              <li>חוסר התמצאות,</li>
              <li>בעיות קשב וזיכרון,</li>
              <li>הפרעות בתהליכי חשיבה,</li>
              <li>קושי ביכולת פתרון בעיות,</li>
              <li>קושי בזיהוי סיטואציה וביכולת להגיב אליה בצורה תואמת,</li>
            </ul>
            <p> וגם חוסר מודעות וליקויים בתפקודים ניהוליים כגון :</p>
            <ul style={{ direction: 'rtl', listStyleType: 'circle' }}>
              <li>קושי ליזם ולתכנן פעולה,</li>
              <li>קושי בארגון ובבקרה על פעולה.</li>
            </ul>
            <p style={{ textDecoration: 'underline' }}>
              <strong> מהו התפקוד הקוגניטיבי ?</strong>
            </p>
            <p style={{ direction: 'rtl' }}>
              ניתן להגדיר תפקוד קוגניטיבי <strong>כסל כישורים המאפשר לילד המשך צמיחה והתפתחות</strong>.
            </p>
            <p>כישורים אלו מתפתחים באופן טבעי עד גיל הבגרות, ותלויים הן בתנאים תורשתיים וסביבתיים.</p>
            <p style={{ direction: 'rtl' }}>
              תפקודים קוגניטיביים ("תפקודים ניהוליים") מסייעים לילד לנהל ולתכנן את התנהגותו בפעילויות ובמצבים מורכבים
              ומשתנים.
            </p>
            <p>
              מרכיב משמעותי ביכולתנו לפתח תפקודים קוגניטיביים תלוי במאגר זיכרון הנקרא <strong>זיכרון העבודה.</strong>{' '}
              הוא זה המאפשר קישור בין מידע חדש לבין מידע קודם שיש לשלוף ממאגר זיכרון אחר, הזיכרון לטווח ארוך.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              <strong> איך אפשר לפתח יכולות קוגניטיביות ?</strong>
            </p>
            <p>
              לאורח החיים שלנו השפעה גדולה על התפקוד הקוגניטיבי. למחות זאת, יש בידנו את האפשרות לבחור הרגלים בריאים
              שנמצאו כבעלי השפעה חיוביות על התפקוד הקוגניטיבי:
            </p>
            <p>
              <strong>למידה – </strong> למידה והשכלה בגילאים הצעירים תורמת להיווצרותם של קשרים עצביים רבים יותר, אשר
              יעזור לנו תפקוד קוגניטיבי גבוה גם בגילאים מתקדמים יותר
            </p>
            <p>
              <strong>התנסויות שונות – </strong>כל התנסות חדשה תיאלץ אותנו להשליך ולהתאים על סמך ניסיון העבר – זיכרון,
              שליפה ומהירות תגובה הן רק חלק מהיכולות הקוגניטיביות שנתרגל במצב כזה.
            </p>
            <p>
              <strong>אימון קוגניטיבי – </strong>שילוב פעילויות המציבות אתגרים מנטליים בשגרת היום יסייע לשיפורן ושימורן
              של יכולות קוגניטיביות. אימון בכיתה, בזמן הלימודים, תיתן תמורה נהדרת בזמן קצר.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              <strong>דרך פעילות עמותת שושנים :</strong>
            </p>
            <p>
              דרך סל של טיפולים מקצועיים מתאימים לכל ילד, עמותת שושנים תעזור לו בהתפתחות קוגניטיבית ובעליית היכולות שלו
              להבין ולפתור בעיות במסגרת הלימודים, ובאופן כללי , לכל החיים.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            לסגור
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
