import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  FormGroup,
  Typography,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormHelperText,
  Checkbox,
  Select,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import BaseRequest from '../helpers/BaseRequest';
import Meeting from '../models/Meeting';
import PeopleIcon from '@material-ui/icons/PeopleOutlineOutlined';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import ReactSelect from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { getUser } from './utils/user';
import { Roles } from './utils/roles';
import { ConfirmationModal } from './confirmation-modal';
import { useBranches } from '../branches/use-branches';

const useStyles = makeStyles({
  select: {
    border: '1px solid red',
  },
  icon: {
    marginTop: 2,
  },
  erroeText: {
    marginRight: 12,
  },
  datePicker: {
    width: '100%',
    zIndex: 0,
  },
  grid: {
    direction: 'rtl',
  },
  okButton: {
    marginRight: '8px',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
});

interface MeetingProps {
  ok: Function;
  reunion: any;
}

export default function AddMeeting(props: MeetingProps) {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>(props.reunion.intervenant);
  const [salle, setSalle] = useState<any[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const typesMeetingArr = ['שעה טכנית', 'ישיבת צוות'];
  const [errors, setErrors] = useState({
    sujet: false,
    heure: false,
    date: false,
    intervenant_list: false,
    id_salle: false,
    type_meeting: false,
  });
  const user = getUser();
  const { branchesList, selectedBranche } = useBranches();
  const [selectedBrancheId, setSelectedBrancheId] = useState(selectedBranche?.id);

  useEffect(() => {
    if (selectedBranche?.id) setSelectedBrancheId(selectedBranche?.id);
  }, [selectedBranche?.id]);

  useEffect(() => {
    BaseRequest('getIntervenant').then((res: any) => {
      let arr: any[] = [];
      res.data.forEach((item: any) => {
        arr.push({ label: `${item.nom} ${item.prenom}`, value: item.id_interv });
      });
      setOptions(arr);
    });
  }, []);

  //   const [selectedIntervenant, setSelectedIntervenant] = useState()
  const [subject, setSubject] = useState(props.reunion.sujet);
  const [selectedDate, setSelectedDate] = React.useState<any>(props.reunion.date);
  const [selectedHours, setSelectedHours] = useState(props.reunion.heure);
  const [selectedClass, setSelectedClass] = useState<any>(props.reunion.salle);
  const [typeMeeting, setTypeMeeting] = useState<any>(props.reunion.type_meeting);
  const [tarifSpecial, setTarifSpecial] = useState<any>(props.reunion.tarif_special);
  const [showDeleteAsefaModal, setShowDeleteAsefaModal] = useState(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleHourChange = (e: any) => {
    setSelectedHours(e.target.value);
  };

  const getClasses = useCallback(async () => {
    await BaseRequest('getSalle').then((res) => {
      const result: any[] = res.data;
      setSalle(result.filter((salle) => (salle.branche_id !== null ? salle.branche_id === selectedBrancheId : true)));
    });
  }, [selectedBrancheId]);

  useEffect(() => {
    getClasses();
  }, [selectedBrancheId, getClasses]);

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: 53,
      paddingButtom: 0,
      '&:hover': { borderColor: 'black' },
      '&:focus': { borderColor: '#214078' },
      '&:unFocus': { borderColor: '#214078' },
      borderColor: errors.intervenant_list && !selected.length ? 'red' : 'rgba(0, 0, 0, 0.23)',
    }),
  };

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  const deleteAsifa = () => {
    const obj = {
      reunion_id: props.reunion.id_reunion,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    BaseRequest('DeleteAsifa', formData).then((res: any) => {
      if (res.success == 1) {
        props.ok();
      }
    });
  };

  const editReunion = () => {
    let allValid = true;
    var final_date = '';
    if (new Date(selectedDate || '').toString() != 'Invalid Date') {
      var curr = new Date(selectedDate ? selectedDate : '');
      final_date =
        curr.getFullYear() + '-' + appendLeadingZeroes(curr.getMonth() + 1) + '-' + appendLeadingZeroes(curr.getDate());
    }

    let meeting: any;
    const obj: Meeting = {
      id_reunion: props.reunion.id_reunion,
      intervenant_list: selected,
      date: final_date,
      heure: selectedHours,
      sujet: subject,
      id_salle: selectedClass,
      branche_id: selectedBrancheId,
      tarif_special: tarifSpecial,
      type_meeting: typeMeeting,
    };

    meeting = { ...obj };
    let keys = Object.keys(meeting);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (k != 'tarif_special') {
        if (!meeting[k] || (Array.isArray(meeting[k]) && !meeting[k].length) || meeting['id_salle'] == '0') {
          errorsHelper[k] = true;
          allValid = false;
        }
      }
    });
    if (allValid) {
      const formdata = new FormData();
      formdata.append('data', JSON.stringify(meeting));
      BaseRequest('updateReunion', formdata).then((res) => {
        props.ok();
      });
    } else setErrors(errorsHelper);
  };

  return (
    <div>
      <Grid direction="column" spacing={3} container className="rtl">
        <Grid container direction="row-reverse" spacing={2} justifyContent="center" item xs={12}>
          <Grid item>
            <Typography color="primary" variant="h5">
              עידכון אסיפה
            </Typography>
          </Grid>
          <Grid item>
            <PeopleIcon className={classes.icon} color="primary" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactSelect
            placeholder="מוזמנים"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: errors.intervenant_list && !selected.length ? '#f44336' : 'rgba(0, 0, 0, 0.54)', // Placeholder color
              },
            })}
            closeMenuOnSelect={false}
            onChange={(e) => {
              console.log(e);
              if (Array.isArray(e)) {
                setSelected(e);
              } else setSelected([]);
            }}
            isMulti
            styles={customStyles}
            isRtl={true}
            name="colors"
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            value={selected}
          />
          {errors.intervenant_list && !selected.length && (
            <FormHelperText className={classes.erroeText} error={true}>
              מוזמנים חובה
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              PopoverProps={{ dir: 'rtl' }}
              color="primary"
              style={{ width: '100%', zIndex: 0 }}
              inputVariant="outlined"
              label="תאריך"
              format="dd/MM/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"
              error={!selectedDate && errors.date}
              helperText={!selectedDate && errors.date ? 'תאריך חובה' : ''}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="time"
            className={classes.datePicker}
            id="time"
            label="שעה"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={selectedHours}
            onChange={handleHourChange}
            error={!selectedHours && errors.heure}
            helperText={!selectedHours && errors.heure ? 'שעה חובה' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>סניף</InputLabel>
            <Select
              value={selectedBrancheId}
              onChange={(e) => setSelectedBrancheId(e.target.value as string)}
              style={{ textAlign: 'right' }}
            >
              {branchesList?.map((branche, index) => (
                <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
                  {branche.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{ color: errors.id_salle && !selectedClass ? '#f44336' : '' }}
              id="demo-simple-select-outlined-label"
            >
              חדר
            </InputLabel>
            <Select
              onChange={(e) => {
                setSelectedClass(e.target.value);
              }}
              label="חדר"
              value={selectedClass}
              error={errors.id_salle && (selectedClass == '0' || !selectedClass)}
              style={{ textAlign: 'right' }}
            >
              {salle.map((s, idx) => {
                return (
                  <MenuItem key={idx} value={s.id_salle}>
                    {s.salle}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.id_salle && (selectedClass == '0' || !selectedClass) && (
              <FormHelperText error={true}>חדר שדה חובה</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid direction="row" container item xs={12} alignItems="center" style={{ direction: 'rtl' }}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" style={{ fontWeight: 'bold', display: 'flex' }}>
              לבחור סוג
            </FormLabel>
            <FormGroup row>
              {typesMeetingArr.map((a, idx) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeMeeting == a}
                        onChange={(e) => {
                          setTypeMeeting(e.target.value);
                        }}
                        color="primary"
                        value={a}
                      />
                    }
                    label={a}
                  />
                );
              })}
            </FormGroup>
            {errors.type_meeting && typeMeeting == '' && <FormHelperText error={true}>סוג חובה</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="maxWidth"
            id="outlined-basic"
            label="עלות מיוחדת"
            type="number"
            variant="outlined"
            value={tarifSpecial}
            onChange={(e) => setTarifSpecial(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faShekelSign} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.datePicker}
            id="outlined-basic"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            label="נושא"
            variant="outlined"
            error={!subject && errors.sujet}
            helperText={!selectedDate && errors.sujet ? 'נושא חובה' : ''}
          />
        </Grid>
        {(user.role === Roles.Menahel || user.role === Roles.Secretaire) && (
          <Grid container xs={12} justifyContent="center" style={{ marginBottom: '10px' }}>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setShowDeleteAsefaModal(true)}
                style={{ backgroundColor: 'rgb(220, 0, 78)', color: '#fff', width: '400px' }}
              >
                למחוק אסיפה
              </Button>
            </Grid>
            <ConfirmationModal
              open={showDeleteAsefaModal}
              onClose={() => setShowDeleteAsefaModal(false)}
              onSubmit={deleteAsifa}
              dangerButton
              title="מחיקת אסיפה"
              text={<span>האם אתה בטוח שברצונך למחוק את האסיפה?</span>}
            />
          </Grid>
        )}
        <Grid item container className={classes.grid} direction="row" xs={12}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={editReunion}>
              אישור
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.ok();
              }}
              className={classes.okButton}
            >
              ביטול
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
