import React, { useState, useEffect } from 'react';
import { Typography, FormControl, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import styles from './styles.module.css';
import { QuestionCard } from './question-card';
import type { CategoryOfQuestions, CategoryOfResponses, Response } from './types';

type QuestionsCategoryProps = {
  categoryOfQuestions: CategoryOfQuestions;
  initialCategoryOfResponses?: CategoryOfResponses;
  callback: (categoryOfResponses: CategoryOfResponses) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function QuestionsCategory({
  categoryOfQuestions,
  initialCategoryOfResponses,
  callback,
  ...rest
}: QuestionsCategoryProps) {
  const [categoryResponses, setCategoryResponses] = useState<CategoryOfResponses>({
    id: categoryOfQuestions.id,
    title: categoryOfQuestions.title,
    responses: initialCategoryOfResponses ? initialCategoryOfResponses.responses : [],
    note: initialCategoryOfResponses ? initialCategoryOfResponses.note : '',
  });

  useEffect(() => {
    const initialResponses = {
      id: categoryOfQuestions.id,
      title: categoryOfQuestions.title,
      responses: initialCategoryOfResponses ? initialCategoryOfResponses.responses : [],
      note: initialCategoryOfResponses ? initialCategoryOfResponses.note : '',
    };
    setCategoryResponses(initialResponses);
  }, [categoryOfQuestions, categoryOfQuestions.id, initialCategoryOfResponses]);

  useEffect(() => {}, []);

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const copyResponses = { ...categoryResponses };
    copyResponses.note = e.target.value;
    setCategoryResponses(copyResponses);
    callback(copyResponses);
  };

  const handleResponseChange = (questionId: string, questionTitle: string, responseValue: string) => {
    const newCopyResponses: CategoryOfResponses = {
      id: categoryResponses.id,
      title: categoryResponses.title,
      responses: categoryResponses.responses,
      note: categoryResponses.note,
    };
    const newResponse: Response = {
      categoryId: categoryResponses.id,
      questionId,
      questionTitle,
      responseValue,
    };

    if (categoryResponses.responses.find((r) => r.questionId === questionId && r.categoryId === categoryResponses.id)) {
      categoryResponses.responses.forEach((r, index) => {
        if (r.questionId === questionId && r.categoryId === categoryResponses.id) {
          newCopyResponses.responses[index] = newResponse;
        } else {
          newCopyResponses.responses[index] = r;
        }
      });
    } else {
      newCopyResponses.responses.push(newResponse);
    }
    setCategoryResponses(newCopyResponses);
    callback(newCopyResponses);
  };

  return (
    <div>
      <Typography variant="subtitle1" className={styles.titleText}>
        {categoryOfQuestions.title}
      </Typography>
      {categoryOfQuestions.questions.map((question) => {
        return (
          <QuestionCard
            key={`${categoryOfQuestions.id}-question-card-${question.questionId}`}
            title={question.questionTitle}
          >
            <FormControl component="fieldset" variant="outlined" className={styles.formControl}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                onChange={(e) => handleResponseChange(question.questionId, question.questionTitle, e.target.value)}
                value={
                  categoryResponses.responses.find((r) => r.questionId === question.questionId)?.responseValue || ''
                }
              >
                <Typography>טוב</Typography>
                <FormControlLabel value="1" control={<Radio color="primary" />} label="1" labelPlacement="top" />
                <FormControlLabel value="2" control={<Radio color="primary" />} label="2" labelPlacement="top" />
                <FormControlLabel value="3" control={<Radio color="primary" />} label="3" labelPlacement="top" />
                <Typography>טעון שיפור</Typography>
              </RadioGroup>
            </FormControl>
          </QuestionCard>
        );
      })}
      <QuestionCard title="הערות">
        <FormControl component="fieldset" variant="outlined" className={styles.formControl} style={{ width: '100%' }}>
          <TextField
            id="outlined-multiline-static"
            label="הערות"
            multiline
            rows={4}
            value={categoryResponses.note}
            onChange={handleNoteChange}
          />
        </FormControl>
      </QuestionCard>
    </div>
  );
}
