import {
  InputLabel,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  CardContent,
  Card,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  FormHelperText,
  RadioGroup,
  FormControl,
  Radio,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState, useEffect, useRef } from 'react';
import { ChildData, ServerData } from './PersonalData';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ChildDataService from './ChildDataService';
import ArnonaInformation from './ArnonaInformation';
import { Subscription } from 'rxjs';
import DescriptionIcon from '@material-ui/icons/Description';

interface GenerateControlsProps {
  serverData: ServerData[];
  setServerData: Function;
  arr: ChildData[];
  title: string;
  isEdit: boolean;
  childId?: string;
  fileName: string;
  handleNext: Function;
  setFinish?: Function;
  topPlugin?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  txt: {
    width: '100%',
    direction: 'ltr',
  },
  check: {
    padding: 0,
  },
  checkContent: {
    padding: '7px !important',
  },
  radioGroup: {
    flexDirection: 'row-reverse',
    marginLeft: 22,
    direction: 'ltr!important' as any,
  },
  radio: {
    flexDirection: 'row',
  },
  formControlLabel: {
    color: 'black',
  },
  gridContainer: {
    margin: '2%',
    width: '98%',
  },
  typography: {
    marginBottom: 8,
  },
  downloadButton: {
    margin: '8px auto',
  },
  textError: {
    display: 'block',
  },
  textErrorDefault: {
    display: 'none',
  },
  dropZone: {
    // marginBottom: 20,
    direction: 'rtl!important' as any,
  },
}));

export default function GenerateControls(props: GenerateControlsProps) {
  const classes = useStyles();
  const [arr, setArr] = useState(props.arr);
  // const [emptyFile, setEmptyFile] = useState(true);
  const [detectClick, setDetectClick] = useState(false);
  const [textError, setTextError] = useState(classes.textErrorDefault);
  const [CheckEmptySelectValue, setCheckEmptySelectValue] = useState(true);
  const [selectValue, setSelectedValue] = useState('');
  const [fileArr, setFileArr] = useState<any>();

  const emptyFileRefEngagement = useRef(true);
  const emptyFileRefMedicalQuestion = useRef(true);
  const refTextError = useRef(null);
  const refTextField = useRef<any>([]);

  let arrHelper = [...props.serverData];
  const ref = useRef(arrHelper);
  let s: Subscription;

  useEffect(() => {
    ref.current = arrHelper;
    if (arr != undefined) {
      arr.forEach((element) => {
        if (element.type == 'select') {
          setSelectedValue(element.value);
        }
      });
    }
  }, [arrHelper]);

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  const handleTextChange = (item: ChildData, e: any, idx: number) => {
    arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = e.target.value;
    let helpArr = [...arr];
    let index = arr.indexOf(item);
    helpArr[index].value = e.target.value;
    if (helpArr[index].isRequired && helpArr[index].isError && item.value) {
      helpArr[index].isError = false;
    }
    setArr(helpArr);
  };

  const handleDateChange = (item: ChildData, date: any, idx: number) => {
    if (new Date(date || '').toString() != 'Invalid Date') {
      let curr = new Date(date ? date : '');
      let date_final =
        curr.getFullYear() + '-' + appendLeadingZeroes(curr.getMonth() + 1) + '-' + appendLeadingZeroes(curr.getDate());

      if (item.id == 'birthDate') {
        let date1 = new Date(date_final).getTime();
        let date2 = new Date().getTime();
        let yearsDiff = new Date(date2 - date1).getFullYear() - 1970;
        let updateAgeField = ([...arr][idx + 1].value = yearsDiff);
        arrHelper[arrHelper.findIndex((a) => a.key === 'age')].value = yearsDiff;
      }

      arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = date_final;
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index].value = date_final;
      if (helpArr[index].isRequired && helpArr[index].isError && item.value) {
        helpArr[index].isError = false;
      }
      setArr(helpArr);
    } else {
      arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = null;
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index].value = null;
      if (helpArr[index].isRequired && helpArr[index].isError && item.value) {
        helpArr[index].isError = false;
      }
      setArr(helpArr);
    }
  };

  const handleChange = (item: ChildData) => {
    let helpArr = [...arr];
    let index = arr.indexOf(item);
    helpArr[index].value = !helpArr[index].value;
    arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = arr[index].value;
    setArr(helpArr);
  };

  useEffect(() => {
    s = ChildDataService.next$.subscribe(() => {
      setDetectClick(true);
      props.setServerData(ref.current);
      if (props.fileName === 'files' && props.setFinish) {
        props?.setFinish(true);
      } else {
        if (refTextError.current != 0 && refTextError.current != 1) {
          setTextError(classes.textError);
        }
        props.arr.forEach((element) => {
          if (element.id == 'mName' || element.id == 'mTz' || element.id == 'mMail' || element.id == 'mProfession') {
            element.display = true;
          }
        });
        props.handleNext();
      }
    });
    return () => {
      s.unsubscribe();
    };
  }, []);

  const handleRadioChange = (e: any, item: ChildData) => {
    if (item.id == 'insurance' && +e.target.value == 0) {
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index + 1].isRequired = true;
    }
    if (item.id == 'insurance' && +e.target.value == 1) {
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index + 1].isRequired = false;
    }
    if (item.id == 'familyState' && +e.target.value == 0) {
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index + 1].isRequired = true;
      helpArr[index + 2].isRequired = true;
    }
    if (item.id == 'familyState' && +e.target.value != 0) {
      let helpArr = [...arr];
      let index = arr.indexOf(item);
      helpArr[index + 1].isRequired = false;
      helpArr[index + 2].isRequired = false;
    }
    let helpArr = [...arr];
    let index = arr.indexOf(item);
    helpArr[index].value = +e.target.value;
    arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = +e.target.value;
    setArr(helpArr);
    refTextError.current = item.value;
  };

  const handleSelectChange = (e: any, item: ChildData) => {
    setCheckEmptySelectValue(false);
    let helpArr = [...arr];
    let index = arr.indexOf(item);
    helpArr[index].value = e.target.value;
    arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = e.target.value;
    setArr(helpArr);
  };

  const componentRender = (item: ChildData, idx: number) => {
    if (
      (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '3') &&
      props.isEdit &&
      item.isadmin
    ) {
      return (
        <Grid key={idx} item xs={12} className="fieldText">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              PopoverProps={{ dir: 'rtl' }}
              color="primary"
              inputVariant="outlined"
              id="date-picker-dialog-outlined"
              // label="תאריך"
              format="dd/MM/yyyy"
              value={item.value != null ? item.value : null}
              onChange={(e) => handleDateChange(item, e, idx)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={item.lable}
              variant="inline"
              autoOk={true}
              className={classes.txt}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      );
    } else {
      switch (item.type) {
        case 'text':
          return (
            <Grid key={idx} item xs={12} className="fieldText">
              <TextField
                className={classes.txt}
                variant="outlined"
                placeholder=""
                label={item.lable}
                value={item.value}
                InputLabelProps={{
                  shrink: item.value || (item.value != undefined && item.value !== '') ? true : false,
                }}
                type={item.kind}
                error={item.isRequired && item.isError}
                helperText={item.isError && item.isRequired && `${item.lable} שדה חובה`}
                onChange={(e) => handleTextChange(item, e, idx)}
                id={item.id}
                ref={(el) => (refTextField.current[idx] = el)}
                disabled={localStorage.getItem('role') == '2' && props.isEdit == true ? true : false}
              ></TextField>
            </Grid>
          );
        case 'date':
          return (
            <Grid key={idx} item xs={12} className="fieldText">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  PopoverProps={{ dir: 'rtl' }}
                  color="primary"
                  inputVariant="outlined"
                  id="date-picker-dialog-outlined"
                  placeholder="לבחור תאריך"
                  // label="תאריך"
                  format="dd/MM/yyyy"
                  value={item.value != null ? item.value : null}
                  onChange={(e) => handleDateChange(item, e, idx)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  // InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={item.lable}
                  variant="inline"
                  autoOk={true}
                  className={classes.txt}
                  helperText={item.isError && item.isRequired && `${item.lable} שדה חובה או תאריך שגוי`}
                  error={item.isRequired && item.isError}
                  disabled={localStorage.getItem('role') == '2' && props.isEdit == true ? true : false}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          );
        case 'textArea':
          return (
            <Grid key={idx} item xs={12} className="fieldTextArea">
              <TextField
                className={classes.txt}
                variant="outlined"
                placeholder=""
                label={item.lable}
                value={item.value}
                InputLabelProps={{ shrink: item.value ? true : false }}
                multiline
                onChange={(e) => handleTextChange(item, e, idx)}
                disabled={localStorage.getItem('role') == '2' && props.isEdit == true ? true : false}
              ></TextField>
              {item.isError ? <FormHelperText error={item.isError}> {item.lable} שדה חובה</FormHelperText> : ''}
            </Grid>
          );
        case 'select':
          return (
            <Grid key={idx} item xs={12} className="fieldSelect">
              <FormControl className="maxWidth" color="primary" variant="outlined">
                <InputLabel style={{ color: item.isError && CheckEmptySelectValue ? '#f44336' : '' }} id={item.lable}>
                  {item.lable}
                </InputLabel>
                <Select
                  labelId={item.lable}
                  label={item.lable}
                  value={selectValue}
                  error={item.isError && CheckEmptySelectValue}
                  onChange={(e) => handleSelectChange(e, item)}
                  disabled={localStorage.getItem('role') == '2' && props.isEdit == true ? true : false}
                >
                  {item.options.map((p: string, idx: number) => {
                    return (
                      <MenuItem key={idx} value={p}>
                        {p}
                      </MenuItem>
                    );
                  })}
                </Select>
                {item.isError && CheckEmptySelectValue ? (
                  <FormHelperText error={item.isError && CheckEmptySelectValue}> {item.lable} שדה חובה</FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          );
        case 'check':
          return (
            <Grid key={idx} item xs={12} alignContent="flex-end" className="checkFld">
              <Card className={classes.check} variant="outlined">
                <CardContent className={classes.checkContent}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={item.id}
                        checked={item.value ? true : false}
                        onChange={() => handleChange(item)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={
                          (localStorage.getItem('role') == '2' || item.value == true) && props.isEdit == true
                            ? true
                            : false
                        }
                      />
                    }
                    label={item.lable}
                  />
                  {item.isError && <FormHelperText error={item.isError}> {item.lable} שדה חובה</FormHelperText>}
                </CardContent>
              </Card>
            </Grid>
          );
        case 'radio':
          return (
            <Grid key={idx} item xs={12} alignContent="flex-end" className="checkFld">
              <Card className={classes.check} variant="outlined">
                <CardContent className={classes.checkContent}>
                  <FormControlLabel
                    style={{ marginRight: '-6px', direction: 'rtl', flexDirection: 'row-reverse' }}
                    label={item.lable}
                    control={
                      <FormControl variant="outlined">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          className={classes.radioGroup}
                          value={item.value !== undefined ? item.value : -1}
                          onChange={(e) => {
                            handleRadioChange(e, item);
                          }}
                          ref={refTextError}
                        >
                          {item.childrenLables?.map((child, idx) => {
                            return (
                              <FormControlLabel
                                key={idx}
                                value={idx}
                                control={
                                  <Radio
                                    className={classes.radio}
                                    disabled={
                                      localStorage.getItem('role') == '2' && props.isEdit == true ? true : false
                                    }
                                  />
                                }
                                label={child.lable}
                                className={classes.formControlLabel}
                                style={{ marginTop: '8px' }}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    }
                  />
                  <div className={textError} style={{ color: 'red' }}>
                    שדה חובה
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        case 'label':
          return (
            <Grid item xs={12} className="fieldLabel">
              <Typography color="primary" variant="h6">
                {item.lable}
              </Typography>
            </Grid>
          );
        case 'file_arnona':
          return (
            <Grid key={idx} item xs={12} className={classes.dropZone}>
              <ArnonaInformation />
              <DropzoneArea
                dropzoneText="להעלות קבצים"
                filesLimit={10}
                showAlerts={['error']}
                alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                onChange={(e) => {
                  let helpArr = [...arr];
                  let index = arr.indexOf(item);
                  helpArr[index].value = e;
                  arrHelper[arrHelper.findIndex((a) => a.key === item.id)].value = e;
                  setArr(helpArr);
                }}
              />
              {arr[1]['valueFile'] != null &&
                props.isEdit &&
                arr[1].valueFile.map((p: string, idx: number) => {
                  return (
                    <div style={{ display: 'inline-block', margin: '8px' }}>
                      <a href={'https://backend.shoshanim.net/' + p} target="_blank">
                        {p}
                        <DescriptionIcon key={idx} style={{ fontSize: '45px' }} />
                      </a>
                    </div>
                  );
                })}
            </Grid>
          );
        default:
          break;
      }
    }
  };

  const getGridWrapper = (item: ChildData, idx: number) => {
    return <>{componentRender(item, idx)}</>;
  };
  return (
    <>
      <Grid justifyContent="center" alignItems="center" container className={classes.gridContainer}>
        <Grid item xs={12}>
          <Grid item xs={12} className="headerForm">
            <Typography variant="h5" className={classes.typography} color="secondary">
              <b>{props.title}</b>
            </Typography>
          </Grid>
          {props.topPlugin}
          <Grid
            direction="row-reverse"
            spacing={3}
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
            container
            alignContent="flex-start"
          >
            {arr.map((item, idx) => {
              if (!item.condition) return getGridWrapper(item, idx);
              let itemCondition =
                arr[arr.findIndex((s) => s.id === (item.conditionById !== undefined && item.conditionById[0]))];
              if (itemCondition && itemCondition.type === 'radio') {
                item.display = false;
                item.conditionByIdValueRadio?.forEach((element) => {
                  if (
                    itemCondition.value !== null &&
                    itemCondition.value !== undefined &&
                    itemCondition.childrenLables &&
                    itemCondition.childrenLables[itemCondition?.value] != undefined
                  ) {
                    if (itemCondition.childrenLables[itemCondition?.value].id === element) {
                      item.display = true;
                      return;
                    }
                  }
                });
              }
              return (item.condition &&
                (itemCondition?.value !== undefined && itemCondition?.value !== null ? itemCondition.value : false) ===
                  (item.conditionByIdValue !== undefined ? item.conditionByIdValue : true)) ||
                item.display ||
                !item.condition
                ? getGridWrapper(item, idx)
                : null;
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
