import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { BaseRequest } from '../../helpers/BaseRequest';
import { Button } from '../../components/buttons/button';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { getUser } from '../../components/utils/user';
import type { Salle } from '../use-salles';
import { useSalles } from '../use-salles';
import { useBranches } from '../../branches/use-branches';
import styles from './salles-settings.module.scss';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

export function SallesSettings() {
  const { sallesList } = useSalles();
  const [showDisabledSalles, setShowDisabledSalles] = useState(false);
  const [filteredSallesList, setFilteredSallesList] = useState<Salle[]>([]);
  const { branchesList } = useBranches();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [salleToDelete, setSalleToDelete] = useState<Salle | null>(null);

  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [isLoadingDisable, setLoadingDisable] = useState(false);
  const [salleToDisable, setSalleToDisable] = useState<Salle | null>(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoadingEdit, setLoadingEdit] = useState(false);
  const [salleToEdit, setSalleToEdit] = useState<Salle | null>(null);
  const [editSalleNameInput, setEditSalleNameInput] = useState('');
  const [editBrancheIdSelect, setEditBrancheIdSelect] = useState('');

  const [isLoadingAdd, setLoadingAdd] = useState(false);
  const [newSalleNameInput, setNewSalleNameInput] = useState('');
  const [newSalleBrancheId, setNewSalleBrancheId] = useState('');

  const user = getUser();
  const classes = useStyles();

  useEffect(() => {
    if (branchesList && branchesList.length > 0) {
      setNewSalleBrancheId(branchesList[0].id);
      setEditBrancheIdSelect(branchesList[0].id);
    }
  }, [branchesList]);

  useEffect(() => {
    // this is for when the edit in a row is selected, the default selected branche in the edit modal, is the branche of the current salle.
    if (salleToEdit) setEditBrancheIdSelect(salleToEdit.branche_id);
  }, [salleToEdit]);

  useEffect(() => {
    setFilteredSallesList(
      sallesList
        .filter((salle) => (showDisabledSalles ? true : salle.disabled === '0'))
        .sort(function (a, b) {
          // const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          // const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (a.branche_name === 'סולד') {
            return -1;
          }
          if (a.branche_name === 'תלפיות') {
            return 1;
          }
          // names must be equal
          return 0;
        }),
    );
  }, [sallesList, showDisabledSalles]);

  const handleSubmitDisableSalle = async () => {
    if (!salleToDisable) return;
    setLoadingDisable(true);
    const formData: FormData = new FormData();
    formData.append('id_salle', salleToDisable.id_salle);
    formData.append('token', user.token);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('disableSalle', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          setSalleToDisable(null);
          setOpenDisableModal(false);
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingDisable(false));
  };

  const handleSubmitDeleteSalle = async () => {
    if (!salleToDelete) return;
    setLoadingDelete(true);
    const formData: FormData = new FormData();
    formData.append('id_salle', salleToDelete.id_salle);
    formData.append('token', user.token);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('deleteSalle', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          setSalleToDelete(null);
          setOpenDeleteModal(false);
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingDelete(false));
  };

  const handleSubmitCreateSalle = async () => {
    if (!newSalleBrancheId || !newSalleNameInput) return;
    setLoadingAdd(true);
    const formData: FormData = new FormData();
    formData.append('name', newSalleNameInput);
    formData.append('branche_id', newSalleBrancheId);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('createSalleByBrancheId', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          setNewSalleNameInput('');
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingAdd(false));
  };

  const handleSubmitEditSalle = async () => {
    if (!editSalleNameInput || !editBrancheIdSelect || !salleToEdit) return;
    setLoadingEdit(true);
    const formData: FormData = new FormData();
    formData.append('new_name', editSalleNameInput);
    formData.append('branche_id', editBrancheIdSelect);
    formData.append('id_salle', salleToEdit.id_salle);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('editSalle', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          setEditSalleNameInput('');
          setEditBrancheIdSelect('');
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingEdit(false));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>שם חדר</TableCell>
              <TableCell>סניף</TableCell>
              <TableCell>עריכה</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSallesList.map((salle, index) => (
              <TableRow
                key={`${salle.id_salle}-${index}`}
                style={{ backgroundColor: salle.disabled === '1' ? '#e0e0e0' : undefined }}
              >
                <TableCell component="th" scope="row">
                  {salle.salle}
                  {salle.disabled === '1' && ' (חדר מבוטל)'}
                </TableCell>
                <TableCell>{salle.branche_name || 'לא הוגדר סניף'}</TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      setSalleToEdit(salle);
                      setOpenEditModal(true);
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      if (salle.disabled === '1') {
                        // this salle is disable so remove it
                        setSalleToDelete(salle);
                        setOpenDeleteModal(true);
                      } else {
                        // this salle is not disable so just disable it
                        setSalleToDisable(salle);
                        setOpenDisableModal(true);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableInfoHolder}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showDisabledSalles}
              onChange={(event) => setShowDisabledSalles(event.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="הצג חדרים מבוטלים"
          style={{ margin: 0 }}
        />
        |<div>מספר חדרים: {filteredSallesList.length}</div>
      </div>

      <Typography variant="h6" className={styles.addTitle}>
        יצירת חדר חדש
      </Typography>
      <div className={styles.addSalleHolder}>
        <TextField
          label="שם חדר"
          variant="outlined"
          size="small"
          value={newSalleNameInput}
          onChange={(e) => setNewSalleNameInput(e.target.value)}
        />
        <Select
          value={newSalleBrancheId}
          onChange={(e) => setNewSalleBrancheId(e.target.value as string)}
          variant="outlined"
          className={styles.newSalleBrancheHolder}
        >
          {branchesList?.map((branche, index) => (
            <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
              {branche.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          endIcon={<AddIcon />}
          loading={isLoadingAdd}
          onClick={handleSubmitCreateSalle}
          disabled={!newSalleNameInput || !newSalleBrancheId}
        >
          הוספה
        </Button>
      </div>

      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onSubmit={handleSubmitDeleteSalle}
        dangerButton
        title="מחיקת סניף"
        text={
          <span style={{ direction: 'rtl', textAlign: 'right' }}>
            האם אתה בטוח שברצונך למחוק את החדר <strong>{salleToDelete?.salle}</strong>
            <br />
            ?הנמצא בסניף <strong>{salleToDelete?.branche_name || 'לא הוגדר סניף'}</strong>
          </span>
        }
        loading={isLoadingDelete}
      />

      <ConfirmationModal
        open={openDisableModal}
        onClose={() => setOpenDisableModal(false)}
        onSubmit={handleSubmitDisableSalle}
        dangerButton
        title="ביטול סניף"
        text={
          <span style={{ direction: 'rtl', textAlign: 'right' }}>
            האם אתה בטוח שברצונך לבטל את החדר <strong>{salleToDisable?.salle}</strong>
            <br />
            ?הנמצא בסניף <strong>{salleToDisable?.branche_name || 'לא הוגדר סניף'}</strong>
          </span>
        }
        loading={isLoadingDisable}
      />

      <ConfirmationModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        onSubmit={handleSubmitEditSalle}
        title={`עריכת חדר ${salleToEdit?.salle}`}
        text={
          <>
            <TextField
              label="שם חדש לחדר"
              variant="outlined"
              size="small"
              placeholder={salleToEdit?.salle}
              value={editSalleNameInput}
              onChange={(e) => setEditSalleNameInput(e.target.value)}
              fullWidth
              className={styles.editNameInput}
            />
            <Select
              value={editBrancheIdSelect}
              onChange={(e) => setEditBrancheIdSelect(e.target.value as string)}
              variant="outlined"
              className={styles.editBranchesSelector}
            >
              {branchesList?.map((branche, index) => (
                <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
                  {branche.name}
                </MenuItem>
              ))}
            </Select>
          </>
        }
        submitText="עדכן"
        loading={isLoadingEdit}
      />
    </div>
  );
}
