import { ChildData } from './PersonalData';
import { tr } from 'date-fns/locale';
export default function DocumentObject() {
  const arr: ChildData[] = [
    {
      type: 'check',
      id: 'arnona',
      lable: '? האם ברצונך לבקש הנחה בעלות הטיפולים ',
      condition: false,
    },
    {
      type: 'file_arnona',
      id: 'arnona_file',
      lable: 'העלת המסמכים להנחה',
      condition: true,
      conditionById: ['arnona'],
      conditionByIdValue: true,
    },
  ];
  return arr;
}
