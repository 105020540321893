import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import type { FormResponse } from '../../types';
import type { CategoryOfResponses } from '../../base-form';
import styles from './form-responses.module.css';

export function FormResponses({ formResponses }: { formResponses?: FormResponse[] }) {
  const [openAll, setOpenAll] = useState(false);
  const rows = formResponses?.map((response) => {
    return createData(response.studentId, response.categoryOfResponses);
  });

  if (!rows) return null;
  return (
    <div className={styles.container}>
      <Button
        variant="outlined"
        color="default"
        onClick={() => setOpenAll(!openAll)}
        className={styles.openCloseAllButton}
      >
        {openAll ? (
          <>
            <KeyboardArrowUpIcon />
            <span>סגור הכל</span>
          </>
        ) : (
          <>
            <KeyboardArrowDownIcon />
            <span>פתח הכל</span>
          </>
        )}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>מספר תלמיד</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.studentId} row={row} defaultOpen={openAll} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function createData(studentId: string, categoryOfResponses: CategoryOfResponses[]) {
  return {
    studentId,
    categoryOfResponses,
  };
}

function Row({ row, defaultOpen = false }: { row: ReturnType<typeof createData>; defaultOpen?: boolean }) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <>
      <TableRow className={styles.tableRow}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.studentId}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                קטגוריות
              </Typography>
              <Table size="small" style={{ direction: 'rtl' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>שם</TableCell>
                    <TableCell>ציון</TableCell>
                    <TableCell>הערה</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.categoryOfResponses.map((categoryRow) => (
                    <CategoryRow categoryOfResponses={categoryRow} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CategoryRow({ categoryOfResponses }: { categoryOfResponses: CategoryOfResponses }) {
  const [open, setOpen] = useState(false);
  const values = categoryOfResponses.responses.map((response) => parseInt(response.responseValue));
  // 3 is the maximum value a response can have, so 3 multiplie by the length of the array, is the maximum value of all responses we can have.
  const sumResponsesValue = `${values.reduce((previous, current) => previous + current)} / ${
    categoryOfResponses.responses.length * 3
  }`;
  return (
    <>
      <TableRow key={categoryOfResponses.id}>
        <TableCell component="th" scope="row">
          {categoryOfResponses.title}
        </TableCell>
        <TableCell style={{ direction: 'ltr' }}>{sumResponsesValue}</TableCell>
        <TableCell>{categoryOfResponses.note}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                תשובות
              </Typography>
              <Table size="small" style={{ direction: 'rtl' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>מספר שאלה</TableCell>
                    <TableCell>שאלה</TableCell>
                    <TableCell>ציון</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryOfResponses.responses.map((response) => (
                    <TableRow key={response.questionId}>
                      <TableCell component="th" scope="row">
                        {response.questionId}
                      </TableCell>
                      <TableCell>{response.questionTitle}</TableCell>
                      <TableCell>{response.responseValue}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell style={{ direction: 'ltr' }}>{sumResponsesValue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
