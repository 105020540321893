import { Button, Card, CardContent, FormControl, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BaseRequest from '../helpers/BaseRequest';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '1% 15%',
    padding: '0 2% 2% 2%',
    direction: 'rtl',
  },
  cardAccordion: {
    backgroundColor: '#eaeae6',
    direction: 'rtl!important' as any,
  },
  accordionSum: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  accordionDetails: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function ZminoutMepatelim() {
  const classes = useStyles();

  const [dataZminout, setDataZminout] = useState<any[]>([]);

  useEffect(() => {
    BaseRequest('zminoutMetapelim').then((res) => {
      setDataZminout(res.data);
    });
  }, []);

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid spacing={2} direction="column" container>
          <Grid item xs={12}>
            <h1 className="primary">זמינות המטפלים</h1>
          </Grid>
          <div>
            {Object.keys(dataZminout).map((key: any, idx: number) => {
              let dataZminout_arr = dataZminout[key];
              if (new Date(dataZminout_arr[0].date).toString() == 'Invalid Date' || dataZminout_arr[0].date == null) {
                var date_formatted = '';
              } else {
                let format_date = new Date(dataZminout_arr[0].date);
                var date_formatted =
                  appendLeadingZeroes(format_date.getDate()) +
                  '-' +
                  appendLeadingZeroes(format_date.getMonth() + 1) +
                  '-' +
                  format_date.getFullYear();
              }
              return (
                <Grid item xs={12} key={idx} style={{ marginBottom: '15px' }}>
                  <Card className={classes.cardAccordion}>
                    <CardContent>
                      <Accordion style={{ backgroundColor: '#eaeae6' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${idx + 1}a-content`}
                          id={`panel${idx + 1}a-header`}
                          classes={{ content: classes.accordionSum }}
                        >
                          <Typography style={{ fontWeight: 'bold' }}>
                            {dataZminout_arr[0].prenom_intervenant + ' ' + dataZminout_arr[0].nom_intervenant}
                          </Typography>
                          <Typography>
                            <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                              {dataZminout_arr[0].prenom_eleve + ' ' + dataZminout_arr[0].nom_eleve}
                            </span>
                            יציאה ב- {date_formatted}
                          </Typography>
                        </AccordionSummary>
                        {/* {
                                            idx != 0 &&
                                            <AccordionDetails classes={{root: classes.accordionDetails}} key={idx}>
                                              <Typography style={{fontWeight: "bold", marginLeft: '5px'}}>{dataZminout_arr[0].prenom_eleve+" "+dataZminout_arr[0].nom_eleve}</Typography>
                                              <Typography>יציאה ב- {date_formatted}</Typography>
                                            </AccordionDetails>
                                        } */}
                        {dataZminout_arr.map((value: any, idx: number) => {
                          if (idx != 0) {
                            if (
                              new Date(dataZminout_arr[idx].date).toString() == 'Invalid Date' ||
                              dataZminout_arr[idx].date == null
                            ) {
                              var date_formatted = '';
                            } else {
                              let format_date = new Date(dataZminout_arr[idx].date);
                              var date_formatted =
                                appendLeadingZeroes(format_date.getDate()) +
                                '-' +
                                appendLeadingZeroes(format_date.getMonth() + 1) +
                                '-' +
                                format_date.getFullYear();
                            }
                            return (
                              <AccordionDetails classes={{ root: classes.accordionDetails }} key={idx}>
                                <Typography style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                                  {dataZminout_arr[idx].prenom_eleve + ' ' + dataZminout_arr[idx].nom_eleve}
                                </Typography>
                                <Typography>יציאה ב- {date_formatted}</Typography>
                              </AccordionDetails>
                            );
                          }
                        })}
                      </Accordion>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
}
