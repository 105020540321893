export type DataType = { key: string; value: string | number };

export type PrintOption = {
  key: number;
  value: string;
};

export const printOptionsList: PrintOption[] = [
  {
    key: 1,
    value: 'מידע כללי אודות הילד',
  },
  {
    key: 2,
    value: 'מידע משפחתי',
  },
  {
    key: 3,
    value: 'מידע רפואי',
  },
  {
    key: 4,
    value: 'מידע לימודי',
  },
];
