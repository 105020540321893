import { useEffect, useState, useCallback } from 'react';
import BaseRequest from '../../helpers/BaseRequest';
import { useBranches } from '../../branches/use-branches';
import type { Salle } from './types';

export function useSalles(): { sallesList: Salle[]; getAllSalles: () => Promise<void> } {
  const { branchesList } = useBranches();
  const [sallesList, setSallesList] = useState<Salle[]>([]);

  const getAllSalles = useCallback(async () => {
    await BaseRequest('getSalle')
      .then((result) => {
        if (result.success === 1) {
          const newSallesList: Salle[] = result.data.map((item: any) => {
            const s: Salle = {
              ...item,
              branche_name: branchesList?.find((branche) => branche.id === item.branche_id)?.name || '',
            };
            return s;
          });
          setSallesList(newSallesList);
        }
      })
      .catch((error) => console.log(error));
  }, [branchesList]);

  useEffect(() => {
    if (branchesList && branchesList?.length > 0) getAllSalles();
  }, [branchesList, getAllSalles]);

  return {
    sallesList,
    getAllSalles,
  };
}
