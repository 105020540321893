import React from 'react';
import ChildDetails from './ChildDetails';
import { SupportButton } from './buttons/support-button';

export default function Register() {
  return (
    <>
      <ChildDetails isEdit={false} />
      <SupportButton />
    </>
  );
}
