import React, { HTMLAttributes, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  Paper,
  Typography,
  Divider,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Check as CheckIcon, Link as LinkIcon } from '@material-ui/icons';
import { getUser } from '../../components/utils/user';
import BaseRequest from '../../helpers/BaseRequest';
import { Ring as Spinner } from '@joshk/react-spinners-css.ring';
import styles from './new-students.module.css';

type NewStudentsProps = HTMLAttributes<HTMLDivElement>;

type StudentData = {
  avatar_url: string | null;
  id: string;
  personal_data: string;
};

export function NewStudents({ className, ...rest }: NewStudentsProps) {
  const navigate = useNavigate();
  const user = getUser();
  const [newStudents, setNewStudents] = useState<StudentData[]>([]);
  const [acceptedStudent, setAcceptedStudent] = useState('');
  const [showMore, setShowMore] = useState(false);

  const getNewStudents = useCallback(async () => {
    const formData = new FormData();
    const obj = {
      user: user.userName,
    };
    formData.append('data', JSON.stringify(obj));
    await BaseRequest('getNewstudent', formData)
      .then((res) => {
        if (res.success) setNewStudents(res.data);
        else setNewStudents([]);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    getNewStudents();
  }, [getNewStudents]);

  const confirmNewStudent = async (studentId: string) => {
    setAcceptedStudent(studentId);
    const formData: FormData = new FormData();
    formData.append('username', user.userName);
    formData.append('student_id', studentId);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('confirmNewStudent', formData, headersOptions)
      .then(async (result) => {
        await getNewStudents();
      })
      .catch((error) => {});
  };

  if (!newStudents.length) return null;

  return (
    <Paper {...rest} className={classNames(styles.container, className)} elevation={3}>
      <Typography variant="h4">טפסים חדשים</Typography>
      <List>
        {newStudents.map((student, index) => {
          const personalData = [...JSON.parse(student.personal_data.replace(/'/g, '\\"'))];
          const personalDataObject = {};
          personalData.forEach((element) => {
            // @ts-ignore
            personalDataObject[element.key] = element.value;
          });
          // @ts-ignore
          const firstName = personalDataObject.firstName;
          // @ts-ignore
          const lastName = personalDataObject.lastName;
          return (
            <div key={`${index}-${student.id}`} style={{ display: index > 9 && !showMore ? 'none' : undefined }}>
              <Divider />
              <ListItem className={styles.listItem}>
                <ListItemAvatar>
                  <Avatar src={student.avatar_url || ''}>
                    {firstName.substring(0, 1)}
                    {lastName.substring(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${firstName} ${lastName}`} />
                <ListItemSecondaryAction>
                  <Tooltip title="אשר קבלה" arrow>
                    <IconButton edge="end" aria-label="delete" onClick={() => confirmNewStudent(student.id)}>
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="צפייה" arrow>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        navigate(`/viewChild/${student.id}`, { state: { child: personalDataObject } });
                      }}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>

                  {student.id === acceptedStudent && (
                    <IconButton disabled>
                      <Spinner size={24} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          );
        })}
        {newStudents.length > 10 && (
          <div className={styles.showMore} onClick={() => setShowMore(!showMore)}>
            {showMore ? 'הצג פחות' : 'הצג הכל'}
          </div>
        )}
      </List>
    </Paper>
  );
}
