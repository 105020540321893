import React, { useState, useEffect } from 'react';
import GenerateControls from './GenerateControls';
import { FileProps } from './personal-file';
import { MedicalData } from './MedicalData';
import { ServerData, ChildData } from './PersonalData';
import BaseRequest from '../helpers/BaseRequest';
export default function MedicalFile(props: FileProps) {
  const [arr, setArr] = useState<ChildData[]>(MedicalData());

  // if(props.serverData.length > 0){
  //   arr.forEach((element, idx)=> {
  //     if(props.serverData[idx] != undefined){
  //       element.value = props.serverData[idx]['value']
  //     }
  //   })
  // }

  const fetchData = () => {
    let server: ServerData[] = [];
    if (!props.isEdit) {
      arr.forEach((item, idx) => {
        item.id && server.push({ key: item.id, value: item.value !== undefined ? item.value : null });
      });
      props.setServerData(server);
    } else {
      let arrHelper = [...arr];
      const formData = new FormData();
      formData.append('student', props.id || '');
      BaseRequest('getStudent', formData)
        .then((res) => {
          let arrS: any[];
          let d = res.data[0].medical_data.replace(/'/g, '\\"');
          arrS = [...JSON.parse(d)];
          arrS.forEach((item) => {
            if (item.value === true) {
              item.value = 0;
            }
            if (item.value === false) {
              item.value = 1;
            }
          });
          props.setServerData(arrS);
          arrS.forEach((item, idx) => {
            if (arrHelper[arrHelper.findIndex((i) => i.id === item.key)] != undefined) {
              arrHelper[arrHelper.findIndex((i) => i.id === item.key)].value = item.value;
            }
          });
          arrHelper.forEach((item, idx) => {
            if (arrS[arrS.findIndex((i) => i.key === item.id)] == undefined) {
              arrS.push({ key: item.id, value: null });
            }
          });
          setArr(arrHelper);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <GenerateControls
      handleNext={props.handleNext}
      isEdit={props.isEdit}
      serverData={props.serverData}
      setServerData={props.setServerData}
      arr={arr}
      fileName="medical"
      title={props.title}
    />
  );
}
