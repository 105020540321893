import React, { ReactNode } from 'react';
import { BranchesContext } from './branches-context';
import type { BranchesContextType } from './branches-context';

export type BranchesProviderProps = {
  children: ReactNode;
} & BranchesContextType;

export function BranchesProvider({
  children,
  branchesList,
  selectedBranche,
  setCurrentBranche,
}: BranchesProviderProps) {
  return (
    <BranchesContext.Provider value={{ branchesList, selectedBranche, setCurrentBranche }}>
      {children}
    </BranchesContext.Provider>
  );
}
