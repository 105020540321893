import React, { useState, useEffect, useRef } from 'react';
import { PersonalData, ChildData, ServerData } from '../PersonalData';
import GenerateControls from '../GenerateControls';
import BaseRequest from '../../helpers/BaseRequest';
import { StudentAvatar, TEMP_STUDENT_AVATAR_NAME } from './student-avatar';

export interface FileProps {
  title: string;
  isEdit: boolean;
  handleNext: Function;
  serverData: ServerData[];
  setServerData: Function;
  id?: string;
  setFinish?: Function;
}

export function PersonalFile(props: FileProps) {
  const arr = useRef<ChildData[]>(PersonalData());
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  // const [missingAvatar, setMissingAvatar] = useState(false);

  const next = () => {
    let isValid = true;
    let arrHelper = [...arr.current];
    arrHelper.forEach((a) => {
      if (a.isRequired && !a.value) {
        a.isError = true;
        isValid = false;
      }
    });
    // const tempStudentAvatar = localStorage.getItem(TEMP_STUDENT_AVATAR_NAME);
    // if (!tempStudentAvatar && props.isEdit === false) {
    //   isValid = false;
    //   setMissingAvatar(true);
    // }
    if (isValid) {
      props.handleNext();
    } else {
      arr.current = arrHelper;
    }
  };

  const fetchData = () => {
    let server: ServerData[] = [];
    if (!props.isEdit) {
      arr.current.forEach((item, idx) => {
        item.id && server.push({ key: item.id, value: item.value !== undefined ? item.value : null });
      });
      props.setServerData(server);
    } else {
      let arrHelper = [...arr.current];
      const formData = new FormData();
      formData.append('student', props.id || '');
      BaseRequest('getStudent', formData)
        .then((res) => {
          let arrS: any[];
          let d = res.data[0].personal_data.replace(/'/g, '\\"');
          arrS = [...JSON.parse(d)];
          arrS.forEach((item) => {
            if (item.value === true) {
              item.value = 0;
            }
            if (item.value === false) {
              item.value = 1;
            }
          });
          arrS.forEach((item, idx) => {
            if (arrHelper[arrHelper.findIndex((i) => i.id === item.key)] != undefined) {
              arrHelper[arrHelper.findIndex((i) => i.id === item.key)].value = item.value;
            }
          });
          arrHelper.forEach((item, idx) => {
            if (arrS[arrS.findIndex((i) => i.key === item.id)] == undefined) {
              arrS.push({ key: item.id, value: null });
            }
          });
          props.setServerData(arrS);
          arr.current = arrHelper;
          setAvatarUrl(res.data[0].avatar_url);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    localStorage.removeItem(TEMP_STUDENT_AVATAR_NAME);
    fetchData();
  }, []);

  return (
    <GenerateControls
      arr={arr.current}
      fileName="personal"
      handleNext={next}
      isEdit={props.isEdit}
      serverData={props.serverData}
      setServerData={props.setServerData}
      title={props.title}
      topPlugin={
        <StudentAvatar
          avatarUrl={avatarUrl}
          idElev={props.id || ''}
          // error={
          //   missingAvatar &&
          //   props.isEdit === false
          // }
        />
      }
    />
  );
}
