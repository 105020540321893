import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, InputLabel, Select, MenuItem, FormHelperText, InputAdornment } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BaseRequest from '../helpers/BaseRequest';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { CustomAutoComplete } from './custom-autocomplete';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { useBranches } from '../branches/use-branches';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  icon: {
    marginTop: 2,
  },
  grid: {
    direction: 'rtl',
  },
  mainGrid: {
    marginTop: 3,
  },
  button: {
    marginRight: '8px',
  },
}));

interface CourseProps {
  ok: Function;
}

export interface AutoCompleteList {
  id?: string;
  value?: string;
  isError?: boolean;
  lastName?: string;
  firstName?: string;
}

export default function AddCourse(props: CourseProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [intervenant, setIntervenant] = useState<AutoCompleteList[]>([]);
  const [students, setStudents] = useState<AutoCompleteList[]>([]);
  const [salle, setSalle] = useState<any[]>([]);
  const [child, setChild] = useState<AutoCompleteList>();
  const [teacher, setTeacher] = useState<AutoCompleteList>();
  const [tarifCours, setTarifCours] = useState('');
  const [tachloumMichpacha, setTachloumMichpacha] = useState(0);
  const [tachloumShoshanim, setTachloumShoshanim] = useState(0);
  const [tachloumLeumit, setTachloumLeumit] = useState(0);
  const [tachloumRevacha, setTachloumRevacha] = useState(0);
  const [tachloumIriya, setTachloumIriya] = useState(0);
  const [hourCours, setHourCours] = useState('');
  const [chiourComment, setChiourComment] = useState('');
  const [checkAllYear, setCheckAllYear] = useState(false);
  const hitpatchoutOptions = [
    { label: 'כן', value: '1' },
    { label: 'לא', value: '0' },
  ];
  const [errors, setErrors] = useState({
    id_interv: false,
    id_elev: false,
    date_cours: false,
    hour_cours: false,
    kavoua: false,
    id_salle: false,
    id_mode_fact: false,
    hitpatchout: false,
  });
  const [selectedClass, setSelectedClass] = useState<any>('');
  const [hitpatchout, setHitpatchout] = useState<any>('');
  const { branchesList, selectedBranche } = useBranches();
  const [selectedBrancheId, setSelectedBrancheId] = useState(selectedBranche?.id);

  useEffect(() => {
    getIntervant();
    getStudent();
  }, []);

  useEffect(() => {
    if (selectedBranche?.id) setSelectedBrancheId(selectedBranche?.id);
  }, [selectedBranche?.id]);

  const getClasses = useCallback(async () => {
    await BaseRequest('getSalle').then((res) => {
      const result: any[] = res.data;
      setSalle(result.filter((salle) => (salle.branche_id !== null ? salle.branche_id === selectedBrancheId : true)));
    });
  }, [selectedBrancheId]);

  useEffect(() => {
    getClasses();
  }, [selectedBrancheId, getClasses]);

  const getStudent = () => {
    BaseRequest('getStudent')
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({ id: item.id, value: `${item.firstName} ${item.lastName}` });
        });
        setStudents(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getIntervant = () => {
    BaseRequest('getIntervenant')
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({ id: item.id_interv, value: `${item.prenom} ${item.nom}` });
        });
        setIntervenant(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDateChange = (date: Date | null) => {
    if (new Date(date || '').toString() != 'Invalid Date') {
      setSelectedDate(date);
    }
  };

  const handleHourChange = (e: any) => {
    setHourCours(e.target.value);
  };

  const createCourse = () => {
    let allValid = true;
    var date: any;
    var curr = selectedDate ? new Date(selectedDate) : null;
    if (curr) {
      curr.setDate(curr.getDate());
      date = curr.toISOString().substr(0, 10);
    } else date = null;
    let newCourse: any;
    const obj = {
      id_interv: teacher?.id,
      id_elev: child?.id,
      date_cours: selectedDate?.toDateString() ? date : '',
      // hour_cours: selectedDate?.toDateString() ? selectedDate?.toLocaleTimeString() : "",
      hour_cours: hourCours,
      kavoua: checkAllYear ? '1' : '0',
      id_salle: selectedClass,
      branche_id: selectedBrancheId,
      id_mode_fact: '2',
      tarif_cours: tarifCours,
      tachloumMichpacha: tachloumMichpacha,
      tachloumShoshanim: tachloumShoshanim,
      tachloumLeumit: tachloumLeumit,
      tachloumRevacha: tachloumRevacha,
      tachloumIriya: tachloumIriya,
      hitpatchout: hitpatchout,
      chiour_comment: chiourComment,
    };
    newCourse = { ...obj };
    let keys = Object.keys(newCourse);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (
        !newCourse[k] &&
        k !== 'chiour_comment' &&
        k !== 'tarif_cours' &&
        k !== 'tachloumMichpacha' &&
        k !== 'tachloumShoshanim' &&
        k != 'tachloumLeumit' &&
        k != 'tachloumRevacha' &&
        k != 'tachloumIriya'
      ) {
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    if (allValid) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(newCourse));
      BaseRequest('createCours', formData).then((res) => {
        // history.push({ pathname: '/course' })
        navigate('/course');
      });
    } else setErrors(errorsHelper);
  };

  return (
    <Grid spacing={3} direction="column" container className="rtl">
      <Grid container direction="row-reverse" spacing={3} justifyContent="center" item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            הוספת טיפול
          </Typography>
        </Grid>
        <Grid className={classes.mainGrid} item>
          <AddIcon color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl color="primary" variant="outlined" className={classes.formControl}>
          <CustomAutoComplete data={intervenant} label="שם מטפל" setValue={setTeacher} isError={errors['id_interv']} />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl color="primary" variant="outlined" className={classes.formControl}>
          <CustomAutoComplete label={'שם הילד'} data={students} setValue={setChild} isError={errors['id_elev']} />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            PopoverProps={{ dir: 'rtl' }}
            className="maxWidth"
            color="primary"
            inputVariant="outlined"
            margin="normal"
            id="date-picker-dialog-outlined"
            label="תאריך"
            format="dd/MM/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            variant="inline"
            error={!selectedDate && errors.date_cours}
            helperText={!selectedDate && errors.date_cours ? 'תאריך חובה או תאריך שגוי' : ''}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="time"
          label="שעה"
          type="time"
          defaultValue={hourCours}
          onChange={handleHourChange}
          className="maxWidth"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
            min: '07:00',
            max: '22:00',
          }}
          error={!hourCours && errors.hour_cours}
          helperText={!hourCours && errors.hour_cours ? 'שעה חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="עלות מיוחדת"
          type="number"
          variant="outlined"
          value={tarifCours}
          onChange={(e) => setTarifCours(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {parseFloat(tarifCours) > 0 && tarifCours.toString() != '' && (
        <>
          <Grid item xs={12}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="תשלום מהמשפחה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumMichpacha}
              onChange={(e) => setTachloumMichpacha(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="הנחת שושנים בשקלים"
              type="number"
              variant="outlined"
              value={tachloumShoshanim}
              onChange={(e) => setTachloumShoshanim(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="הנחת תכנית הלאומית בשקלים"
              type="number"
              variant="outlined"
              value={tachloumLeumit}
              onChange={(e) => setTachloumLeumit(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="הנחת רווחה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumRevacha}
              onChange={(e) => setTachloumRevacha(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="maxWidth"
              id="outlined-basic"
              label="הנחת ערייה בשקלים"
              type="number"
              variant="outlined"
              value={tachloumIriya}
              onChange={(e) => setTachloumIriya(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faShekelSign} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>סניף</InputLabel>
          <Select
            value={selectedBrancheId}
            onChange={(e) => setSelectedBrancheId(e.target.value as string)}
            style={{ textAlign: 'right' }}
          >
            {branchesList?.map((branche, index) => (
              <MenuItem style={{ direction: 'rtl' }} value={branche.id}>
                {branche.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ color: errors.id_salle && !selectedClass ? '#f44336' : '' }}
            id="demo-simple-select-outlined-label"
          >
            חדר
          </InputLabel>
          <Select
            onChange={(e) => {
              setSelectedClass(e.target.value);
            }}
            label="חדר"
            value={selectedClass}
            error={errors.id_salle && !selectedClass}
            style={{ textAlign: 'right' }}
          >
            {salle.map((s, idx) => {
              return (
                <MenuItem key={idx} value={s.id_salle}>
                  {s.salle}
                </MenuItem>
              );
            })}
          </Select>
          {errors.id_salle && !selectedClass && <FormHelperText error={true}>חדר שדה חובה</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ color: errors.hitpatchout && !hitpatchout ? '#f44336' : '' }}
            id="demo-simple-select-outlined-label"
          >
            התפתחות הילד
          </InputLabel>
          <Select
            onChange={(e) => {
              setHitpatchout(e.target.value);
            }}
            label="התפתחות הילד"
            value={hitpatchout}
            error={errors.hitpatchout && !hitpatchout}
            style={{ textAlign: 'right' }}
          >
            {hitpatchoutOptions.map((s, idx) => {
              return (
                <MenuItem key={idx} value={s.value}>
                  {s.label}
                </MenuItem>
              );
            })}
          </Select>
          {errors.hitpatchout && !hitpatchout && <FormHelperText error={true}> התפתחות הילד שדה חובה </FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="הערה כללית על הטיפול"
          variant="outlined"
          multiline
          value={chiourComment}
          onChange={(e) => setChiourComment(e.target.value)}
        />
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkAllYear}
                onChange={() => {
                  setCheckAllYear(!checkAllYear);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="להוסיף 15 טיפולים"
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.grid} direction="row" xs={12}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={createCourse}>
            אישור
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              props.ok();
            }}
            className={classes.button}
          >
            ביטול
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
