import React, { useState } from 'react';
import BaseRequest from '../helpers/BaseRequest';
import { Grid, Typography, TextField, Button, makeStyles } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';

interface AddCommentProps {
  course: any;
  ok: Function;
}
const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 3,
  },
  txt: {
    width: '100%',
    direction: 'ltr',
  },
  icon: {
    marginTop: 2,
  },
  buttonFile: {
    width: '100%',
    marginBottom: '15px',
  },
  displayAlert: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  notDisplayAlert: {
    display: 'none',
  },
}));

export default function AddComment(props: AddCommentProps) {
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<any[]>([]);

  const handleChangeFile = (files: any) => {
    setFiles(files);
  };

  const uploadFileAndComment = () => {
    if (comment != '' || files.length > 0) {
      var file = [...files];
      var file = files;

      const json = {
        id_interv: props.course.id_interv,
        id_elev: props.course.id_elev,
        comment: comment,
        date_cours: props.course.date_cours,
        id_cours: props.course.id_cours,
      };

      var formData = new FormData();
      formData.append('data', JSON.stringify(json));
      for (var i = 0; i < file.length; i++) {
        formData.append(`files[]`, file[i]);
      }
      const headersOptions = {
        'Content-Type': 'multipart/form-data',
      };
      BaseRequest('UploadFileComment', formData, headersOptions).then((res) => {
        if (res.success == 1) {
          props.ok();
        }
      });
    }
  };

  return (
    <Grid container xs={12} spacing={3} justifyContent="center" alignItems="center">
      <Grid container direction="row-reverse" spacing={3} justifyContent="center" item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            הוספת הערה
          </Typography>
        </Grid>
        <Grid className={classes.grid} item>
          <CommentIcon color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.txt}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          variant="outlined"
          value={comment}
          multiline
          label="הערה"
        ></TextField>
      </Grid>
      {/* <Grid spacing={1} item container alignItems="flex-start" justifyContent="flex-start" direction="row" xs={12}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={addComment}
          >
            אישור
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => { props.ok() }}
          >
            ביטול
          </Button>
        </Grid> 
      </Grid> */}
      <Grid item xs={12} style={{ direction: 'rtl' }}>
        <DropzoneArea
          dropzoneText="להעלות קבצים"
          filesLimit={10}
          showAlerts={['error']}
          alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
          onChange={handleChangeFile}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={uploadFileAndComment} className={classes.buttonFile}>
          אישור
        </Button>
      </Grid>
    </Grid>
  );
}
