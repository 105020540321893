import { useContext, useEffect, useState } from 'react';
import BaseRequest from '../../helpers/BaseRequest';
import { BranchesContext } from './branches-context';
import type { BranchesContextType } from './branches-context';
import type { Branche } from './types';

export function useBranches(): BranchesContextType {
  const branchesFromContext = useContext(BranchesContext);
  const [branchesList, setBranchesList] = useState<Branche[] | undefined>(branchesFromContext.branchesList);
  const [selectedBranche, setSelectedBranche] = useState<Branche | undefined>(branchesFromContext.selectedBranche);

  useEffect(() => {
    getAllBranches();
  }, []);

  const getAllBranches = async () => {
    await BaseRequest('getAllBranches', undefined, undefined, 'get')
      .then((result) => {
        if (result.success === 1) {
          const newBranchesList: Branche[] = result.data.map((item: any) => item);
          setBranchesList(newBranchesList);
          const savedBrancheId = localStorage.getItem('selectedBrancheId');
          if (savedBrancheId) {
            const savedBranche = newBranchesList.find((branche) => branche.id === savedBrancheId);
            if (savedBranche) setSelectedBranche(savedBranche);
          } else {
            setSelectedBranche(newBranchesList[0]);
          }
        } else {
          setBranchesList([]); // if list is empty
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSetSelectedBranche = (brancheId: string) => {
    const branche = branchesList?.find((branche) => branche.id === brancheId);
    localStorage.setItem('selectedBrancheId', brancheId);
    setSelectedBranche(branche);
  };

  return {
    branchesList,
    selectedBranche,
    setCurrentBranche: handleSetSelectedBranche,
  };
}
