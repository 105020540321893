import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../assets/shoshanim_transparent.png';
import { useNavigate, NavLink } from 'react-router-dom';
import BaseRequest from '../helpers/BaseRequest';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { getRole, Roles } from './utils/roles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    height: 60,
    width: 70,
    float: 'right',
    cursor: 'pointer',
  },
  alert_display: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
    width: '100%',
  },
  alert_hide: {
    display: 'none',
  },
}));

export default function SignIn() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [classeAlert, setClasseAlert] = useState(classes.alert_hide);
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const userRole = getRole();

  const handleChange = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const signIn = () => {
    const formData = new FormData();
    formData.append('user', userName);
    formData.append('password', password);
    BaseRequest('LoginProc', formData)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem('userName', res.name);
        localStorage.setItem('token', res.token);
        localStorage.setItem('id', res.related_id);
        localStorage.setItem('role', res.role);
        localStorage.setItem('user_id', res.user_id);
        localStorage.setItem('user_color', res.color);
        if (res.role === Roles.Menahel || res.role === Roles.Secretaire || res.role === Roles.MenahelBliKsafim)
          navigate('/home');
        else navigate('/teacherHome');
      })
      .catch((e: any) => {
        if (e.response.status == 401) {
          setClasseAlert(classes.alert_display);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img style={{ width: 200 }} src={Logo} alt="logo" />
        <Typography component="h1" variant="h5">
          כניסה
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="שם משתמש"
            name="userName"
            autoComplete="userName"
            onChange={(e) => setUserName(e.target.value)}
            autoFocus
          />
          <FormControl variant="outlined" style={{ display: 'flex' }}>
            <InputLabel htmlFor="outlined-adornment-password" style={{ fontSize: '1.3rem' }}>
              סיסמה
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={signIn}>
            כניסה
          </Button>
          <NavLink to="/Register">להירשם בתוך ילד</NavLink>
        </form>
        <Alert variant="filled" severity="error" className={classeAlert}>
          <span style={{ marginLeft: '3px' }}>שם משתמש או סיסמה לא תקין </span>
        </Alert>
      </div>
    </Container>
  );
}
