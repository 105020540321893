import { FormControl, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BaseRequest from '../helpers/BaseRequest';
import { AutoCompleteList } from './AddCourse';
import { CustomAutoComplete } from './custom-autocomplete';

import Comment from './Comment';
import ChildDataService from './ChildDataService';
import { Subscription } from 'rxjs';
interface CommentsProps {
  id: string;
  isTeacher?: boolean;
}
export interface CommentData {
  id_interv: string;
  id_elev: string;
  id: string;
  date_heure: string;
  commentaire: string;
  nom: string;
  prenom: string;
  activite: string;
  document: any;
  date_cours: string;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
}));
export default function Comments(props: CommentsProps) {
  const [comments, setComments] = useState<CommentData[]>([]);
  const [displayComments, setDisplayComments] = useState<CommentData[]>([]);
  const [intervenant, setIntervenant] = useState<AutoCompleteList[]>([]);
  const [teacher, setTeacher] = useState<AutoCompleteList>();
  const classes = useStyles();

  useEffect(() => {
    getComments();
    if (!props.isTeacher) getIntervant();
  }, []);
  let s: Subscription;

  useEffect(() => {
    s = ChildDataService.commentNext$.subscribe(() => {
      getComments();
    });
    return () => {
      s.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let c = comments.filter((c) => {
      return teacher ? c.id_interv === teacher.id : true;
    });
    setDisplayComments(c);
  }, [teacher]);

  const getComments = () => {
    const formdata = new FormData();
    let data: any = {
      role: localStorage.getItem('role'),
      id_interv: localStorage.getItem('id'),
      student: props.id,
    };
    // if (props.isTeacher){
    //   data['id_interv'] = props.id;
    // }
    // else{
    //   data['student'] = props.id;
    // }
    formdata.append('data', JSON.stringify(data));
    // formdata.append("student", props.id)
    BaseRequest('getComment', formdata)
      .then((res) => {
        setComments(res.data);
        setDisplayComments(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getIntervant = () => {
    const formdata = new FormData();
    if (localStorage.getItem('role') == '2') {
      let data: any = {
        data: 'fromComment',
      };
      formdata.append('data', JSON.stringify(data));
    }
    BaseRequest('getIntervenant', formdata)
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({ id: item.id_interv, value: `${item.prenom} ${item.nom}` });
        });
        setIntervenant(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Grid spacing={2} direction="row" container justifyContent="flex-end">
      <Grid direction="row" item xs={12} justifyContent="flex-end" container>
        <Grid item xs={3}>
          {!props.isTeacher ? (
            <FormControl color="primary" variant="outlined" className={classes.formControl}>
              <CustomAutoComplete
                label="שם מטפל"
                data={intervenant}
                setValue={setTeacher}
                // isLabelShrink={true}
              />
            </FormControl>
          ) : (
            <p style={{ textDecoration: 'underline' }} className="primary">
              הערות
            </p>
          )}
        </Grid>
      </Grid>
      {displayComments.length > 0 &&
        displayComments.map((item, idx) => {
          return (
            <Grid key={idx} item xs={6}>
              <Comment item={item} />
            </Grid>
          );
        })}
    </Grid>
  );
}
