import React from 'react';
import Home from './components/Home';
import SignIn from './components/Login.comonent';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import InfosFinancieres from './components/InfosFinancieres';
import NextTreatments from './components/NextTreatments';
import CourseList from './components/CourseList';
import TeacherList from './components/TeachersList';
import { PersonalFile } from './components/personal-file';
import Comments from './components/Comments';
import ViewChild from './components/ViewChild';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './components/Register';
import StudentList from './components/StudentsList';
import Finance from './components/Finance';
import EditTeacher from './components/EditTeacher';
import TeacherHome from './components/TeacherHome';
import TeacherReport from './components/TeacherReport';
import ViewTeacher from './components/ViewTeacher';
import ZmninoutMetapelim from './components/ZminoutMetapelim';
import { getRole, Roles } from './components/utils/roles';
import { DefaultFormIndex } from './components/forms/default';
import { QuestionsCognitiveFunction } from './components/forms/questions-cognitive-function';
import { Settings as SettingsPage } from './pages/settings';
import { BranchesProvider, useBranches } from './branches/use-branches';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset, ThemeProvider, createTheme } from '@material-ui/core/styles';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const userRole = getRole();
  const { branchesList, selectedBranche, setCurrentBranche } = useBranches();

  const rtlTheme = createTheme({
    typography: {
      fontFamily: 'Arimo',
    },
    direction: 'rtl',
    palette: {
      primary: {
        main: '#14738c',
      },
      secondary: {
        main: '#1e3e79',
      },
      success: {
        main: '#00C851',
      },
    },
  });

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={rtlTheme}>
        <BranchesProvider
          branchesList={branchesList}
          selectedBranche={selectedBranche}
          setCurrentBranche={setCurrentBranche}
        >
          <div className="App">
            <Routes>
              <Route
                path="/forms"
                element={
                  <PrivateRoute>
                    <DefaultFormIndex />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings/*"
                element={
                  <PrivateRoute>
                    <SettingsPage />
                  </PrivateRoute>
                }
              ></Route>
              <Route path="/forms/questions-cognitive-function" element={<QuestionsCognitiveFunction />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<Register />} />
              <Route
                index
                path="/"
                element={
                  (userRole === Roles.Menahel ||
                    userRole === Roles.MenahelBliKsafim ||
                    userRole === Roles.Secretaire) &&
                  localStorage.getItem('token') ? (
                    <Navigate to="/home" />
                  ) : (
                    <Navigate to="/teacherHome" />
                  )
                }
              />

              <Route
                path="/course"
                element={
                  <PrivateRoute>
                    <CourseList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/finance"
                element={
                  <PrivateRoute>
                    <Finance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/teachers"
                element={
                  <PrivateRoute>
                    <TeacherList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/students"
                element={
                  <PrivateRoute>
                    <StudentList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/zminout"
                element={
                  <PrivateRoute>
                    <ZmninoutMetapelim />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reportT"
                element={
                  <PrivateRoute>
                    <TeacherReport />
                  </PrivateRoute>
                }
              />
              <Route
                path="/personalFile"
                element={
                  <PrivateRoute>
                    {/** @ts-ignore */}
                    <PersonalFile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/comments"
                element={
                  <PrivateRoute>
                    {/** @ts-ignore */}
                    <Comments />
                  </PrivateRoute>
                }
              />
              <Route
                path="/nextTreatment"
                element={
                  <PrivateRoute>
                    {/** @ts-ignore */}
                    <NextTreatments />
                  </PrivateRoute>
                }
              />
              <Route
                path="/infosFinancieres"
                element={
                  <PrivateRoute>
                    {/** @ts-ignore */}
                    <InfosFinancieres />
                  </PrivateRoute>
                }
              />
              <Route
                path="/viewChild/:id"
                element={
                  <PrivateRoute>
                    <ViewChild />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editTeacher/:id"
                element={
                  <PrivateRoute>
                    <EditTeacher />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ViewTeacher/:id"
                element={
                  <PrivateRoute>
                    <ViewTeacher />
                  </PrivateRoute>
                }
              />
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/teacherHome"
                element={
                  <PrivateRoute>
                    <TeacherHome />
                  </PrivateRoute>
                }
              />

              {/* <PrivateRoute exact path="/demo" element={DemoApp} /> */}
            </Routes>
          </div>
        </BranchesProvider>
      </ThemeProvider>
    </StylesProvider>
    // <div>
    //   <PDFDownloadLink
    //     document={<TeacherReport a="sss" />}
    //     fileName="somename.pdf"
    //   >
    //     {({ blob, url, loading, error }) =>
    //       loading ? "Loading document..." : "הורד עכשיו"
    //     }
    //   </PDFDownloadLink>
    // </div>
  );
}
export default App;
