import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { CommentData } from './Comments';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    textAlign: 'left',
    height: '185px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  typhography: {
    fontWeight: 'bold',
  },
});

interface CommentProps {
  item: CommentData;
}
export default function Comment(props: CommentProps) {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.card}>
        <Typography color="textSecondary" gutterBottom>
          {props.item.prenom} {props.item.nom} - {props.item.activite}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {props.item.date_heure}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {props.item.date_cours != null ? props.item.date_cours : ''} : תאריך הטיפול
        </Typography>
        <Typography className={classes.typhography} color="textSecondary" gutterBottom>
          {props.item.commentaire}
        </Typography>
        {'document' in props.item && (
          <div>
            <Typography color="textSecondary" gutterBottom>
              קבצים
            </Typography>
            {props.item.document.map((doc: any, idx: number) => {
              return (
                <Typography className={classes.typhography} gutterBottom>
                  {doc.image && <img src={'https://backend.shoshanim.net/' + doc.path} key={idx} width="150" />}
                  {!doc.image && (
                    <a href={'https://backend.shoshanim.net/' + doc.path} key={idx} target="_blank">
                      {doc.name_file}
                    </a>
                  )}
                </Typography>
              );
            })}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
