import React from 'react';
import classNames from 'classnames';
import { WhatsappSVG } from './icons';
import styles from './whatsapp-button.module.css';

export type WhatsappButtonProps = {
  phoneNumber: string;
  message?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function WhatsappButton({ phoneNumber, message, className }: WhatsappButtonProps) {
  const link = message
    ? `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
    : `https://wa.me/${phoneNumber}`;

  return (
    <div className={classNames(styles.whatsappButton, className)}>
      <a aria-label="Chat on WhatsApp" href={link} target="_blank" rel="noreferrer">
        <WhatsappSVG />
      </a>
    </div>
  );
}
