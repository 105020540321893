import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import File from './File';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert } from '@material-ui/lab';
import BaseRequest from '../helpers/BaseRequest';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  wrapperGrid: {
    height: '80%',
  },
  singleFile: {
    marginBottom: 20,
    marginTop: 20,
  },
  file: {
    width: 300,
  },
  dropZone: {
    marginBottom: 20,
    direction: 'rtl!important' as any,
  },
  displayAlert: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  notDisplayAlert: {
    display: 'none',
  },
});

interface AttachFilesProps {
  name: string;
  id: string;
}

export default function AttachFiles(props: AttachFilesProps) {
  const classes = useStyles();
  const [files, setFiles] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileId, setFileId] = useState('');
  const [classeAlert, setClasseAlert] = useState(classes.notDisplayAlert);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    const formData: FormData = new FormData();
    let data = {
      id_elev: props.id,
    };
    formData.append('data', JSON.stringify(data));
    BaseRequest('getDocument', formData).then((res) => {
      setFiles(res.data);
    });
  };

  const handleChangeFile = (files: any) => {
    setSelectedFiles(files);
  };

  const handleClickDelete = (file_id: string) => {
    setShowModal(true);
    setFileId(file_id);
  };

  const handleDeleteFile = () => {
    let id_elev = props.id;
    let file_id = fileId;
    const formData: FormData = new FormData();
    let data = {
      id_elev: id_elev,
      file_id: file_id,
    };
    formData.append('data', JSON.stringify(data));
    BaseRequest('deleteDocument', formData).then((res) => {
      setFiles(res.data);
      setShowModal(false);
    });
  };

  return (
    <Grid spacing={2} justifyContent="center" container xs={12}>
      <Grid spacing={2} className={classes.wrapperGrid} item xs={9}>
        <Card variant="outlined">
          <CardContent>
            <Grid spacing={2} direction="column" container xs={12}>
              <Grid direction="column" spacing={8} justifyContent="center" item xs={12}>
                <Grid className={classes.dropZone} item xs={12}>
                  <DropzoneArea
                    dropzoneText="להעלות קבצים"
                    filesLimit={10}
                    showAlerts={['error']}
                    alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                    onChange={handleChangeFile}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      var file = [...selectedFiles];
                      var file = selectedFiles;
                      const json = {
                        id_elev: props.id,
                      };
                      var formData = new FormData();
                      formData.append('data', JSON.stringify(json));
                      for (var i = 0; i < file.length; i++) {
                        formData.append(`files[]`, file[i]);
                      }

                      const headersOptions = {
                        'Content-Type': 'multipart/form-data',
                      };
                      BaseRequest('uploadFile', formData, headersOptions).then((res) => {
                        setFiles(res.data);
                        setClasseAlert(classes.displayAlert);
                        setTimeout(function () {
                          setClasseAlert(classes.notDisplayAlert);
                        }, 3000);
                      });
                    }}
                  >
                    אישור
                  </Button>
                  <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" className={classeAlert}>
                    קובץ שמור
                  </Alert>
                </Grid>
                <Grid
                  className={classes.singleFile}
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={12}
                  container
                  direction="column"
                  spacing={2}
                >
                  {files.map((f, idx) => {
                    return (
                      <div style={{ display: 'inline-block', margin: '8px', direction: 'rtl' }}>
                        <ClearIcon style={{ cursor: 'pointer' }} onClick={() => handleClickDelete(f.id)} />
                        <a href={'https://backend.shoshanim.net/document/' + f.nom_doc} target="_blank">
                          {f.nom_doc}
                          <DescriptionIcon key={idx} style={{ fontSize: '45px' }} />
                        </a>
                      </div>
                    );
                  })}
                </Grid>
                <Dialog
                  onClose={() => {
                    setShowModal(false);
                  }}
                  open={showModal}
                >
                  <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center' }}>
                    {'? האם אתה בטוח שברצונך למחוק את הקובץ הזה'}
                  </DialogTitle>
                  <DialogContent style={{ width: 500 }}>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <Button variant="contained" color="primary" onClick={handleDeleteFile}>
                        <DeleteIcon />
                        כן
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setShowModal(false)}
                        autoFocus
                        style={{ backgroundColor: 'rgb(220, 0, 78)' }}
                      >
                        לא
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
