import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Dialog, DialogContent, Grid, Card } from '@material-ui/core';
import { Ring } from 'react-spinners-css';
import BaseRequest from '../helpers/BaseRequest';
import EnhancedTable, { HeadCell } from './Table';
import { useNavigate } from 'react-router-dom';
import AddTeacher from './AddTeacher';
import { AddEmployee } from './add-employee';
import { Roles, getRole } from './utils/roles';

interface TeacherData {
  activite: string;
  koupa: string;
  nom: string;
  prenom: string;
  maavid: string;
  item: any;
}
const useStyles = makeStyles((theme) => ({
  root: {
    float: 'left',
    margin: '2%',
  },
  content: {
    width: 500,
    height: 600,
  },
  employeeDialog: {
    width: 500,
    height: 450,
  },
  button: { marginLeft: 8 },
  card: {
    margin: '1% 15%',
    padding: '0 2% 2% 2%',
    direction: 'rtl',
  },
}));
export default function TeacherList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState<TeacherData[]>([]);
  const [showAddTeacher, setShowAddTeacher] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const userRole = getRole();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = () => {
    BaseRequest('getIntervenant')
      .then((res: any) => {
        let rows: TeacherData[] = [];
        res.data.forEach((item: any) => {
          rows.push(createData(item.activite, item.koupa, item.nom, item.prenom, item.maavid, item));
        });
        // setDisplayRows(rows);
        setRows(rows);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const createData = (
    activite: string,
    koupa: string,
    nom: string,
    prenom: string,
    maavid: string,
    item: any,
  ): TeacherData => {
    return {
      activite,
      koupa,
      nom,
      prenom,
      maavid,
      item,
    };
  };
  const clickIcon = (item: any) => {
    // history.push({
    //   pathname: 'ViewTeacher/' + item.id_interv,
    //   state: { teacher: item },
    // });
    navigate(`/ViewTeacher/${item.id_interv}`, { state: { teacher: item } });
  };
  const cells: HeadCell[] = [
    { id: 'prenom', label: 'שם פרטי', isSortable: true },
    { id: 'nom', label: 'שם משפחה', isSortable: true },
    { id: 'activite', label: 'תחום', isSortable: true },
    { id: 'koupa', label: 'קופת חולים', isSortable: true },
    { id: 'maavid', label: 'מעביד', isSortable: true },
    {
      id: 'edit',
      label: 'עריכה',
      type: 'icon',
      handleClickIcon: clickIcon,
      isSortable: false,
    },
  ];

  return (
    <>
      <Card className={classes.card}>
        <Grid item spacing={2} direction="column" container>
          <Grid item xs={12}>
            <h1 className="primary">רשימת מטפלים</h1>
          </Grid>
          {userRole === Roles.Menahel && (
            <Grid container item direction="row" xs={12} justifyContent="flex-end">
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => setShowAddEmployee(true)}
              >
                הוספת משתמש
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => setShowAddTeacher(true)}
              >
                הוספת מטפל
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading ? <Ring color="#14738c" /> : <EnhancedTable headCells={cells} rows={rows} numberRows={5} />}
          </Grid>
        </Grid>
      </Card>
      <Dialog onClose={() => setShowAddTeacher(false)} open={showAddTeacher}>
        <DialogContent className={classes.content}>
          <AddTeacher
            ok={() => {
              setShowAddTeacher(false);
              getTeachers();
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => setShowAddEmployee(false)} open={showAddEmployee}>
        <DialogContent className={classes.employeeDialog}>
          <AddEmployee
            ok={() => {
              setShowAddEmployee(false);
              // getTeachers();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
