import { createContext, Context } from 'react';
import type { Branche } from './types';

export type BranchesContextType = {
  branchesList?: Branche[];
  selectedBranche?: Branche;
  setCurrentBranche?: (brancheId: string) => void;
};

export const BranchesContext: Context<BranchesContextType> = createContext<BranchesContextType>({
  branchesList: undefined,
  selectedBranche: undefined,
  setCurrentBranche: undefined,
});
