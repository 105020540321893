import React from 'react';
import { BaseForm } from '../base-form';
import { categoryOne, categoryTwo, categoryThree, categoryFour, categoryFive } from './questions';

export const formId = 'form-1';
export const formTitle = 'שאלון לבדיקת תפקוד קוגניטיבי';
const formDescription =
  'השאלון יהווה בסיס לקבלת החלטות לגבי הצורך בהתערבות תפקודית קוגניטיבית לילדים המתקשים. חשוב להתייחס אליו באופן מדויק עד כמה שאפשר.';

const categories = [categoryOne, categoryTwo, categoryThree, categoryFour, categoryFive];

export function QuestionsCognitiveFunction() {
  return <BaseForm formTitle={formTitle} formDescription={formDescription} categoriesOfQuestions={categories} />;
}
