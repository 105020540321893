import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
  Button,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';
import BaseRequest from '../helpers/BaseRequest';
import AttachFiles from './AttachFiles';
import { StudentData } from './StudentsList';
import Comments from './Comments';
import NextTreatments from './NextTreatments';
import InfosFinacieres from './InfosFinancieres';
import ChildDetails from './ChildDetails';
import { getUser } from './utils/user';
import { Roles } from './utils/roles';
import { PrintChildDetails } from './print-child-details';
import { ConfirmationModal } from './confirmation-modal';
import { ChildBrancheSection } from '../branches/child-branche-section';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  card: {
    margin: '4% 15%',
  },
  typography: {
    marginTop: 15,
    marginBottom: 15,
  },
  alignDroite: {
    textAlign: 'right!important' as any,
  },
  alignCentre: {
    textAlign: 'center',
  },
  content: {},
  tabs: {
    overflow: 'scroll',
  },
}));

interface Child {
  firstname: string;
  lastName: string;
  age: number;
}
interface ViewChildProps {
  child: StudentData;
  ok?: Function;
}

export default function ViewChild(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  // @ts-ignore
  const params = location.state.child;
  const { id } = useParams();
  const childId = id as string;
  const navigate = useNavigate();
  const user = getUser();
  const [showPrintModal, setShowPrintModal] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const scroll = () => {
    document.getElementById('card')?.scrollTo(0, 0);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const deleteChild = () => {
    const id_elev = childId;
    const obj = {
      id_elev: id_elev,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    BaseRequest('deleteStudent', formData).then((res: any) => {
      if (res.success == 1) {
        // history.push({ pathname: '/students' });
        navigate('/students');
      }
    });
  };

  return (
    <Card className={`childDetailsCard ${classes.card}`}>
      <CardContent id="card">
        <Grid spacing={2} container>
          <Grid
            item
            xs={localStorage.getItem('role') == '1' ? 8 : 12}
            className={localStorage.getItem('role') == '1' ? classes.alignDroite : classes.alignCentre}
          >
            <Typography className={classes.typography} color="primary" variant="h5">
              {`${params.lastName} ${params.firstName}`}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {(user.role === Roles.Menahel || user.role === Roles.Secretaire) && (
              <Button
                variant="contained"
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ backgroundColor: 'rgb(220, 0, 78)', color: '#fff' }}
              >
                למחוק ילד
              </Button>
            )}
            <Button
              variant="contained"
              color="default"
              style={{ marginLeft: 16 }}
              startIcon={<PrintIcon style={{ marginRight: 8 }} />}
              onClick={() => setShowPrintModal(true)}
            >
              הדפסה
            </Button>
          </Grid>
          <Grid item xs={12}>
            {user.role !== Roles.Intervenant ? (
              <Tabs
                className="rtl subTabsChild"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                classes={{ scroller: classes.tabs }}
              >
                <Tab label="נתונים אישיים" {...a11yProps(0)} />
                <Tab label="טפסים" {...a11yProps(1)} />
                <Tab label="נתונים פיננסיים" {...a11yProps(2)} />
                <Tab label="סיכומי טיפול" {...a11yProps(3)} />
                <Tab label="טיפולים עתידיים" {...a11yProps(4)} />
                <Tab label="הגדרות" {...a11yProps(5)} />
              </Tabs>
            ) : (
              <Tabs
                className="rtl subTabsChild"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                classes={{ scroller: classes.tabs }}
              >
                <Tab label="נתונים אישיים" {...a11yProps(0)} />
                <Tab label="טפסים" {...a11yProps(1)} />
                <Tab label="סיכומי טיפול" {...a11yProps(2)} />
                <Tab label="טיפולים עתידיים" {...a11yProps(3)} />
              </Tabs>
            )}

            {localStorage.getItem('role') != '2' ? (
              <>
                <TabPanel value={value} index={0}>
                  <ChildDetails scroll={scroll} isEdit={true} id={childId} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AttachFiles id={childId} name={`${params.lastName} ${params.firstName}`} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <InfosFinacieres id={childId} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Comments id={childId} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <NextTreatments id={childId} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <ChildBrancheSection childId={childId} />
                </TabPanel>
              </>
            ) : (
              <>
                <TabPanel value={value} index={0}>
                  <ChildDetails scroll={scroll} isEdit={true} id={childId} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AttachFiles id={childId} name={`${params.lastName} ${params.firstName}`} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Comments id={childId} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <NextTreatments id={childId} />
                </TabPanel>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <ConfirmationModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={deleteChild}
        dangerButton
        title="מחיקת ילד"
        text={
          <span>
            ? האם אתה בטוח שברצונך למחוק את הילד <strong>{`${params.lastName} ${params.firstName}`}</strong>
          </span>
        }
      />
      <PrintChildDetails childId={childId} show={showPrintModal} onClose={() => setShowPrintModal(false)} />
    </Card>
  );
}
