export enum Roles {
  Menahel = '1',
  Intervenant = '2',
  Secretaire = '3',
  MenahelBliKsafim = '4',
}

export const getRole = () => {
  const role = localStorage.getItem('role');
  switch (role) {
    case '1':
      return Roles.Menahel;
    case '2':
      return Roles.Intervenant;
    case '3':
      return Roles.Secretaire;
    case '4':
      return Roles.MenahelBliKsafim;
    default:
      break;
  }
};
