import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  makeStyles,
  Select,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Input,
} from '@material-ui/core';
import BaseRequest from '../helpers/BaseRequest';
import { AutoCompleteList } from './AddCourse';
import { CustomAutoComplete } from './custom-autocomplete';
import EnhancedTable, { HeadCell } from './Table';
import { useNavigate } from 'react-router-dom';
import { Ring } from 'react-spinners-css';
import { useBranches } from '../branches/use-branches';
import type { Branche } from '../branches/use-branches';

export interface StudentData {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  TZ: string;
  date_inscription: string;
  brancheName: string | null;
  item: any;
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  card: {
    margin: '1% 15%',
    padding: '0 2% 2% 2%',
    direction: 'rtl',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function StudentList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState<StudentData[]>([]);
  const [displayRows, setDisplayRows] = useState<StudentData[]>([]);
  const [child, setChild] = useState<AutoCompleteList>();
  const [students, setStudents] = useState<AutoCompleteList[]>([]);
  const [teoudatZeout, setTeoudatZeout] = useState<AutoCompleteList[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { branchesList, selectedBranche } = useBranches();
  const brancheOptions = branchesList?.concat([{ id: (branchesList.length + 1).toString(), name: 'לא הוגדר סניף' }]);
  const [selectedBranches, setSelectedBranches] = useState<Branche[]>([]);

  useEffect(() => {
    if (selectedBranche) setSelectedBranches([selectedBranche]);
  }, [selectedBranche]);

  useEffect(() => {
    getStudents();
  }, []);

  const handleChangeSelectedBranches = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    const newSelectedBranches = brancheOptions?.filter((branche) => values.includes(branche.name));
    setSelectedBranches(newSelectedBranches || []);
  };

  const getStudents = () => {
    const formData: FormData = new FormData();

    if (localStorage.getItem('role') == '2') {
      const obj = {
        id_interv: localStorage.getItem('id'),
      };

      formData.append('metapel', JSON.stringify(obj));
    }

    BaseRequest('getStudent', formData)
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        let t: AutoCompleteList[] = [];
        let r: StudentData[] = [];
        res.data.forEach((item) => {
          let birthDate_format = new Date(item.birthDate);
          let birthDate =
            appendLeadingZeroes(birthDate_format.getDate()) +
            '-' +
            appendLeadingZeroes(birthDate_format.getMonth() + 1) +
            '-' +
            birthDate_format.getFullYear();
          let date_inscription_format = new Date(item.date_inscription);
          let date_inscription =
            appendLeadingZeroes(date_inscription_format.getDate()) +
            '-' +
            appendLeadingZeroes(date_inscription_format.getMonth() + 1) +
            '-' +
            date_inscription_format.getFullYear();
          i.push({ id: item.id, value: `${item.firstName} ${item.lastName}` });
          t.push({ id: item.id, value: `${item.tz}` });
          // r.push(createData(item.firstName, item.lastName, item.birthDate, item));
          r.push(
            createData(
              item.avatar_url,
              item.firstName,
              item.lastName,
              birthDate,
              item.tz,
              date_inscription,
              item.branche_name,
              item,
            ),
          );
        });
        setStudents(i);
        setTeoudatZeout(t);
        setRows(r);
        setDisplayRows(r);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const students = rows.filter((r) => {
      return child ? r.item.id === child.id : true;
    });
    setDisplayRows(students);
  }, [child]);

  useEffect(() => {
    if (selectedBranches.length === 0) {
      setDisplayRows(rows);
    } else {
      const students = rows.filter((s) => {
        return selectedBranches.findIndex((b) => b.name === s.brancheName) > -1;
      });
      setDisplayRows(students);
    }
  }, [selectedBranches, rows]);

  const createData = (
    avatarUrl: string,
    firstName: string,
    lastName: string,
    birthDate: string,
    TZ: string,
    date_inscription: string,
    brancheName: string | null,
    item: any,
  ): StudentData => {
    return {
      avatarUrl,
      firstName,
      lastName,
      birthDate,
      TZ,
      date_inscription,
      brancheName: brancheName !== null ? brancheName : 'לא הוגדר סניף',
      item,
    };
  };

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  const clickIcon = (item: any) => {
    setChild(item);

    // history.push({
    //   pathname: '/viewChild/' + item.id,
    //   state: { child: item },
    // });
    navigate(`/viewChild/${item.id}`, { state: { child: item } });
  };

  const cells: HeadCell[] = [
    { id: 'avatarUrl', label: 'תמונת פרופיל', type: 'studentAvatar', isSortable: false },
    { id: 'firstName', label: 'שם פרטי', isSortable: true },
    { id: 'lastName', label: 'שם משפחה', isSortable: true },
    { id: 'birthDate', label: 'תאריך לידה', isSortable: true },
    { id: 'TZ', label: 'תעודת זהות', isSortable: true },
    { id: 'date_inscription', label: 'תאריך הרשמה', isSortable: true },
    { id: 'brancheName', label: <div style={{ width: 100 }}>סניף</div>, isSortable: false },
    {
      id: 'comments',
      label: 'הערות',
      type: 'studentCommentsIcon',
      isSortable: false,
    },
    {
      id: 'edit',
      label: 'עריכה',
      type: 'icon',
      handleClickIcon: clickIcon,
      isSortable: false,
    },
  ];
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid spacing={2} direction="column" container style={{ direction: 'rtl' }}>
          <Grid item xs={12}>
            <h1 className="primary">רשימת ילדים</h1>
          </Grid>
          <Grid direction="row" item xs={12} justifyContent="flex-start" container>
            <Grid item xs={3} style={{ marginLeft: '10px' }}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <CustomAutoComplete label="שם הילד" data={students} setValue={setChild} isLabelShrink={true} />
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginLeft: '10px' }}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <CustomAutoComplete label="תעודת זהות" data={teoudatZeout} setValue={setChild} isLabelShrink={true} />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <InputLabel>סניף</InputLabel>
                <Select
                  multiple
                  value={selectedBranches.map((b) => b.name)}
                  onChange={handleChangeSelectedBranches}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {brancheOptions?.map((branche) => (
                    <MenuItem key={branche.id} value={branche.name}>
                      <Checkbox color="primary" checked={selectedBranches.findIndex((b) => b.id === branche.id) > -1} />
                      <ListItemText primary={branche.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Ring color="#14738c" />
            ) : (
              <EnhancedTable headCells={cells} rows={displayRows} numberRows={20} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
