import { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { ColorPicker } from '@teambit/design.ui.input.color-picker';
import { ConfirmationModal } from '../confirmation-modal';
import BaseRequest from '../../helpers/BaseRequest';
import { Button } from '../../components/buttons/button';
import { getUser } from '../utils/user';
import styles from './members.module.scss';

// id_user, identifiant, name, role, id_interv, register_date, color
interface RowData {
  // id_user
  id: string;
  identifiant: string;
  name: string;
  role: string;
  id_interv?: string;
  register_date?: string;
  color?: string;
}

const getParsedRole = (role_number: string) => {
  switch (role_number) {
    case '1':
      return 'מנהל';
    case '2':
      return 'מטפל';
    case '3':
      return 'מזכירה';
    case '4':
      return 'מנהל בלי כספים';
    default:
      return '';
  }
};

const createRowData = (item: any): RowData => {
  return {
    id: item.id_user,
    identifiant: item.identifiant,
    name: item.name,
    role: getParsedRole(item.role),
    id_interv: item.id_interv,
    register_date: item.register_date,
    color: item.color,
  };
};

export function Members() {
  const [rows, setRows] = useState<RowData[]>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RowData | undefined>(undefined);
  const [userColor, setUserColor] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const user = getUser();

  const getAllUsers = async () => {
    const formData: FormData = new FormData();
    formData.append('token', user.token);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('getAllUsers', formData, headersOptions)
      .then((result) => {
        if (result.success === 1) {
          const rowData: RowData[] = result.data.map((value: any) => createRowData(value));
          setRows(rowData);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleEditUser = async () => {
    if (!selectedRow) return;
    const formData: FormData = new FormData();
    formData.append('token', user.token);
    formData.append('color', userColor);
    formData.append('id_user', selectedRow.id);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    setLoading(true);
    await BaseRequest('setUserColor', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          await getAllUsers();
          if (user.user_id === selectedRow.id) localStorage.setItem('user_color', userColor);
          setSelectedRow(undefined);
          setUserColor('');
          setOpenEditModal(false);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'שם', flex: 1 },
    { field: 'role', headerName: 'הרשאה', flex: 1 },
    { field: 'identifiant', headerName: 'מייל', flex: 1 },
    { field: 'register_date', headerName: 'תאריך הרשמה', flex: 1 },
    {
      field: 'color',
      headerName: 'צבע במערכת',
      flex: 1,
      renderCell(params) {
        const row = params.row as RowData;
        if (row.color) return <div className={styles.circleColor} style={{ backgroundColor: row.color }} />;
        return null;
      },
    },
    {
      field: 'editButton',
      headerName: 'עריכה',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            onClick={() => {
              const row = params.row as RowData;
              setSelectedRow(row);
              setUserColor(row.color || '');
              setOpenEditModal(true);
            }}
          >
            <EditIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Grid direction="column" container justifyContent="flex-start">
      <Grid item xs={12}>
        <div className={styles.dataGridHolder}>
          <DataGrid
            style={{ direction: 'rtl' }}
            rows={rows}
            columns={columns}
            pageSize={30}
            localeText={{
              columnMenuHideColumn: 'הסתר עמודה',
              columnMenuShowColumns: 'הצג עמודות',
              columnMenuFilter: 'פילטר',
              MuiTablePagination: {
                labelDisplayedRows(paginationInfo) {
                  return `${paginationInfo.from}-${paginationInfo.to} מתוך ${paginationInfo.count}`;
                },
              },
              footerRowSelected(count) {
                return `${count} שורות שנבחרו`;
              },
            }}
          />
        </div>
      </Grid>
      <ConfirmationModal
        fullWidth
        classes={{
          paper: styles.editModal,
        }}
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          setSelectedRow(undefined);
        }}
        onSubmit={handleEditUser}
        title="עידכון משתמש"
        submitText="עדכן"
        disabledSubmit={selectedRow?.color === userColor || !userColor}
        cancelText="ביטול"
        text={
          selectedRow && (
            <FormControl dir="rtl" style={{ display: 'flex', gap: 16, textAlign: 'right' }} variant="outlined">
              <TextField disabled label="שם משתמש\מייל" defaultValue={selectedRow.identifiant} />
              <TextField disabled label="שם מלא" defaultValue={selectedRow.name} />
              <div className={styles.colorPicker}>
                <ColorPicker
                  value={userColor}
                  onColorSelect={(value) => setUserColor(value)}
                  size="l"
                  position="bottom-end"
                >
                  צבע משתמש
                </ColorPicker>
              </div>
            </FormControl>
          )
        }
        loading={loading}
      />
    </Grid>
  );
}
