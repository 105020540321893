import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  LocalHospital as LocalHospitalIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import MedicalPriceForm from '../../MedicalPriceForm';
import { getUser } from '../../utils/user';
import { Roles } from '../../utils/roles';
import { default as LogoImage } from '../../../assets/shoshanim_transparent.png';
import { useBranches } from '../../../branches/use-branches';
import type { HeaderData, HeaderPlugin } from './types';
import styles from './header.module.css';

export function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showPriceMedicalForm, setShowPriceMedicalForm] = useState(false);
  const user = getUser();
  const { userName, role } = user;
  const navigate = useNavigate();
  const location = useLocation();

  // @todo why after clicking on the logo, I can't go back?
  const logo = (
    <RouterLink to="/">
      <img className={styles.logo} src={LogoImage} alt="logo" />
    </RouterLink>
  );

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const headersData: HeaderData[] = [
    {
      label: 'ילדים',
      href: '/students',
    },
    {
      label: 'מטפלים',
      href: '/teachers',
      display: () => role === Roles.Menahel || role === Roles.Secretaire || role === Roles.MenahelBliKsafim,
    },
    {
      label: 'טיפולים',
      href: '/course',
    },
    {
      label: 'כספים',
      href: '/finance',
      display: () => role === Roles.Menahel || role === Roles.Secretaire,
    },
    {
      label: 'זמינות המטפלים',
      href: '/zminout',
      display: () => role === Roles.Menahel || role === Roles.Secretaire || role === Roles.MenahelBliKsafim,
    },
    {
      label: 'שאלונים',
      href: '/forms',
      display: () => role === Roles.Menahel || role === Roles.Secretaire || role === Roles.MenahelBliKsafim,
    },
  ];

  const leftPlugins: HeaderPlugin[] = [
    {
      element: () => {
        return (
          <Tooltip title="הגדרות">
            <Button
              color="primary"
              component={RouterLink}
              to="/settings/branches"
              className={classNames(styles.menuLink, location.pathname === '/settings/branches' && styles.active)}
            >
              <SettingsIcon />
            </Button>
          </Tooltip>
        );
      },
      display: () => role === Roles.Menahel,
    },
    {
      element: () => {
        return (
          <Tooltip title="עדכון מימון">
            <Button color="primary" className={styles.menuLink} onClick={() => setShowPriceMedicalForm(true)}>
              <LocalHospitalIcon />
            </Button>
          </Tooltip>
        );
      },
      display: () => role === Roles.Menahel,
    },
    {
      element: () => {
        return (
          <Tooltip title="התנתק">
            <Button color="primary" className={styles.menuLink} onClick={() => handleLogout()}>
              <LogoutIcon />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const rightPlugins: HeaderPlugin[] = [
    {
      element: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { branchesList, selectedBranche, setCurrentBranche } = useBranches();
        return (
          <FormControl variant="standard" className={styles.brancheFormControl}>
            <InputLabel id="branche-selector">סניף</InputLabel>
            <Select
              labelId="branche-selector"
              value={selectedBranche?.id || undefined}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCurrentBranche?.(event.target.value as string);
                window.location.reload();
              }}
              label="סניף"
            >
              {branchesList?.map((branche, index) => (
                <MenuItem key={`header-select-${index}-${branche.id}`} value={branche.id} style={{ direction: 'rtl' }}>
                  {branche.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      // display: () => false,
      // display: () => role === Roles.Menahel,
    },
    {
      element: () => {
        return (
          <Button color="primary" className={styles.menuLink} disableTouchRipple>
            <AccountCircleIcon style={{ marginLeft: 8, color: user.color }} />
            שלום {userName}
          </Button>
        );
      },
    },
  ];

  const getMenuLinks = () => {
    return headersData
      .filter(({ display }) => (display ? display() : true))
      .map(({ label, href }) => {
        return (
          <Button
            key={label}
            color="primary"
            component={RouterLink}
            to={href}
            className={classNames(styles.menuLink, location.pathname === href && styles.active)}
          >
            {label}
          </Button>
        );
      });
  };

  const getPlugins = (plugins: HeaderPlugin[]) => {
    return plugins
      .filter(({ display }) => (display ? display() : true))
      .map(({ element: Element }, index) => <Element key={index} />);
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={classNames(styles.toolbar, styles.desktop)}>
        {logo}
        <div className={styles.menuLinksHolder}>
          {getPlugins(rightPlugins)}
          {getMenuLinks()}
          {getPlugins(leftPlugins)}
        </div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    const homeLink: HeaderData = {
      label: 'ראשי',
      href: '/',
    };
    const headersDataWithHomeLink: HeaderData[] = [homeLink, ...headersData];
    return headersDataWithHomeLink
      .filter(({ display }) => (display ? display() : true))
      .map(({ label, href }) => {
        return (
          <Link component={RouterLink} to={href} color="inherit" style={{ textDecoration: 'none' }} key={label}>
            <MenuItem>{label}</MenuItem>
          </Link>
        );
      });
  };

  const handleDrawerOpen = () => setDrawerOpen(!drawerOpen);

  const displayMobile = () => {
    return (
      <Toolbar className={classNames(styles.mobile)}>
        <IconButton edge="start" color="inherit" aria-label="menu" aria-haspopup="true" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <div>{logo}</div>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerOpen}
          classes={{ paperAnchorRight: styles.drawer }}
        >
          <div className={styles.drawerContainer}>
            {getPlugins(rightPlugins)}
            {getDrawerChoices()}
            {getPlugins(leftPlugins)}
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  if (!userName) return null;
  return (
    <AppBar dir="rtl" position="relative" color="default" className={styles.header}>
      {displayDesktop()}
      {displayMobile()}
      <Dialog onClose={() => setShowPriceMedicalForm(false)} open={showPriceMedicalForm}>
        <DialogContent className={styles.dialogContent}>
          <MedicalPriceForm ok={() => setShowPriceMedicalForm(false)} />
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}
