import React, { useEffect, useState, ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Avatar,
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/Comment';
import { StudentComments } from './student-comments';

function descendingComparator<T>(a: any, b: any, orderBy: any) {
  if (orderBy === 'date' || orderBy === 'birthDate' || orderBy === 'date_inscription' || orderBy === 'date_cours') {
    var change_date_format = function (date: string) {
      var date_format = date.split('-');
      return date_format[1] + '-' + date_format[0] + '-' + date_format[2];
    };
    if (b[orderBy] && a[orderBy]) {
      if (new Date(change_date_format(b[orderBy])).getTime() < new Date(change_date_format(a[orderBy])).getTime()) {
        return -1;
      }
      if (new Date(change_date_format(b[orderBy])).getTime() > new Date(change_date_format(a[orderBy])).getTime()) {
        return 1;
      }
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el: T, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell {
  id: string;
  label?: ReactNode;
  type?: string;
  handleClickIcon?: any;
  isSortable?: boolean;
  isDate?: boolean;
  icon?: any;
  icon_if_comment?: any;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy?: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: HeadCell) => (event: React.MouseEvent<unknown>) => {
    if (property.isSortable) onRequestSort(event, property.id);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.header}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                className={classes.sortHeader}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id && headCell.isSortable ? order : 'asc'}
                onClick={createSortHandler(headCell)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      direction: 'ltr',
    },
    header: {
      fontWeight: 'bold',
    },
    sortHeader: {
      paddingLeft: '0',
    },
    table: {
      minWidth: 750,
    },
    tableContainer: {
      direction: 'ltr',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

type orderBy = 'date' | 'birthDate' | 'date_inscription' | 'date_cours';
export interface TableProps {
  headCells: HeadCell[];
  rows: any[];
  numberRows: number;
  orderBy?: orderBy | string;
}

export default function EnhancedTable(props: TableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<orderBy | string>(props.orderBy || 'date_inscription');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(props.numberRows);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [modalAvatarUrl, setModalAvatarUrl] = useState('');
  const [openStudentCommentsModal, setOpenStudentCommentsModal] = useState(false);
  const [studentItem, setStudentItem] = useState<any>({});

  useEffect(() => {
    setPage(0);
  }, [props.rows]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenAvatarModal = (url: string) => {
    setModalAvatarUrl(url);
    setOpenAvatarModal(true);
  };

  const handleCloseAvatarModal = () => {
    setModalAvatarUrl('');
    setOpenAvatarModal(false);
  };

  const handleStudentCommentsModal = () => {
    setOpenStudentCommentsModal(!openStudentCommentsModal);
  };

  const renderComponent = (row: any, item: HeadCell) => {
    if (!item.type) return row[item.id];
    switch (item.type) {
      case 'icon':
        return (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => item.handleClickIcon(row.item)}
          >
            {item.icon && row.item['commentaire_exist'] !== undefined && row.item['commentaire_exist'] === true && (
              <>
                {item.icon} {item.icon_if_comment}
              </>
            )}
            {item.icon && row.item['commentaire_exist'] !== undefined && row.item['commentaire_exist'] === false && (
              <>{item.icon}</>
            )}
            {!item.icon && <EditIcon />}
          </IconButton>
        );
      case 'studentCommentsIcon':
        return (
          <IconButton
            color="primary"
            component="span"
            onClick={() => {
              // handleStudentCommentOpen(row.item.id);
              handleStudentCommentsModal();
              setStudentItem(row.item);
            }}
          >
            <CommentIcon />
          </IconButton>
        );
      case 'studentAvatar':
        return (
          <Avatar
            onClick={() => handleOpenAvatarModal(row.avatarUrl)}
            style={{ width: 56, height: 56, cursor: 'pointer' }}
            alt={`${row.firstName} ${row.lastName}`}
            src={row.avatarUrl}
          />
        );
      default:
        return row[item.id];
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);
  const cells = props.headCells;
  return (
    <Paper className={classes.paper}>
      <Modal
        open={openAvatarModal}
        onClose={handleCloseAvatarModal}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Avatar style={{ width: 96, height: 96 }} src={modalAvatarUrl} />
      </Modal>
      <Modal
        open={openStudentCommentsModal}
        onClose={handleStudentCommentsModal}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card style={{ width: 1200, height: '90vh', display: 'flex', flexDirection: 'column' }}>
          <CardContent style={{ height: 'calc(100% - 47px)' }}>
            <StudentComments key={`student-comment-${studentItem.id}`} student={studentItem} />
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small" onClick={handleStudentCommentsModal}>
              סגור
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={cells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
          />
          <TableBody>
            {stableSort(props.rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let backgroundColor_ancien_student = '';
                if (row.item !== undefined) {
                  if (row.item['ancien'] !== undefined) {
                    if (row.item.ancien) {
                      backgroundColor_ancien_student = '#D3D3D3';
                    }
                  }
                }
                return (
                  <TableRow
                    hover
                    key={`table-row-${row.item.id}-${index}`}
                    style={{ backgroundColor: backgroundColor_ancien_student }}
                  >
                    {cells.map((item, idx) => {
                      return <TableCell key={idx}>{renderComponent(row, item)}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} מתוך ${count}`}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div data-end />
    </Paper>
  );
}
