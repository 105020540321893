export interface ChildData {
  type?: string;
  lable?: string;
  id?: string;
  condition?: boolean;
  conditionById?: string[];
  conditionByIdValue?: boolean;
  conditionByIdValueRadio?: string[];
  kind?: string;
  childrenLables?: ChildData[];
  display?: boolean;
  value?: any;
  valueFile?: any;
  isError?: boolean;
  isRequired?: boolean;
  textError?: string;
  options?: any;
  isadmin?: boolean;
}
export interface ServerData {
  key?: any;
  value?: any;
}
export function PersonalData() {
  const arr: ChildData[] = [
    {
      type: 'text',
      id: 'firstName',
      lable: 'שם פרטי',
      kind: 'text',
      condition: false,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'lastName',
      lable: 'שם משפחה',
      kind: 'text',
      condition: false,
      isRequired: true,
    },
    // {
    //   type: "text",
    //   id: "birthDate",
    //   lable: "תאריך לידה",
    //   kind: "date",
    //   value: format(new Date(), "dd-mm-yyyy"),
    //   isRequired:true,
    //   condition: false,
    // },
    {
      type: 'date',
      id: 'birthDate',
      lable: 'תאריך לידה',
      kind: 'date',
      value: null,
      isRequired: true,
      condition: false,
    },
    {
      type: 'text',
      id: 'age',
      lable: 'גיל',
      kind: 'number',
      condition: false,
      value: '',
    },
    {
      type: 'text',
      id: 'grade',
      lable: 'כיתה',
      condition: false,
      kind: 'text',
    },
    {
      type: 'text',
      id: 'tz',
      lable: 'תעודת זהות',
      condition: false,
      kind: 'text',
      isRequired: true,
    },
    {
      type: 'text',
      id: 'address',
      lable: 'כתובת',
      condition: false,
      kind: 'text',
      isRequired: true,
    },
    {
      type: 'text',
      id: 'mPhone',
      lable: 'טלפון האם',
      kind: 'text',
      condition: false,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'fPhone',
      lable: 'טלפון האב',
      kind: 'text',
      condition: false,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'hPhone',
      lable: 'טלפון הבית',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'aPhone',
      lable: 'טלפון אחר',
      condition: false,
      kind: 'text',
    },
    {
      type: 'textArea',
      id: 'gorem',
      lable: 'גורם המפנה',
      condition: false,
    },
    {
      type: 'text',
      id: 'school',
      lable: 'בית ספר',
      condition: false,
      kind: 'text',
      isRequired: true,
    },
    {
      type: 'text',
      id: 'sPhone',
      lable: 'טלפון בית ספר',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'schoolAddress',
      lable: 'כתובת בית ספר',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'principle',
      lable: 'מנהל/ת',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'teacher',
      lable: 'מורה מחנכת',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'doctor',
      lable: 'רופא משפחה',
      condition: false,
      kind: 'text',
    },
    {
      type: 'select',
      id: 'hmo',
      lable: 'קופת חולים',
      condition: false,
      isRequired: true,
      options: ['לאומית', 'כללית', 'מאוחדת', 'מכבי'],
      isError: false,
    },

    // {
    //   id: "entryDate",
    //   lable: "תאריך כניסה לשושנים",
    //   kind: "date",
    //   value: null,
    //   condition: false,
    //   isadmin: true,
    //   isError: false,
    // },

    // {
    //   id: "releaseDate",
    //   lable: "תאריך יציאה משושנים",
    //   kind: "date",
    //   value: null,
    //   condition: false,
    //   isadmin: true,
    //   isError: false,
    // },

    {
      type: 'radio',
      id: 'insurance',
      lable: 'ביטוח משלים',
      condition: false,
      childrenLables: [
        { id: 'insuranceYes', lable: 'כן' },
        { id: 'insuranceNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'insuranceKind',
      lable: 'סוג הביטוח המשלים',
      condition: true,
      conditionById: ['insurance'],
      conditionByIdValueRadio: ['insuranceYes'],
    },
    {
      type: 'radio',
      id: 'hmoRequest',
      lable: 'האם פניתם לקופת חולים ?',
      condition: false,
      childrenLables: [
        { id: 'hmoRequestYes', lable: 'כן' },
        { id: 'hmoRequestNo', lable: 'לא' },
      ],
    },
    // {
    //   type: "check",
    //   id: "hmoRequest",
    //   lable: "האם הוגשה בקשה לקופת חולים בקשר לטיפולים ?",
    //   condition: false,
    //   // childrenLables: [
    //   //   { id: "hmoRequestYes", lable: "כן" },
    //   //   { id: "hmoRequestNo", lable: "לא" },
    //   // ],
    // },
    // {
    //   type: "date",
    //   id: "requestDate",
    //   lable: "באיזה תאריך",
    //   kind: "date",
    //   value: null,
    //   condition: true,
    //   conditionById: ["hmoRequest"],
    //   isError: false,
    //   conditionByIdValueRadio: ['hmoRequestYes']
    // },
    {
      type: 'radio',
      id: 'Hitpatchut',
      lable: 'האם הילד טופל בהתפתחות הילד ?',
      condition: true,
      childrenLables: [
        { id: 'HitpatchutYes', lable: 'כן' },
        { id: 'HitpatchutNo', lable: 'לא' },
      ],
      conditionById: ['hmoRequest'],
      conditionByIdValueRadio: ['hmoRequestYes'],
    },
    {
      type: 'textArea',
      id: 'requestDate',
      lable: 'מתי ?',
      condition: true,
      conditionById: ['Hitpatchut'],
      conditionByIdValueRadio: ['HitpatchutYes'],
    },
    // {
    //   type: "radio",
    //   id: "requestAnswer",
    //   lable: "האם קיבלתם תשובה ?",
    //   condition: true,
    //   childrenLables: [
    //     { id: "requestAnswerYes", lable: "כן"},
    //     { id: "requestAnswerNo", lable: "לא" },
    //   ],
    //   conditionById: ["hmoRequest"],
    //   conditionByIdValueRadio: ['hmoRequestYes']
    // },
  ];
  return arr;
}
