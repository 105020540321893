import { useState } from 'react';
import {
  TextField,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { BaseRequest } from '../../helpers/BaseRequest';
import { Button } from '../../components/buttons/button';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { getUser } from '../../components/utils/user';
import type { Branche } from '../use-branches';
import { useBranches } from '../use-branches';
import styles from './branches-settings.module.css';

export function BranchesSettings() {
  const { branchesList } = useBranches();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [brancheToDelete, setBrancheToDelete] = useState<Branche | null>(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoadingEdit, setLoadingEdit] = useState(false);
  const [brancheToEdit, setBrancheToEdit] = useState<Branche | null>(null);
  const [editBrancheNameInput, setEditBrancheNameInput] = useState('');

  const [isLoadingAdd, setLoadingAdd] = useState(false);
  const [brancheNameInput, setBrancheNameInput] = useState('');
  const user = getUser();

  const handleSubmitDeleteModal = async () => {
    if (!brancheToDelete) return;
    setLoadingDelete(true);
    const formData: FormData = new FormData();
    formData.append('branche_id', brancheToDelete.id);
    formData.append('token', user.token);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('removeBranche', formData, headersOptions)
      .then(async (result) => {
        if (result.success === 1) {
          setBrancheToDelete(null);
          setOpenDeleteModal(false);
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingDelete(false));
  };

  const handleSubmitAddBranche = async () => {
    if (!brancheNameInput) return;
    setLoadingAdd(true);
    const formData: FormData = new FormData();
    formData.append('name', brancheNameInput);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('createBranche', formData, headersOptions)
      .then(async (result) => {
        if (result.data === 'Branche created') {
          setBrancheNameInput('');
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingAdd(false));
  };

  const handleSubmitEditBrancheName = async () => {
    if (!editBrancheNameInput) return;
    setLoadingEdit(true);
    const formData: FormData = new FormData();
    formData.append('name', editBrancheNameInput);
    formData.append('id', brancheToEdit?.id || '');
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('editBrancheName', formData, headersOptions)
      .then(async (result) => {
        if (result.data === 'Branche edited') {
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingAdd(false));
  };

  return (
    <div>
      <List>
        {branchesList?.map((branche, index) => {
          return (
            <div key={`${index}-${branche.id}-${branche.name}`}>
              <ListItem>
                <ListItemText primary={branche.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      setBrancheToEdit(branche);
                      setOpenEditModal(true);
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setBrancheToDelete(branche);
                      setOpenDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
      <div className={styles.addBrancheHolder}>
        <TextField
          label="שם הסניף"
          variant="outlined"
          size="small"
          value={brancheNameInput}
          onChange={(e) => setBrancheNameInput(e.target.value)}
        />
        <Button
          endIcon={<AddIcon />}
          loading={isLoadingAdd}
          onClick={handleSubmitAddBranche}
          disabled={!brancheNameInput}
        >
          הוספה
        </Button>
      </div>

      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onSubmit={handleSubmitDeleteModal}
        dangerButton
        title="מחיקת סניף"
        text={
          <span>
            ? האם אתה בטוח שברצונך למחוק את הסניף <strong>{`${brancheToDelete?.name}`}</strong>
          </span>
        }
        loading={isLoadingDelete}
      />

      <ConfirmationModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        onSubmit={handleSubmitEditBrancheName}
        title={`עריכת שם סניף ${brancheToEdit?.name}`}
        text={
          <TextField
            label="שם חדש לסניף"
            variant="outlined"
            size="small"
            placeholder={brancheToEdit?.name}
            value={editBrancheNameInput}
            onChange={(e) => setEditBrancheNameInput(e.target.value)}
          />
        }
        submitText="עדכן"
        loading={isLoadingEdit}
      />
    </div>
  );
}
