import React, { useState, useEffect } from 'react';
import {
  Grid,
  CardContent,
  Card,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import BaseRequest from '../helpers/BaseRequest';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getRole, Roles } from './utils/roles';
import { getUser } from './utils/user';

const useStyles = makeStyles((theme) => ({
  txt: {
    width: '100%',
    direction: 'ltr',
  },
  container: {
    backgroundColor: '#fafafa',
  },
  card: {
    // margin: "2% 15%"
  },
  typograpy: {
    marginBottom: 15,
    marginTop: 15,
  },
  typograpyGrid: {
    marginTop: 7,
    marginRight: 20,
  },
  width: {
    width: '70%',
  },
  card2: {
    marginTop: '2%',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  buttonPdfHidden: {
    display: 'none',
  },
  buttonPdfVisible: {
    display: 'block',
  },
  spinner_loader_visible: {
    display: 'block',
  },
  spinner_loader_hidden: {
    display: 'none',
  },
}));
export default function EditTeacher(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const arr = ['מכבי', 'כללית', 'לאומית', 'מאוחדת'];
  const payOptions = ['180', '300'];
  const month = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
  ];
  const years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
  const maavidOptions = ['התפתחות הילד', 'שושנים'];
  const activiteOptions = [
    'אף אוזן גרון',
    'מאבחן',
    'מטפלת קוגניטיבית',
    'מיקוד ראיה',
    'מרפה בעיסוק',
    'ספורט טיפולי',
    'פסיכיאטר',
    'פסיכולוג',
    'קלינאית תקשורת',
  ];
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedPayValue, setSelectedPayValue] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  let params = props.id;
  const [updatedTeacher, setUpdatedTeacher] = useState({ ...params });
  const [maavid, setMaavid] = useState(updatedTeacher.maavid);
  const index = arr.indexOf(params.koupa);
  const index2 = payOptions.indexOf(params.tarif_interv);
  const [dateStart, setDateStart] = React.useState<Date | null>(null);
  const [dateEnd, setDateEnd] = React.useState<Date | null>(null);
  const [classButton, setClassButton] = useState<any>(classes.buttonPdfHidden);
  const [classSpinner, setClassSpinner] = useState<any>(classes.spinner_loader_hidden);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [errors, setErrors] = useState({
    email: false,
    tel: false,
    tz: false,
    prenom: false,
    nom: false,
  });
  const user = getUser();

  const [errorsInputPdf, setErrorsInputPdf] = useState({
    dateStart: false,
    dateEnd: false,
  });

  useEffect(() => {
    setSelectedValue(index + 1);
    setSelectedPayValue(index2 + 1);
  }, []);
  const handleChange = (event: any) => {
    setSelectedMonth(event.target.value);
  };

  const handleDateStartChange = (date: Date | null) => {
    if (date === null) setDateStart(null);
    else new Date(date || '').toString() != 'Invalid Date' && setDateStart(date);
  };

  const handleDateEndChange = (date: Date | null) => {
    if (date === null) setDateEnd(null);
    else new Date(date || '').toString() != 'Invalid Date' && setDateEnd(date);
  };

  const handleChangeMaavid = (event: any) => {
    setMaavid(event.target.value);
    const t = { ...updatedTeacher };
    t.maavid = event.target.value;
    setUpdatedTeacher(t);
  };

  const editTeacher = () => {
    let allValid = true;
    let newTeacher = { ...updatedTeacher };
    let keys = Object.keys(errors);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (!newTeacher[k]) {
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    if (allValid) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(updatedTeacher));
      BaseRequest('UpdateInterv', formData).then((res) => navigate('/teachers'));
    } else setErrors(errorsHelper);
  };

  const downloadPDF = () => {
    setClassButton(classes.buttonPdfHidden);
    let allValid = true;
    let options: any;
    const obj = {
      id_metapel: props.id.id_interv,
      dateStart: dateStart,
      dateEnd: dateEnd,
    };
    options = { ...obj };
    let keys = Object.keys(options);
    let errorsHelper: any;
    errorsHelper = { ...errorsInputPdf };
    keys.forEach((k) => {
      if (!options[k]) {
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    if (allValid) {
      setClassSpinner(classes.spinner_loader_visible);
      const formData = new FormData();
      formData.append('data', JSON.stringify(options));
      BaseRequest('generePdfMetapelim', formData).then((res) => {
        setPdfUrl(res);
        setClassButton(classes.buttonPdfVisible);
        setClassSpinner(classes.spinner_loader_hidden);
      });
    } else setErrorsInputPdf(errorsHelper);
  };

  const viewPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <Grid className={classes.container} justifyContent="center" alignItems="center" container xs={12}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid justifyContent="center" direction="column" spacing={3} alignItems="center" container item xs={12}>
              <Grid className={classes.width} item xs={12}>
                <Grid className="maxWidth" container justifyContent="center" alignItems="center">
                  <Grid direction="column" spacing={2} item container xs={12}>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="primary">מידע לגבי המטפל</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        value={updatedTeacher.prenom}
                        label="שם פרטי"
                        error={!updatedTeacher.prenom && errors.prenom}
                        helperText={!updatedTeacher.prenom && errors.prenom ? 'שם פרטי חובה' : ''}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.prenom = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="שם משפחה"
                        value={updatedTeacher.nom}
                        error={!updatedTeacher.nom && errors.nom}
                        helperText={!updatedTeacher.nom && errors.nom ? 'שם משפחה חובה' : ''}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.nom = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="תעודת זהות"
                        value={updatedTeacher.tz}
                        error={!updatedTeacher.tz && errors.tz}
                        helperText={!updatedTeacher.tz && errors.tz ? 'תעודת זהות חובה' : ''}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.tz = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="תחום" value={updatedTeacher.activite} onChange={(e) => { const t = { ...updatedTeacher }; t.activite = e.target.value; setUpdatedTeacher(t); }} variant="outlined" />
                    </Grid> */}
                    <Grid item xs={12}>
                      <FormControl className="maxWidth" color="primary" variant="outlined">
                        <InputLabel id="select-outlined-label-thoum">תחום</InputLabel>
                        <Select
                          labelId="select-outlined-label-thoum"
                          id="select-outlined-thoum"
                          value={updatedTeacher.activite}
                          label="תחום"
                          onChange={(e) => {
                            const t = { ...updatedTeacher };
                            t.activite = e.target.value;
                            setUpdatedTeacher(t);
                          }}
                        >
                          {activiteOptions.map((m, idx) => {
                            return (
                              <MenuItem key={idx} value={m}>
                                {m}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="כתובת"
                        value={updatedTeacher.adresse}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.adresse = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="טלפון"
                        value={updatedTeacher.tel}
                        error={!updatedTeacher.tel && errors.tel}
                        helperText={!updatedTeacher.tel && errors.tel ? 'תאריך חובה' : ''}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.tel = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="2 טלפון"
                        value={updatedTeacher.tel2}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.tel2 = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="אימייל"
                        value={updatedTeacher.email}
                        error={!updatedTeacher.email && errors.email}
                        helperText={!updatedTeacher.email && errors.email ? 'אימייל חובה' : ''}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.email = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className="maxWidth" color="primary" variant="outlined">
                        <InputLabel id="select-outlined-label-maavid">מעביד</InputLabel>
                        <Select
                          labelId="select-outlined-label-maavid"
                          id="select-outlined-maavid"
                          value={maavid}
                          label="מעביד"
                          onChange={handleChangeMaavid}
                        >
                          {maavidOptions.map((m, idx) => {
                            return (
                              <MenuItem key={idx} value={m}>
                                {m}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="פרטי בנק"
                        value={updatedTeacher.iban}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.iban = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.txt}
                        id="outlined-basic"
                        label="מידע נוסף"
                        value={updatedTeacher.infos}
                        onChange={(e) => {
                          const t = { ...updatedTeacher };
                          t.infos = e.target.value;
                          setUpdatedTeacher(t);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid justifyContent="center" container item xs={12} direction="row">
                      {arr.map((a, idx) => {
                        return (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                checked={selectedValue === idx + 1}
                                onChange={(e) => {
                                  setSelectedValue(idx + 1);
                                  const t = { ...updatedTeacher };
                                  t.koupa = arr[idx];
                                  setUpdatedTeacher(t);
                                }}
                                color="primary"
                                value={idx + 1}
                              />
                            }
                            label={arr[idx]}
                          />
                        );
                      })}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid justifyContent="center" container item xs={12} direction="row-reverse">
                        <TextField
                          className={classes.txt}
                          type="number"
                          id="outlined-basic"
                          label="מספר טיפולים שבועי"
                          value={updatedTeacher.number_lessons}
                          onChange={(e) => {
                            const t = { ...updatedTeacher };
                            t.number_lessons = e.target.value;
                            setUpdatedTeacher(t);
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.width} item xs={12}>
                {user.role !== Roles.MenahelBliKsafim && (
                  <Card className={classes.card2} variant="outlined">
                    <CardContent>
                      <Grid item container direction="column" justifyContent="center" xs={12}>
                        <Grid direction="column" spacing={2} item container xs={12}>
                          <Grid item xs={12}>
                            <Typography color="primary">מידע תקציבי</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid justifyContent="center" container item xs={12} direction="row-reverse">
                              <TextField
                                className={classes.txt}
                                type="number"
                                id="outlined-basic"
                                label="תשלום לטיפול"
                                value={updatedTeacher.tarif_interv}
                                onChange={(e) => {
                                  const t = { ...updatedTeacher };
                                  t.tarif_interv = e.target.value;
                                  setUpdatedTeacher(t);
                                }}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faShekelSign} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {/* <FormControl className='maxWidth' color="primary" variant="outlined">
                                <InputLabel id="תשלום לטיפול">תשלום לטיפול</InputLabel>
                                <Select
                                  labelId="תשלום לטיפול"
                                  label="תשלום לטיפול"
                                  value={updatedTeacher.tarif_interv} 
                                  endAdornment={<InputAdornment style={{marginRight: '10px'}} position="start"><FontAwesomeIcon icon={faShekelSign} /></InputAdornment>}
                                  onChange={(e) => { const t = { ...updatedTeacher }; t.tarif_interv = e.target.value; setUpdatedTeacher(t); }}
                                >
                                  {payOptions.map((p:string, idx:number) => { 
                                    return <MenuItem key={idx} value={p}>{p}</MenuItem> 
                                  })}
                                </Select>
                            </FormControl> */}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid justifyContent="center" container item xs={12} direction="row-reverse">
                              <TextField
                                className={classes.txt}
                                type="number"
                                id="outlined-basic"
                                label="תשלום לשעה טכנית"
                                value={updatedTeacher.tarif_bilan}
                                onChange={(e) => {
                                  const t = { ...updatedTeacher };
                                  t.tarif_bilan = e.target.value;
                                  setUpdatedTeacher(t);
                                }}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faShekelSign} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid justifyContent="center" container item xs={12} direction="row-reverse">
                              <TextField
                                className={classes.txt}
                                type="number"
                                id="outlined-basic"
                                label="תשלום לישיבת צוות"
                                value={updatedTeacher.tarif_reunion}
                                onChange={(e) => {
                                  const t = { ...updatedTeacher };
                                  t.tarif_reunion = e.target.value;
                                  setUpdatedTeacher(t);
                                }}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faShekelSign} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid justifyContent="center" container item xs={12} direction="row-reverse">
                              <TextField
                                className={classes.txt}
                                type="number"
                                id="outlined-basic"
                                label="תשלום לביטול"
                                value={updatedTeacher.tarif_bitoul}
                                onChange={(e) => {
                                  const t = { ...updatedTeacher };
                                  t.tarif_bitoul = e.target.value;
                                  setUpdatedTeacher(t);
                                }}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faShekelSign} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}
                <Grid item style={{ marginTop: '24px' }}>
                  <Button variant="contained" color="primary" onClick={editTeacher} style={{ width: '70%' }}>
                    אישור
                  </Button>
                </Grid>
              </Grid>
              {user.role !== Roles.MenahelBliKsafim && (
                <Grid className={classes.width} item xs={12}>
                  <Card className={classes.card2} variant="outlined">
                    <CardContent>
                      <Grid item container direction="column" justifyContent="center" xs={12}>
                        <Grid spacing={2} item container xs={12}>
                          <Grid item xs={12}>
                            <Typography color="primary">סכום שעות להורדה</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid justifyContent="center" spacing={3} container item xs={12} direction="row-reverse">
                              {/* <Grid item xs={3}>
                              <FormControl
                                className='maxWidth'
                                color="primary" variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">חודש</InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={selectedMonth}
                                  label="חודש"
                                  onChange={handleChange}
                                >
                                  {month.map((m, idx) => { return <MenuItem value={idx}>{month[idx]}</MenuItem> })}
                                </Select>
                              </FormControl>
                            </Grid> */}
                              {/* <Grid item xs={3}>
                              <FormControl
                                className='maxWidth'
                                color="primary" variant="outlined">
                                <InputLabel>שנה</InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onChange={handleYearChange}
                                  label="שנה"
                                >
                                  {years.map((y, idx) => { return <MenuItem value={idx}>{years[idx]}</MenuItem> })}
                                </Select>
                              </FormControl>
                            </Grid> */}
                              <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    PopoverProps={{ dir: 'rtl' }}
                                    color="primary"
                                    inputVariant="outlined"
                                    id="date-picker-dialog-outlined"
                                    format="dd/MM/yyyy"
                                    value={dateStart}
                                    onChange={handleDateStartChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label=" תאריך התחלה"
                                    variant="inline"
                                    // className={classes.formControl}
                                    error={!dateStart && errorsInputPdf.dateStart}
                                    helperText={
                                      !dateStart && errorsInputPdf.dateStart ? 'תאריך התחלה חובה או תאריך שגוי' : ''
                                    }
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    PopoverProps={{ dir: 'rtl' }}
                                    color="primary"
                                    inputVariant="outlined"
                                    id="date-picker-dialog-outlined"
                                    format="dd/MM/yyyy"
                                    value={dateEnd}
                                    onChange={handleDateEndChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label=" תאריך סיום"
                                    variant="inline"
                                    // className={classes.formControl}
                                    error={!dateEnd && errorsInputPdf.dateEnd}
                                    helperText={
                                      !dateEnd && errorsInputPdf.dateEnd ? 'תאריך סיום חובה או תאריך שגוי' : ''
                                    }
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: '24px' }}>
                          <Button variant="contained" color="primary" onClick={downloadPDF} style={{ width: '70%' }}>
                            הורדת סכום שעות
                          </Button>
                        </Grid>
                        <Grid className={classSpinner}>
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size="3.5em" />
                          </Box>
                        </Grid>
                        <Grid style={{ marginTop: '10px' }} className={classButton}>
                          <Button onClick={viewPdf} variant="contained" color="primary" style={{ width: '50%' }}>
                            PDF
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
