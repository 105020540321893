import React, { useState, useEffect } from 'react';
import GenerateControls from './GenerateControls';
import { FileProps } from './personal-file';
import { FamilyData } from './FamilyData';
import { ServerData, ChildData } from './PersonalData';
import BaseRequest from '../helpers/BaseRequest';
export default function PersonalFile(props: FileProps) {
  const [arr, setArr] = useState<ChildData[]>(FamilyData());

  // if(props.serverData.length > 0){
  //   arr.forEach((element, idx)=> {
  //     element.value = props.serverData[idx]['value']
  //   })
  // }

  const next = () => {
    let isValid = true;
    let arrHelper = [...arr];
    arrHelper.forEach((a) => {
      if (a.isRequired && a.value == undefined) {
        a.isError = true;
        isValid = false;
      }
    });
    if (isValid) props.handleNext();
    else setArr(arrHelper);
  };

  const fetchData = () => {
    let server: ServerData[] = [];
    if (!props.isEdit) {
      arr.forEach((item, idx) => {
        item.id && server.push({ key: item.id, value: item.value !== undefined ? item.value : null });
      });
      props.setServerData(server);
    } else {
      let arrHelper = [...arr];
      const formData = new FormData();
      formData.append('student', props.id || '');
      BaseRequest('getStudent', formData)
        .then((res) => {
          let arrS: any[];
          let d = res.data[0].family_data.replace(/'/g, '\\"');
          arrS = [...JSON.parse(d)];
          props.setServerData(arrS);
          arrS.forEach((item, idx) => {
            if (arrHelper[arrHelper.findIndex((i) => i.id === item.key)] != undefined) {
              arrHelper[arrHelper.findIndex((i) => i.id === item.key)].value = item.value;
            }
          });
          setArr(arrHelper);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <GenerateControls
      handleNext={next}
      arr={arr}
      isEdit={props.isEdit}
      serverData={props.serverData}
      setServerData={props.setServerData}
      fileName="family"
      title={props.title}
    />
  );
}
