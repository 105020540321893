import { InputAdornment, Grid, makeStyles, Typography, TextField, Button, Card, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import BaseRequest from '../helpers/BaseRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  txt: {
    width: '100%',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#171010',
      borderWidth: '2px',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#14738c',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#14738c',
    },
    '& .MuiOutlinedInput-input': {
      color: '#171010',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#14738c',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#171010',
    },
    '& .MuiInputLabel-outlined': {
      color: '#171010',
      fontWeight: 'bold',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#14738c',
      fontWeight: 'bold',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#14738c',
      fontWeight: 'bold',
    },
  },
  gridContainer: {
    width: '30%',
    margin: '0 auto',
  },
  txtContainer: {
    width: '95%',
    marginTop: '25px',
  },
  cardClass: {
    width: '100%',
    direction: 'rtl',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  displayAlert: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },
  notDisplayAlert: {
    display: 'none',
  },
}));

interface infoFinancieresProps {
  id: string;
}

export default function InfosFinacieres(props: infoFinancieresProps) {
  // const arr = ["10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%"];

  // Tachloum Tipoul
  const [tachloumTipoul, setTachloumTipoul] = useState<any>();

  const [tachloumMichpachaS, setTachloumMichpachaS] = useState<any>(0);
  const [tachloumMichpachaP, setTachloumMichpachaP] = useState<any>(0);

  const [hanachaShoshanimS, setHanachaShoshanimS] = useState<any>(0);
  const [hanachaShoshanimP, setHanachaShoshanimP] = useState<any>(0);

  const [hanachaLeumitS, setHanachaLeumitS] = useState<any>(0);
  const [hanachaLeumitP, setHanachaLeumitP] = useState<any>(0);

  const [hanachaRevahaS, setHanachaRevahaS] = useState<any>(0);
  const [hanachaRevahaP, setHanachaRevahaP] = useState<any>(0);

  const [hanachaIriyaS, setHanachaIriyaS] = useState<any>(0);
  const [hanachaIriyaP, setHanachaIriyaP] = useState<any>(0);

  const [koupatActif, setKoupatActif] = useState<any>(false);
  const [hanachaKoupatS, setHanachaKoupatS] = useState<any>(0);
  const [hanachaKoupatP, setHanachaKoupatP] = useState<any>(0);

  // Tachloum Tipoul Rigchi
  const [tachloumTipoulRigchi, setTachloumTipoulRigchi] = useState<any>();

  const [tachloumRigchiMichpachaS, setTachloumRigchiMichpachaS] = useState<any>(0);
  const [tachloumRigchiMichpachaP, setTachloumRigchiMichpachaP] = useState<any>(0);

  const [hanachaRigchiShoshanimS, setHanachaRigchiShoshanimS] = useState<any>(0);
  const [hanachaRigchiShoshanimP, setHanachaRigchiShoshanimP] = useState<any>(0);

  const [hanachaRigchiLeumitS, setHanachaRigchiLeumitS] = useState<any>(0);
  const [hanachaRigchiLeumitP, setHanachaRigchiLeumitP] = useState<any>(0);

  const [hanachaRigchiRevahaS, setHanachaRigchiRevahaS] = useState<any>(0);
  const [hanachaRigchiRevahaP, setHanachaRigchiRevahaP] = useState<any>(0);

  const [hanachaRigchiIriyaS, setHanachaRigchiIriyaS] = useState<any>(0);
  const [hanachaRigchiIriyaP, setHanachaRigchiIriyaP] = useState<any>(0);

  const [koupatActifRigchi, setKoupatActifRigchi] = useState<any>(false);
  const [hanachaRigchiKoupatS, setHanachaRigchiKoupatS] = useState<any>(0);
  const [hanachaRigchiKoupatP, setHanachaRigchiKoupatP] = useState<any>(0);

  const classes = useStyles();
  const navigate = useNavigate();

  const [classeAlert, setClasseAlert] = useState(classes.notDisplayAlert);

  useEffect(() => {
    const object = {
      id_student: props.id,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(object));
    BaseRequest('getTachloumStudent', formData).then((res: { data: any }) => {
      let data = res.data;
      // Tipoul
      let data_tachloum_tipoul = data.tachloum_tipoul;
      setTachloumTipoul(data_tachloum_tipoul.tachloum_tipoul);
      setTachloumMichpachaS(data_tachloum_tipoul.tachloumMichpachaS);
      setTachloumMichpachaP(data_tachloum_tipoul.tachloumMichpachaP);
      setHanachaShoshanimS(data_tachloum_tipoul.hanachaShoshanimS);
      setHanachaShoshanimP(data_tachloum_tipoul.hanachaShoshanimP);
      setHanachaLeumitS(data_tachloum_tipoul.hanachaLeumitS);
      setHanachaLeumitP(data_tachloum_tipoul.hanachaLeumitP);
      setHanachaRevahaS(data_tachloum_tipoul.hanachaRevahaS);
      setHanachaRevahaP(data_tachloum_tipoul.hanachaRevahaP);
      setHanachaIriyaS(data_tachloum_tipoul.hanachaIriyaS);
      setHanachaIriyaP(data_tachloum_tipoul.hanachaIriyaP);
      if (data_tachloum_tipoul.hanachaKoupatS != 0 && data_tachloum_tipoul.tachloum_tipoul != null) {
        setKoupatActif(true);
        setHanachaKoupatS(data_tachloum_tipoul.hanachaKoupatS);
        setHanachaKoupatP(data_tachloum_tipoul.hanachaKoupatP);
      }
      // Tipoul Rigchi
      let data_tachloum_tipoul_rigchi = data.tachloum_tipoul_rigchi;
      setTachloumTipoulRigchi(data_tachloum_tipoul_rigchi.tachloum_tipoul_rigchi);
      setTachloumRigchiMichpachaS(data_tachloum_tipoul_rigchi.tachloumRigchiMichpachaS);
      setTachloumRigchiMichpachaP(data_tachloum_tipoul_rigchi.tachloumRigchiMichpachaP);
      setHanachaRigchiShoshanimS(data_tachloum_tipoul_rigchi.hanachaRigchiShoshanimS);
      setHanachaRigchiShoshanimP(data_tachloum_tipoul_rigchi.hanachaRigchiShoshanimP);
      setHanachaRigchiLeumitS(data_tachloum_tipoul_rigchi.hanachaRigchiLeumitS);
      setHanachaRigchiLeumitP(data_tachloum_tipoul_rigchi.hanachaRigchiLeumitP);
      setHanachaRigchiRevahaS(data_tachloum_tipoul_rigchi.hanachaRigchiRevahaS);
      setHanachaRigchiRevahaP(data_tachloum_tipoul_rigchi.hanachaRigchiRevahaP);
      setHanachaRigchiIriyaS(data_tachloum_tipoul_rigchi.hanachaRigchiIriyaS);
      setHanachaRigchiIriyaP(data_tachloum_tipoul_rigchi.hanachaRigchiIriyaP);
      if (
        data_tachloum_tipoul_rigchi.hanachaRigchiKoupatS != 0 &&
        data_tachloum_tipoul_rigchi.tachloum_tipoul_rigchi != null
      ) {
        setKoupatActifRigchi(true);
        setHanachaRigchiKoupatS(data_tachloum_tipoul_rigchi.hanachaRigchiKoupatS);
        setHanachaRigchiKoupatP(data_tachloum_tipoul_rigchi.hanachaRigchiKoupatP);
      }
    });
  }, []);

  const handleChangeSwitch = (e: any) => {
    setKoupatActif(e.target.checked);
    if (e.target.checked) {
      setHanachaKoupatS(165);
      let valueHanacha = 165;
      let valueHanachaPercentage = (Number(valueHanacha) / tachloumTipoul) * 100;
      valueHanachaPercentage = ((valueHanachaPercentage * 100) | 0) / 100;
      setHanachaKoupatP(valueHanachaPercentage);
    }
  };

  const handleChangeSwitchRigchi = (e: any) => {
    setKoupatActifRigchi(e.target.checked);
    if (e.target.checked) {
      setHanachaRigchiKoupatS(165);
      let valueHanacha = 165;
      let valueHanachaPercentage = (Number(valueHanacha) / tachloumTipoulRigchi) * 100;
      valueHanachaPercentage = ((valueHanachaPercentage * 100) | 0) / 100;
      setHanachaRigchiKoupatP(valueHanachaPercentage);
    }
  };

  const saveData = () => {
    const obj = {
      id_student: props.id,
      tachloum_tipoul: tachloumTipoul,
      tachloum_tipoul_rigchi: tachloumTipoulRigchi,
      hanacha_tipoul: [
        {
          tachloumMichpachaS: tachloumMichpachaS,
          tachloumMichpachaP: tachloumMichpachaP,
        },
        {
          hanachaShoshanimS: hanachaShoshanimS,
          hanachaShoshanimP: hanachaShoshanimP,
        },
        {
          hanachaLeumitS: hanachaLeumitS,
          hanachaLeumitP: hanachaLeumitP,
        },
        {
          hanachaRevahaS: hanachaRevahaS,
          hanachaRevahaP: hanachaRevahaP,
        },
        {
          hanachaIriyaS: hanachaIriyaS,
          hanachaIriyaP: hanachaIriyaP,
        },
        {
          hanachaKoupatS: koupatActif ? hanachaKoupatS : 0,
          hanachaKoupatP: koupatActif ? hanachaKoupatP : 0,
        },
      ],
      hanacha_tipoul_rigchi: [
        {
          tachloumRigchiMichpachaS: tachloumRigchiMichpachaS,
          tachloumRigchiMichpachaP: tachloumRigchiMichpachaP,
        },
        {
          hanachaRigchiShoshanimS: hanachaRigchiShoshanimS,
          hanachaRigchiShoshanimP: hanachaRigchiShoshanimP,
        },
        {
          hanachaRigchiLeumitS: hanachaRigchiLeumitS,
          hanachaRigchiLeumitP: hanachaRigchiLeumitP,
        },
        {
          hanachaRigchiRevahaS: hanachaRigchiRevahaS,
          hanachaRigchiRevahaP: hanachaRigchiRevahaP,
        },
        {
          hanachaRigchiIriyaS: hanachaRigchiIriyaS,
          hanachaRigchiIriyaP: hanachaRigchiIriyaP,
        },
        {
          hanachaRigchiKoupatS: koupatActifRigchi ? hanachaRigchiKoupatS : 0,
          hanachaRigchiKoupatP: koupatActifRigchi ? hanachaRigchiKoupatP : 0,
        },
      ],
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    BaseRequest('updateTachloumStudent', formData).then((res) => {
      if (res.success == 1) {
        setClasseAlert(classes.displayAlert);
      }
      // history.push({ pathname: "/students" }
    });
  };

  const handleTachloumTipoul = (e: any) => {
    let valueTachloumTipoul = e.target.value;
    setTachloumTipoul(valueTachloumTipoul);
    setTachloumMichpachaP((((tachloumMichpachaS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaShoshanimP((((hanachaShoshanimS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaLeumitP((((hanachaLeumitS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRevahaP((((hanachaRevahaS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaKoupatP((((hanachaKoupatS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaIriyaP((((hanachaIriyaS / valueTachloumTipoul) * 10000) | 0) / 100);
  };

  const handleTachloumTipoulRigchi = (e: any) => {
    let valueTachloumTipoul = e.target.value;
    // setTachloumTipoulRigchi(valueTachloumTipoul);
    setTachloumTipoulRigchi(valueTachloumTipoul);
    setTachloumRigchiMichpachaP((((tachloumRigchiMichpachaS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRigchiShoshanimP((((hanachaRigchiShoshanimS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRigchiLeumitP((((hanachaRigchiLeumitS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRigchiRevahaP((((hanachaRigchiRevahaS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRigchiKoupatP((((hanachaRigchiKoupatS / valueTachloumTipoul) * 10000) | 0) / 100);
    setHanachaRigchiIriyaP((((hanachaRigchiIriyaS / valueTachloumTipoul) * 10000) | 0) / 100);
  };

  const calculShekel = (e: any, SetFunctionS: any, setFunctionP: any, tachloum: any) => {
    let valueHanacha = e.target.value;
    SetFunctionS(valueHanacha);
    let valueHanachaPercentage = (Number(valueHanacha) / tachloum) * 100;
    valueHanachaPercentage = ((valueHanachaPercentage * 100) | 0) / 100;
    setFunctionP(valueHanachaPercentage);
  };

  const calculPercentage = (e: any, SetFunctionS: any, setFunctionP: any, tachloum: any) => {
    let valueHanachaPercentage = e.target.value;
    setFunctionP(valueHanachaPercentage);
    let valueHanachaCalcul = (Number(valueHanachaPercentage) * tachloum) / 100;
    valueHanachaCalcul = ((valueHanachaCalcul * 100) | 0) / 100;
    SetFunctionS(valueHanachaCalcul);
  };

  return (
    <div>
      <h1>נתונים פיננסיים</h1>
      {/* <Grid
        spacing={2}
        direction="column"
        justifyContent="center"
        container
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <Typography variant="h5">הנחה</Typography>
        </Grid>
        <Grid
          direction="row-reverse"
          spacing={1}
          item
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-start"
          container
          alignContent="flex-start"
        >
          {arr.map((item, idx) => {
            return (
              <Grid key={idx} item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={idx + 1 === indexChecked}
                      onChange={() => handleChange(idx)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={item}
                />
              </Grid>
            );
          })}
        </Grid>
        
      </Grid> */}
      <Grid xs={12} style={{ marginBottom: '16px' }}>
        <Typography variant="h5">תשלומים</Typography>
      </Grid>

      <Grid container direction="row-reverse">
        <Grid xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Grid item xs={12} style={{ direction: 'rtl' }}>
                <TextField
                  className={classes.txt}
                  type="number"
                  id="outlined-basic"
                  label="מחיר לטיפול"
                  value={tachloumTipoul}
                  onChange={handleTachloumTipoul}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faShekelSign} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="תשלום מהמשפחה בשקלים"
                    value={tachloumMichpachaS}
                    onChange={(e) => calculShekel(e, setTachloumMichpachaS, setTachloumMichpachaP, tachloumTipoul)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="תשלום מהמשפחה באחוזים"
                    value={tachloumMichpachaP}
                    onChange={(e) => {
                      calculPercentage(e, setTachloumMichpachaS, setTachloumMichpachaP, tachloumTipoul);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת שושנים בשקלים"
                    value={hanachaShoshanimS}
                    onChange={(e) => calculShekel(e, setHanachaShoshanimS, setHanachaShoshanimP, tachloumTipoul)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת שושנים באחוזים"
                    value={hanachaShoshanimP}
                    onChange={(e) => {
                      calculPercentage(e, setHanachaShoshanimS, setHanachaShoshanimP, tachloumTipoul);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת תכנית הלאומית בשקלים"
                    value={hanachaLeumitS}
                    onChange={(e) => calculShekel(e, setHanachaLeumitS, setHanachaLeumitP, tachloumTipoul)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת תכנית הלאומית באחוזים"
                    value={hanachaLeumitP}
                    onChange={(e) => {
                      calculPercentage(e, setHanachaLeumitS, setHanachaLeumitP, tachloumTipoul);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת רווחה בשקלים"
                    value={hanachaRevahaS}
                    onChange={(e) => calculShekel(e, setHanachaRevahaS, setHanachaRevahaP, tachloumTipoul)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת רווחה באחוזים"
                    value={hanachaRevahaP}
                    onChange={(e) => {
                      calculPercentage(e, setHanachaRevahaS, setHanachaRevahaP, tachloumTipoul);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת ערייה בשקלים"
                    value={hanachaIriyaS}
                    onChange={(e) => calculShekel(e, setHanachaIriyaS, setHanachaIriyaP, tachloumTipoul)}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת ערייה באחוזים"
                    value={hanachaIriyaP}
                    onChange={(e) => {
                      calculPercentage(e, setHanachaIriyaS, setHanachaIriyaP, tachloumTipoul);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* <FormControlLabel
                control={
                  <Switch
                    checked={koupatActif}
                    onChange={handleChangeSwitch}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="הנחת קופת חולים"
              />

              {
                koupatActif && 
                  <Grid container direction="row-reverse">

                  <Grid item xs={6} style={{direction: 'rtl'}}>
                    <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת קופת חולים בשקלים" value={hanachaKoupatS} variant="outlined" 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                      readOnly: true
                    }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{direction: 'rtl'}}>
                    <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת קופת חולים באחוזים" variant="outlined" 
                    value={hanachaKoupatP}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                      readOnly: true
                    }}
                    />
                  </Grid>

                </Grid>

              } */}
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Grid item xs={12} style={{ direction: 'rtl' }}>
                <TextField
                  className={classes.txt}
                  type="number"
                  id="outlined-basic"
                  label=" מחיר לטיפול מיוחד"
                  value={tachloumTipoulRigchi}
                  onChange={handleTachloumTipoulRigchi}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faShekelSign} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="תשלום מהמשפחה בשקלים"
                    value={tachloumRigchiMichpachaS}
                    onChange={(e) =>
                      calculShekel(e, setTachloumRigchiMichpachaS, setTachloumRigchiMichpachaP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="תשלום מהמשפחה באחוזים"
                    value={tachloumRigchiMichpachaP}
                    onChange={(e) =>
                      calculPercentage(
                        e,
                        setTachloumRigchiMichpachaS,
                        setTachloumRigchiMichpachaP,
                        tachloumTipoulRigchi,
                      )
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת שושנים בשקלים"
                    value={hanachaRigchiShoshanimS}
                    onChange={(e) =>
                      calculShekel(e, setHanachaRigchiShoshanimS, setHanachaRigchiShoshanimP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת שושנים באחוזים"
                    value={hanachaRigchiShoshanimP}
                    onChange={(e) =>
                      calculPercentage(e, setHanachaRigchiShoshanimS, setHanachaRigchiShoshanimP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת תכנית הלאומית בשקלים"
                    value={hanachaRigchiLeumitS}
                    onChange={(e) =>
                      calculShekel(e, setHanachaRigchiLeumitS, setHanachaRigchiLeumitP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת תכנית הלאומית באחוזים"
                    value={hanachaRigchiLeumitP}
                    onChange={(e) =>
                      calculPercentage(e, setHanachaRigchiLeumitS, setHanachaRigchiLeumitP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת רווחה בשקלים"
                    value={hanachaRigchiRevahaS}
                    onChange={(e) =>
                      calculShekel(e, setHanachaRigchiRevahaS, setHanachaRigchiRevahaP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת רווחה באחוזים"
                    value={hanachaRigchiRevahaP}
                    onChange={(e) =>
                      calculPercentage(e, setHanachaRigchiRevahaS, setHanachaRigchiRevahaP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row-reverse">
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת ערייה בשקלים"
                    value={hanachaRigchiIriyaS}
                    onChange={(e) =>
                      calculShekel(e, setHanachaRigchiIriyaS, setHanachaRigchiIriyaP, tachloumTipoulRigchi)
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ direction: 'rtl' }}>
                  <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת ערייה באחוזים"
                    value={hanachaRigchiIriyaP}
                    onChange={(e) => {
                      calculPercentage(e, setHanachaRigchiIriyaS, setHanachaRigchiIriyaP, tachloumTipoulRigchi);
                    }}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* <FormControlLabel
                control={
                  <Switch
                    checked={koupatActifRigchi}
                    onChange={handleChangeSwitchRigchi}
                    name="checkedA"
                    color="primary"
                  />
                }
                label="הנחת קופת חולים"
              />

              {
                koupatActifRigchi && 
                  <Grid container direction="row-reverse">

                  <Grid item xs={6} style={{direction: 'rtl'}}>
                    <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת קופת חולים בשקלים" value={hanachaRigchiKoupatS} variant="outlined" 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faShekelSign} />
                        </InputAdornment>
                      ),
                      readOnly: true
                    }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{direction: 'rtl'}}>
                    <TextField
                    className={classes.txtContainer}
                    type="number"
                    id="outlined-basic"
                    label="הנחת קופת חולים באחוזים" value={hanachaRigchiKoupatP} variant="outlined" 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faPercentage} />
                        </InputAdornment>
                      ),
                      readOnly: true
                    }}
                    />
                  </Grid>

                </Grid>

              } */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid item style={{ marginTop: '35px' }}>
        <Button variant="contained" color="primary" style={{ width: '40%' }} onClick={saveData}>
          לשמור
        </Button>
      </Grid>
      <Grid>
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" className={classeAlert}>
          נשלח בהצלחה
        </Alert>
      </Grid>
    </div>
  );
}
