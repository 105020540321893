import { useState, useEffect, useCallback, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import BaseRequest from '../../helpers/BaseRequest';
import { PersonalData } from '../PersonalData';
import { FamilyData } from '../FamilyData';
import { MedicalData } from '../MedicalData';
import { AcademicHistory } from '../AcademicHistory';
import { printOptionsList } from './print-options-list';
import type { DataType } from './print-options-list';
import { ComponentToPrint } from './component-to-print';
import type { PrintData } from './component-to-print';
import styles from './print-child-details.module.css';

export type PrintChildDetailsProps = {
  childId: string;
  show?: boolean;
  onClose?: () => void;
};

const childPersonalData = PersonalData();
const childFamilyData = FamilyData();
const childMedicalData = MedicalData();
const childAcademicData = AcademicHistory();

export function PrintChildDetails({ childId, show = false, onClose }: PrintChildDetailsProps) {
  const [dialogOpen, setDialogOpen] = useState(show);
  const [dataFetched, setDataFetched] = useState(false);
  const [printOptionsChecked, setPrintOptionsChecked] = useState<number[]>([]);
  const componentToPrintRef = useRef(null);
  const onBeforeGetContentResolve = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('old boring text');
  const [childImageUrl, setChildImageUrl] = useState<string | null>(null);
  const [personalData, setPersonalData] = useState<DataType[]>([]);
  const [familyData, setFamilyData] = useState<DataType[]>([]);
  const [medicalData, setMedicalData] = useState<DataType[]>([]);
  const [academicData, setAcademicData] = useState<DataType[]>([]);
  const [dataToPrint, setDataToPrint] = useState<PrintData[]>([]);

  const fetchData = useCallback(async () => {
    const formData = new FormData();
    formData.append('student', childId);
    await BaseRequest('getStudent', formData).then((res) => {
      // personal_data
      const personal_data: DataType[] = [...JSON.parse(res.data[0].personal_data.replace(/'/g, '\\"'))];
      const correctPersonalData = personal_data.map((data) => {
        const newData: DataType = {
          key: childPersonalData.find((item) => item.id === data.key)?.lable || data.key,
          value: data.value,
        };
        return newData;
      });

      // family_data
      const family_data: DataType[] = [...JSON.parse(res.data[0].family_data.replace(/'/g, '\\"'))];
      const correctFamilyData = family_data.map((data) => {
        const newData: DataType = {
          key: childFamilyData.find((item) => item.id === data.key)?.lable || data.key,
          value: data.value,
        };
        return newData;
      });

      // medical_data
      const medical_data: DataType[] = [...JSON.parse(res.data[0].medical_data.replace(/'/g, '\\"'))];
      const correctMedicalData = medical_data.map((data) => {
        const newData: DataType = {
          key: childMedicalData.find((item) => item.id === data.key)?.lable || data.key,
          value: data.value,
        };
        return newData;
      });

      // academic_data
      const academic_data: DataType[] = [...JSON.parse(res.data[0].academic_data.replace(/'/g, '\\"'))];
      const correctAcademicData = academic_data.map((data) => {
        const newData: DataType = {
          key: childAcademicData.find((item) => item.id === data.key)?.lable || data.key,
          value: data.value,
        };
        return newData;
      });

      setChildImageUrl(res.data[0].avatar_url);
      setPersonalData(correctPersonalData);
      setFamilyData(correctFamilyData);
      setMedicalData(correctMedicalData);
      setAcademicData(correctAcademicData);
      setDataFetched(true);
    });
  }, [childId]);

  useEffect(() => {
    setDialogOpen(show);
    if (show && !dataFetched) {
      // get the data only one time
      fetchData();
    }
  }, [show, dataFetched, fetchData]);

  const handlePrintOptionToggle = (value: number) => () => {
    const currentIndex = printOptionsChecked.indexOf(value);
    const newChecked = [...printOptionsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const newDataToPrint: PrintData[] = [];
    if (newChecked.indexOf(1) !== -1 && personalData)
      newDataToPrint.push({
        title: 'מידע כללי',
        data: personalData,
      });
    if (newChecked.indexOf(2) !== -1 && familyData)
      newDataToPrint.push({
        title: 'מידע משפחתי',
        data: familyData,
      });
    if (newChecked.indexOf(3) !== -1 && medicalData)
      newDataToPrint.push({
        title: 'מידע רפואי',
        data: medicalData,
      });
    if (newChecked.indexOf(4) !== -1 && academicData)
      newDataToPrint.push({
        title: 'מידע לימודי',
        data: academicData,
      });

    setPrintOptionsChecked(newChecked);
    setDataToPrint(newDataToPrint);
  };

  const handleClose = () => {
    setDialogOpen(false);
    onClose?.();
  };

  const handleAfterPrint = useCallback(() => {
    console.log('`onAfterPrint` called');
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log('`onBeforePrint` called');
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    console.log('`onBeforeGetContent` called');
    setLoading(true);
    setText('Loading new text...');

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  useEffect(() => {
    if (text === 'New, Updated Text!' && typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve, text]);

  const reactToPrintContent = useCallback(() => {
    return componentToPrintRef.current;
  }, [componentToPrintRef]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={printOptionsChecked.length === 0}
        className={styles.triggerButton}
        size="medium"
      >
        הדפסה
      </Button>
    );
  }, [printOptionsChecked]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.dialog}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">מה להדפיס?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">ניתן לבחור איזה נתונים להדפיס:</DialogContentText>
        <List dense className={styles.printOptionsList}>
          {printOptionsList.map((printOption) => {
            const labelId = `checkbox-list-secondary-label-${printOption.key}`;
            return (
              <ListItem key={printOption.key} button>
                <ListItemText id={labelId} primary={printOption.value} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handlePrintOptionToggle(printOption.key)}
                    checked={printOptionsChecked.indexOf(printOption.key) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="שם הילד"
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
        {loading && <p>מכין להדפסה...</p>}
        <ComponentToPrint ref={componentToPrintRef} printData={dataToPrint} childImageUrl={childImageUrl || ''} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
  );
}
