import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Container, Typography, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import BaseRequest from '../../../helpers/BaseRequest';
import { Heading, HeadingProps } from './heading';
import { SelectStudentID, selectStudentIDtitle } from './select-student-id';
import { QuestionsCategory } from './questions-category';
import type { FormResponses, CategoryOfResponses, CategoryOfQuestions } from './types';
import styles from './styles.module.css';

// get school, year, class from url, then show the avliable eleve id to complete

type BaseFormProps = {
  categoriesOfQuestions: CategoryOfQuestions[];
} & HeadingProps;

export function BaseForm({ formTitle, formDescription, categoriesOfQuestions }: BaseFormProps) {
  const [formResponses, setFormResponses] = useState<FormResponses>({ studentId: '', categoryOfResponses: [] });
  const [activeStep, setActiveStep] = useState(0);
  const steps = [selectStudentIDtitle].concat(categoriesOfQuestions.map((c) => c.title));
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formlinkid = params.get('formlinkid');
  const [formid, setFormid] = useState('');
  const [school, setSchool] = useState('');
  const [year, setYear] = useState('');
  const [classKita, setClassKita] = useState('');
  const [availableStudentsID, setAvailableStudentsID] = useState<number[]>([]);

  useEffect(() => {
    console.log('change in formResponses ', formResponses);
  }, [formResponses]);

  useEffect(() => {
    getFormLinkByID();
  }, [formlinkid]);

  const getFormLinkByID = async () => {
    if (typeof formlinkid === 'string') {
      const formData: FormData = new FormData();
      formData.append('form_link_id', formlinkid);
      const headersOptions = {
        'Content-Type': 'multipart/form-data',
      };
      await BaseRequest('getFormLinkByID', formData, headersOptions)
        .then(async (result) => {
          if (result.data) {
            const { form_id, school, year, class: classKita, students_number } = result.data;
            setSchool(school);
            setYear(year);
            setClassKita(classKita);
            setFormid(form_id);
            const initialAvailableStudentsId = Array.from(Array(parseInt(students_number)).keys(), (item) => item + 1);
            await getAllStudentIDbyFormLinkID(initialAvailableStudentsId);
          }
        })
        .catch((error) => {});
    }
  };

  const getAllStudentIDbyFormLinkID = async (idsToRemove: number[]) => {
    if (typeof formlinkid === 'string') {
      const formData: FormData = new FormData();
      formData.append('form_link_id', formlinkid);
      const headersOptions = {
        'Content-Type': 'multipart/form-data',
      };
      await BaseRequest('getAllStudentIDbyFormLinkID', formData, headersOptions)
        .then((result) => {
          if (result.data) {
            console.log('result.data', result.data);
            // if the result is not 0, so it's an array of used student id, need to remove them from the avalable array.
            if (result.data !== 0) {
              const usedIds: number[] = result.data.map((value: any) => parseInt(value));
              let newAvailableStudentsID = idsToRemove;
              usedIds.forEach((value) => {
                const positionToRemove = newAvailableStudentsID.findIndex((studentId) => studentId === value);
                newAvailableStudentsID.splice(positionToRemove, 1);
              });
              setAvailableStudentsID(newAvailableStudentsID);
            }
          } else {
            // if no id is used, so nothing to remove
            setAvailableStudentsID(idsToRemove);
          }
        })
        .catch((error) => {});
    }
  };

  const handleSaveFormResponses = async () => {
    const formData: FormData = new FormData();
    formData.append('form_link_id', formlinkid || '');
    formData.append('form_id', formid);
    formData.append('student_id', formResponses.studentId);
    formData.append('category_of_responses', JSON.stringify(JSON.stringify(formResponses.categoryOfResponses)));
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('addFormResponses', formData, headersOptions)
      .then((result) => {
        if (result.data) {
          console.log('result.data', result.data);
        }
      })
      .catch((error) => {});
  };

  const handleCategoryOfResponses = (category: CategoryOfResponses) => {
    const newFormResponses: FormResponses = {
      studentId: formResponses.studentId,
      categoryOfResponses: formResponses.categoryOfResponses,
    };
    if (formResponses.categoryOfResponses.find((c) => c.id === category.id)) {
      formResponses.categoryOfResponses.forEach((c, index) => {
        if (c.id === category.id) {
          newFormResponses.categoryOfResponses[index] = category;
        } else {
          newFormResponses.categoryOfResponses[index] = c;
        }
      });
    } else {
      newFormResponses.categoryOfResponses.push(category);
    }
    setFormResponses(newFormResponses);
  };

  const handleSelectStudentId = (studentid: string) => {
    const copyFormResponses = { ...formResponses };
    copyFormResponses.studentId = studentid;
    setFormResponses(copyFormResponses);
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      console.log('send data to server');
      await handleSaveFormResponses();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = async () => {
    setActiveStep(0);
    setFormResponses({ studentId: '', categoryOfResponses: [] });
    await getFormLinkByID();
  };

  const getStepContent = (stepIndex: number) => {
    if (stepIndex === 0)
      return (
        <SelectStudentID
          callback={handleSelectStudentId}
          initialFormResponses={formResponses}
          availableStudentsID={availableStudentsID}
        />
      );
    const stepCategory = categoriesOfQuestions.findIndex((value, index) => index + 1 === stepIndex);
    if (stepCategory === -1) return <div>Unknown stepIndex</div>;
    return (
      <QuestionsCategory
        categoryOfQuestions={categoriesOfQuestions[stepCategory]}
        initialCategoryOfResponses={formResponses.categoryOfResponses.find(
          (c, index) => c.id === categoriesOfQuestions[stepCategory].id,
        )}
        callback={handleCategoryOfResponses}
      />
    );
  };

  const isNextButtonDisabled = () => {
    if (activeStep === 0) {
      if (!formResponses.studentId) return true;
      return false;
    }

    if (activeStep <= steps.length - 1) {
      const currentResponseCategory = formResponses.categoryOfResponses.find(
        (c, index) => c.id === categoriesOfQuestions[activeStep - 1].id,
      );
      if (!currentResponseCategory) return true; // it's undefined when no questions of the category was responded.
      if (currentResponseCategory.responses.length !== categoriesOfQuestions[activeStep - 1].questions.length)
        return true; // disable the button until all the questions was responded
    }
    return false;
  };

  return (
    <Container className={styles.steppersContainer} component="main" maxWidth="md">
      <div>
        {activeStep === steps.length ? (
          <div style={{ marginBottom: 24 }}>
            <Typography>כל השלבים הושלמו ונשלחו</Typography>
            <Button onClick={handleReset} variant="contained" color="primary">
              איפוס
            </Button>
            <div>{formResponses.studentId} תלמיד</div>
            <div style={{ textAlign: 'right' }}>
              {formResponses.categoryOfResponses.map((category, index) => {
                return (
                  <div key={`${category.id}-${index}`}>
                    <div style={{ fontWeight: 'bold', marginTop: 24 }}>{category.title}</div>
                    <div>
                      {category.responses.map((response, index) => {
                        return (
                          <div key={`${response.responseValue}-${index}`}>
                            {response.questionTitle}: {response.responseValue}
                          </div>
                        );
                      })}
                      <div>
                        <span style={{ fontWeight: 'bold' }}>תגובה נוספת: </span>
                        <span>{category.note}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            <Heading
              formTitle={formTitle}
              formDescription={formDescription}
              moreInfo={`בית ספר ${school}, שנה ${year}, כיתה ${classKita}, תלמיד מספר ${formResponses.studentId}`}
            />
            <Typography>{getStepContent(activeStep)}</Typography>
            <div className={classNames(styles.marginTop, styles.marginBottom)}>
              <Button variant="contained" color="primary" onClick={handleNext} disabled={isNextButtonDisabled()}>
                {activeStep === steps.length - 1 ? 'סיים' : 'הבא'}
              </Button>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                חזור
              </Button>
            </div>
          </div>
        )}
      </div>
      <Stepper activeStep={activeStep} alternativeLabel dir="rtl">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
}
