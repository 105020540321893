import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid, makeStyles, Container } from '@material-ui/core';
import AddCourse from './AddCourse';
import AddMeeting from './AddMeeting';
import BaseRequest from '../helpers/BaseRequest';
import { useNavigate } from 'react-router-dom';
import Calendrier from './Calendrier';
import PastLessons from './PastLessons';
import { NewStudents } from '../students/new-students';
import { CoursesWithoutComments } from '../courses/courses-without-comments';
import { getUser } from './utils/user';
import { Roles } from './utils/roles';
import styles from './home.module.css';

const useStyles = makeStyles((theme) => ({
  coursContent: {
    width: 500,
    height: 600,
  },
  content: {
    width: 500,
    height: 600,
  },
  buttonsParent: {
    display: 'flex',
    margin: '15px',
    fontSize: '20px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    direction: 'rtl!important' as any,
    alignItems: 'baseline',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: '12px',
    width: 200,
  },
}));

export default function Home() {
  const [showAssCourse, setShowAddCourse] = useState(false);
  const [showAddMeeting, setShowAddMeeting] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const classes = useStyles();
  const user = getUser();

  useEffect(() => {
    const formData = new FormData();
    const obj = {
      user: localStorage.getItem('userName'),
    };
    formData.append('data', JSON.stringify(obj));
    BaseRequest('getNewstudent', formData)
      .then((res) => {
        if (res.success) setIsNew(true);
      })
      .catch((e) => console.log(e));
  }, []);

  const closeMeeting = () => {
    setShowAddMeeting(false);
  };

  const closeCourse = () => {
    setShowAddCourse(false);
  };

  return (
    <div className="backgroundImageHome">
      <Grid container justifyContent="flex-start" direction="column">
        <Grid xs={3} item container justifyContent="center" className={styles.newStudentsGrid}>
          <NewStudents style={{ marginTop: 15 }} />
        </Grid>
        <Grid item xs={6} container justifyContent="center" style={{ margin: '0 auto' }}>
          <div className={classes.buttonsParent}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowAddCourse(true);
              }}
              style={{ fontSize: '20px', marginRight: '5px' }}
            >
              הוספת טיפול
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowAddMeeting(true);
              }}
              style={{ fontSize: '20px' }}
            >
              הוספת אסיפה
            </Button>
          </div>
        </Grid>
      </Grid>

      <Dialog
        onClose={() => {
          setShowAddCourse(false);
        }}
        open={showAssCourse}
      >
        <DialogContent className={classes.coursContent}>
          <AddCourse ok={closeCourse} />
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => {
          setShowAddMeeting(false);
        }}
        open={showAddMeeting}
      >
        <DialogContent className={classes.content}>
          <AddMeeting ok={closeMeeting} />
        </DialogContent>
      </Dialog>

      <Grid item xs={12}>
        <Calendrier />
      </Grid>

      {user.role === Roles.Menahel || user.role === Roles.Secretaire ? (
        <Container maxWidth="lg" style={{ marginBottom: '30px' }}>
          <Grid item xs={12}>
            <CoursesWithoutComments />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="lg" style={{ marginBottom: '30px' }}>
          <Grid item xs={12}>
            <PastLessons />
          </Grid>
        </Container>
      )}
    </div>
  );
}
