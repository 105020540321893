import { ChildData } from './PersonalData';
export function AcademicHistory() {
  const arr: ChildData[] = [
    {
      type: 'radio',
      id: 'stayGrade',
      lable: 'האם הילד נשאר באותה כיתה ?',
      condition: false,
      childrenLables: [
        { id: 'stayGradeYes', lable: 'כן' },
        { id: 'stayGradeNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'whichGrade',
      lable: 'באיזו כיתה?',
      condition: true,
      conditionById: ['stayGrade'],
      conditionByIdValueRadio: ['stayGradeYes'],
    },
    {
      type: 'textArea',
      id: 'stayGradeReasons',
      lable: 'מאלו סיבות?',
      condition: true,
      conditionById: ['stayGrade'],
      conditionByIdValueRadio: ['stayGradeYes'],
    },
    {
      type: 'radio',
      id: 'learningDeficits',
      lable: 'באלו מיומנויות מתקשה הילד ?',
      condition: false,
      childrenLables: [
        { id: 'learningDeficitsYes', lable: 'כן' },
        { id: 'learningDeficitsNo', lable: 'לא' },
      ],
    },

    {
      type: 'textArea',
      id: 'learningDeficitsKind',
      lable: 'פירוט',
      condition: true,
      conditionById: ['learningDeficits'],
      conditionByIdValueRadio: ['learningDeficitsYes'],
    },
    // {
    //   type: "text",
    //   id: "learningDeficitsKindStart",
    //   lable: "כיתה?",
    //   kind: "text",
    //   condition: true,
    //   conditionById: ["learningDeficits"],
    //   conditionByIdValueRadio: ["learningDeficitsYes"],
    // },
    {
      type: 'label',
      lable: '-האם אתה רואה את ילדך כ',
      condition: false,
    },
    {
      type: 'radio',
      id: 'scattered',
      lable: 'מפוזר',
      childrenLables: [
        { id: 'scatteredYes', lable: 'כן' },
        { id: 'scatteredSometimes', lable: 'לפעמים' },
        { id: 'scatteredNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'radio',
      id: 'nervous',
      lable: 'עצבני',
      childrenLables: [
        { id: 'nervousYes', lable: 'כן' },
        { id: 'nervousSometimes', lable: 'לפעמים' },
        { id: 'nervousNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'radio',
      id: 'disruptClass',
      lable: 'מפריע בכיתה',
      childrenLables: [
        { id: 'disruptClassYes', lable: 'כן' },
        { id: 'disruptClassSometimes', lable: 'לפעמים' },
        { id: 'disruptClassNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'activeComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'label',
      lable: ': האם אתם שמים לב לקושי של הילד בתחומים שלהלן',
      condition: false,
    },
    {
      type: 'radio',
      id: 'attention',
      lable: 'קשב',
      childrenLables: [
        { id: 'attentionYes', lable: 'כן' },
        { id: 'attentionSometimes', lable: 'לפעמים' },
        { id: 'attentionNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'attentionComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'newLearning',
      lable: 'למידה של ידע חדש',
      childrenLables: [
        { id: 'newLearningYes', lable: 'כן' },
        { id: 'newLearningSometimes', lable: 'לפעמים' },
        { id: 'newLearningNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'newLearningComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'memory',
      lable: 'זיכרון',
      childrenLables: [
        { id: 'memoryYes', lable: 'כן' },
        { id: 'memorySometimes', lable: 'לפעמים' },
        { id: 'memoryNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'memoryComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'autonomy',
      lable: 'אוטונומיה',
      childrenLables: [
        { id: 'autonomyYes', lable: 'כן' },
        { id: 'autonomySometimes', lable: 'לפעמים' },
        { id: 'autonomyNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'autonomyComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'grossMotorSkills',
      lable: 'מוטוריקה גסה',
      childrenLables: [
        { id: 'grossMotorSkillsYes', lable: 'כן' },
        { id: 'grossMotorSkillsSometimes', lable: 'לפעמים' },
        { id: 'grossMotorSkillsNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'grossMotorSkillsComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'fineMotorSkills',
      lable: 'מוטוריקה עדינה',
      childrenLables: [
        { id: 'fineMotorSkillsYes', lable: 'כן' },
        { id: 'fineMotorSkillsSometimes', lable: 'לפעמים' },
        { id: 'fineMotorSkillsNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'fineMotorSkillsComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'confidence',
      lable: 'ביטחון עצמי/הערכה עצמית',
      childrenLables: [
        { id: 'confidenceYes', lable: 'כן' },
        { id: 'confidenceSometimes', lable: 'לפעמים' },
        { id: 'confidenceNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'confidenceComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'textArea',
      id: 'defectsComments',
      lable: 'אחר/הערות',
      condition: false,
    },
    {
      type: 'label',
      lable: ' ? האם אתם שמים לב לקושי של הילד בכלים האלה',
      condition: false,
    },
    {
      type: 'radio',
      id: 'reading',
      lable: 'קריאה',
      condition: false,
      childrenLables: [
        { id: 'readingYes', lable: 'כן' },
        { id: 'readingNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'readingComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'graphics',
      lable: 'צורת כתיבה',
      condition: false,
      childrenLables: [
        { id: 'graphicsYes', lable: 'כן' },
        { id: 'graphicsNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'graphicsComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'math',
      lable: 'חשבון',
      condition: false,
      childrenLables: [
        { id: 'mathYes', lable: 'כן' },
        { id: 'mathNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'mathComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'radio',
      id: 'expression ',
      lable: 'הבעה',
      condition: false,
      childrenLables: [
        { id: 'expressionYes', lable: 'כן' },
        { id: 'expressionNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'expressionComments',
      lable: 'הערות',
      condition: false,
    },
    // {
    //   type: "radio",
    //   id: "writing",
    //   lable: "כתיבה",
    //   condition: false,
    //   childrenLables: [
    //     { id: "writingYes", lable: "כן" },
    //     { id: "writingNo", lable: "לא" },
    //   ],
    // },
    {
      type: 'textArea',
      id: 'anotherSubject',
      lable: 'אחר',
      condition: false,
    },
    {
      type: 'label',
      lable: '? האם הילד קיבל עזרה או טיפול',
      condition: false,
    },
    {
      type: 'radio',
      id: 'privateTeacher',
      lable: 'האם הילד קיבל שיעורי עזר בבית ספר או ממורה פרטי ?',
      childrenLables: [
        { id: 'privateTeacherYes', lable: 'כן' },
        { id: 'privateTeacherSometimes', lable: 'לפעמים' },
        { id: 'privateTeacherNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'text',
      id: 'privateTeacherSubject',
      lable: 'באיזה מקצוע ?',
      kind: 'text',
      condition: true,
      conditionById: ['privateTeacher'],
      conditionByIdValueRadio: ['privateTeacherYes', 'privateTeacherSometimes'],
    },
    {
      type: 'textArea',
      id: 'privateTeacherComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['privateTeacher'],
      conditionByIdValueRadio: ['privateTeacherYes', 'privateTeacherSometimes'],
    },
    {
      type: 'radio',
      id: 'occupationalTherapist',
      lable: 'מרפא בעיסוק',
      condition: false,
      childrenLables: [
        { id: 'occupationalTherapistYes', lable: 'כן' },
        { id: 'occupationalTherapistNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'occupationalTherapistAge',
      lable: 'באיזה גיל/כיתה ?',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapist'],
      conditionByIdValueRadio: ['occupationalTherapistYes'],
    },
    {
      type: 'text',
      id: 'occupationalTherapistTime',
      lable: 'לכמה טיפולים ?',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapist'],
      conditionByIdValueRadio: ['occupationalTherapistYes'],
    },
    {
      type: 'text',
      id: 'occupationalTherapistName',
      lable: 'שם המטפל',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapist'],
      conditionByIdValueRadio: ['occupationalTherapistYes'],
    },
    {
      type: 'text',
      id: 'occupationalTherapistCenter',
      lable: 'באיזה מרכז ?',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapist'],
      conditionByIdValueRadio: ['occupationalTherapistYes'],
    },
    {
      type: 'radio',
      id: 'communicationClinician',
      lable: 'קלינאי תקשורת',
      condition: false,
      childrenLables: [
        { id: 'communicationClinicianYes', lable: 'כן' },
        { id: 'communicationClinicianNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'communicationClinicianAge',
      lable: 'באיזה גיל/כיתה ?',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinician'],
      conditionByIdValueRadio: ['communicationClinicianYes'],
    },
    {
      type: 'text',
      id: 'communicationClinicianTime',
      lable: 'לכמה זמן ?',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinician'],
      conditionByIdValueRadio: ['communicationClinicianYes'],
    },
    {
      type: 'text',
      id: 'communicationClinicianName',
      lable: 'שם המטפל',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinician'],
      conditionByIdValueRadio: ['communicationClinicianYes'],
    },
    {
      type: 'text',
      id: 'communicationClinicianCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinician'],
      conditionByIdValueRadio: ['communicationClinicianYes'],
    },
    {
      type: 'radio',
      id: 'תרגילי עיניים',
      lable: 'מיקוד ראייה',
      condition: false,
      childrenLables: [
        { id: 'OrthopticsYes', lable: 'כן' },
        { id: 'OrthopticsNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'OrthopticsAge',
      lable: 'באיזה גיל/כיתה ?',
      kind: 'text',
      condition: true,
      conditionById: ['Orthoptics'],
      conditionByIdValueRadio: ['OrthopticsYes'],
    },
    {
      type: 'text',
      id: 'OrthopticsTime',
      lable: 'לכמה זמן ?',
      kind: 'text',
      condition: true,
      conditionById: ['Orthoptics'],
      conditionByIdValueRadio: ['OrthopticsYes'],
    },
    {
      type: 'text',
      id: 'OrthopticsName',
      lable: 'שם המטפל',
      kind: 'text',
      condition: true,
      conditionById: ['Orthoptics'],
      conditionByIdValueRadio: ['OrthopticsYes'],
    },
    {
      type: 'text',
      id: 'OrthopticsCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['Orthoptics'],
      conditionByIdValueRadio: ['OrthopticsYes'],
    },
    {
      type: 'radio',
      id: 'correctiveTeacher',
      lable: 'מורה מתקנת',
      condition: false,
      childrenLables: [
        { id: 'correctiveTeacherYes', lable: 'כן' },
        { id: 'correctiveTeacherNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'correctiveTeacherAge',
      lable: 'באיזה גיל/כיתה ?',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacher'],
      conditionByIdValueRadio: ['correctiveTeacherYes'],
    },
    {
      type: 'text',
      id: 'correctiveTeacherTime',
      lable: 'לכמה זמן ?',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacher'],
      conditionByIdValueRadio: ['correctiveTeacherYes'],
    },
    {
      type: 'text',
      id: 'correctiveTeacherName',
      lable: 'שם המורה',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacher'],
      conditionByIdValueRadio: ['correctiveTeacherYes'],
    },
    {
      type: 'text',
      id: 'correctiveTeacherCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacher'],
      conditionByIdValueRadio: ['correctiveTeacherYes'],
    },
    {
      type: 'radio',
      id: 'psychologist',
      lable: 'פסיכולוג',
      condition: false,
      childrenLables: [
        { id: 'psychologistYes', lable: 'כן' },
        { id: 'psychologistNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'psychologistAge',
      lable: 'באיזה גיל/כיתה ?',
      kind: 'text',
      condition: true,
      conditionById: ['psychologist'],
      conditionByIdValueRadio: ['psychologistYes'],
    },
    {
      type: 'text',
      id: 'psychologistTime',
      lable: 'לכמה זמן?',
      kind: 'text',
      condition: true,
      conditionById: ['psychologist'],
      conditionByIdValueRadio: ['psychologistYes'],
    },
    {
      type: 'text',
      id: 'psychologistName',
      lable: 'שם המטפל',
      kind: 'text',
      condition: true,
      conditionById: ['psychologist'],
      conditionByIdValueRadio: ['psychologistYes'],
    },
    {
      type: 'text',
      id: 'psychologistCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['psychologist'],
      conditionByIdValueRadio: ['psychologistYes'],
    },
    {
      type: 'textArea',
      id: 'otherTreatment',
      lable: 'אחר-לפרט',
      condition: false,
    },
    {
      type: 'label',
      lable: ' ? האם הילד מקבל כעת עזרה או טיפול',
      condition: false,
    },
    // {
    //   type: "radio",
    //   id: "privateTeacherNow",
    //   lable: "האם הילד מקבל כעת שיעורי עזר בבית ספר ?",
    //   childrenLables: [
    //     { id: "privateTeacherNowYes", lable: "כן" },
    //     { id: "privateTeacherNowSometimes", lable: "לפעמים" },
    //     { id: "privateTeacherNowNo", lable: "לא" },
    //   ],
    //   condition: false,
    // },
    // {
    //   type: "text",
    //   id: "privateTeacherNowSubject",
    //   lable: "באיזה מקצוע?",
    //   kind: "text",
    //   condition: true,
    //   conditionById: ["privateTeacherNow"],
    //   conditionByIdValueRadio:["privateTeacherNowYes","privateTeacherNowSometimes"]
    // },
    // {
    //   type: "textArea",
    //   id: "privateTeacherNowComments",
    //   lable: "הערות",
    //   condition: true,
    //   conditionById: ["privateTeacherNow"],
    //   conditionByIdValueRadio:["privateTeacherNowYes","privateTeacherNowSometimes"]
    // },
    {
      type: 'radio',
      id: 'occupationalTherapistNow',
      lable: 'מרפא בעיסוק',
      condition: false,
      childrenLables: [
        { id: 'occupationalTherapistNowYes', lable: 'כן' },
        { id: 'occupationalTherapistNowNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'occupationalTherapistNowWhen',
      lable: 'ממתי ?',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapistNow'],
      conditionByIdValueRadio: ['occupationalTherapistNowYes'],
    },
    {
      type: 'text',
      id: 'occupationalTherapistNowName',
      lable: 'שם המרפא',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapistNow'],
      conditionByIdValueRadio: ['occupationalTherapistNowYes'],
    },
    {
      type: 'text',
      id: 'occupationalTherapistNowCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['occupationalTherapistNow'],
      conditionByIdValueRadio: ['occupationalTherapistNowYes'],
    },
    {
      type: 'radio',
      id: 'communicationClinicianNow',
      lable: 'קלינאי תקשורת',
      condition: false,
      childrenLables: [
        { id: 'communicationClinicianNowYes', lable: 'כן' },
        { id: 'communicationClinicianNowNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'communicationClinicianNowWhen',
      lable: 'ממתי ?',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinicianNow'],
      conditionByIdValueRadio: ['communicationClinicianNowYes'],
    },
    {
      type: 'text',
      id: 'communicationClinicianNowName',
      lable: 'שם המרפא',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinicianNow'],
      conditionByIdValueRadio: ['communicationClinicianNowYes'],
    },
    {
      type: 'text',
      id: 'communicationClinicianNowCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['communicationClinicianNow'],
      conditionByIdValueRadio: ['communicationClinicianNowYes'],
    },
    {
      type: 'radio',
      id: 'correctiveTeacherNow',
      lable: 'מורה מתקנת',
      condition: false,
      childrenLables: [
        { id: 'correctiveTeacherNowYes', lable: 'כן' },
        { id: 'correctiveTeacherNowNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'correctiveTeacherNowWhen',
      lable: 'ממתי ?',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacherNow'],
      conditionByIdValueRadio: ['correctiveTeacherNowYes'],
    },
    {
      type: 'text',
      id: 'correctiveTeacherNowName',
      lable: 'שם המורה',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacherNow'],
      conditionByIdValueRadio: ['correctiveTeacherNowYes'],
    },
    {
      type: 'text',
      id: 'correctiveTeacherNowCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['correctiveTeacherNow'],
      conditionByIdValueRadio: ['correctiveTeacherNowYes'],
    },
    {
      type: 'radio',
      id: 'psychologistNow',
      lable: 'פסיכולוג',
      condition: false,
      childrenLables: [
        { id: 'psychologistNowYes', lable: 'כן' },
        { id: 'psychologistNowNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'psychologistNowWhen',
      lable: 'ממתי ?',
      kind: 'text',
      condition: true,
      conditionById: ['psychologistNow'],
      conditionByIdValueRadio: ['psychologistNowYes'],
    },
    {
      type: 'text',
      id: 'psychologistNowName',
      lable: 'שם הרופא',
      kind: 'text',
      condition: true,
      conditionById: ['psychologistNow'],
      conditionByIdValueRadio: ['psychologistNowYes'],
    },
    {
      type: 'text',
      id: 'psychologistNowCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['psychologistNow'],
      conditionByIdValueRadio: ['psychologistNowYes'],
    },
    {
      type: 'radio',
      id: 'OrthopticsNow',
      lable: 'תרגילי עיניים',
      condition: false,
      childrenLables: [
        { id: 'OrthopticsNowYes', lable: 'כן' },
        { id: 'OrthopticsNowNo', lable: 'לא' },
      ],
    },
    {
      type: 'text',
      id: 'OrthopticsNowWhen',
      lable: 'ממתי ?',
      kind: 'text',
      condition: true,
      conditionById: ['OrthopticsNow'],
      conditionByIdValueRadio: ['OrthopticsNowYes'],
    },
    {
      type: 'text',
      id: 'OrthopticsNowName',
      lable: 'שם המרפא',
      kind: 'text',
      condition: true,
      conditionById: ['OrthopticsNow'],
      conditionByIdValueRadio: ['OrthopticsNowYes'],
    },
    {
      type: 'text',
      id: 'OrthopticsNowCenter',
      lable: 'שם המרכז',
      kind: 'text',
      condition: true,
      conditionById: ['OrthopticsNow'],
      conditionByIdValueRadio: ['OrthopticsNowYes'],
    },
    {
      type: 'textArea',
      id: 'otherTreatmentNow',
      lable: 'אחר-לפרט',
      condition: false,
    },
    {
      type: 'label',
      lable: 'שיעורי בית',
      condition: false,
    },
    {
      type: 'radio',
      id: 'hwHelp',
      lable: '?האם הילד זקוק לעזרה בשיעורי בית',
      condition: false,
      childrenLables: [
        { id: 'hwHelpYes', lable: 'כן' },
        { id: 'hwHelpNo', lable: 'לא' },
      ],
    },
    {
      type: 'textArea',
      id: 'hwHelpComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['hwHelp'],
      conditionByIdValueRadio: ['hwHelpYes'],
    },
    {
      type: 'text',
      id: 'hwHelpWho',
      lable: 'מי עוזר לו בשיעורי בית ?',
      kind: 'text',
      condition: true,
      conditionById: ['hwHelp'],
      conditionByIdValueRadio: ['hwHelpYes'],
    },
    {
      type: 'text',
      id: 'hwHelpTime ',
      lable: 'כמה זמן לוקח לו להשלים את שיעורי הבית ?',
      kind: 'text',
      condition: true,
      conditionById: ['hwHelp'],
      conditionByIdValueRadio: ['hwHelpYes'],
    },
    {
      type: 'radio',
      id: 'hwHardTime',
      lable: 'האם זה זמן קשה ?',
      childrenLables: [
        { id: 'hwHardTimeYes', lable: 'כן' },
        { id: 'hwHardTimeSometimes', lable: 'לפעמים' },
        { id: 'hwHardTimeNo', lable: 'לא' },
      ],
      condition: true,
      conditionById: ['hwHelp'],
      conditionByIdValueRadio: ['hwHelpYes'],
    },
    {
      type: 'textArea',
      id: 'hwHardTimeComments',
      lable: 'הערות',
      condition: false,
    },
    {
      type: 'label',
      lable: 'כישורים חברתיים',
      condition: false,
    },
    {
      type: 'radio',
      id: 'manegeWithFamily',
      lable: ' האם הילד מסתדר טוב עם כל בני המשפחה ?',
      childrenLables: [
        { id: 'manegeWithFamilyYes', lable: 'כן' },
        { id: 'manegeWithFamilyNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'manegeWithFamilyComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['manegeWithFamily'],
      conditionByIdValueRadio: ['manegeWithFamilyNo'],
    },
    {
      type: 'radio',
      id: 'manegeWithFrainds',
      lable: ' האם הילד מסתדר טוב עם ילדים בני גילו ?',
      childrenLables: [
        { id: 'manegeWithFraindsYes', lable: 'כן' },
        { id: 'manegeWithFraindsNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'manegeWithFraindsComments',
      lable: 'הערות',
      condition: true,
      conditionById: ['manegeWithFrainds'],
      conditionByIdValueRadio: ['manegeWithFraindsNo'],
    },
    {
      type: 'radio',
      id: 'keepFrainds',
      lable: ' האם הילד שומר על חבריו בקלות ?',
      childrenLables: [
        { id: 'keepFraindsYes', lable: 'כן' },
        { id: 'keepFraindsNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'keepFraindsComments',
      lable: 'לפרט',
      condition: true,
      conditionById: ['keepFrainds'],
      conditionByIdValueRadio: ['keepFraindsNo'],
    },
    {
      type: 'radio',
      id: 'schoolOnTime',
      lable: ' האם הילד מגיע בזמן לבית הספר ?',
      childrenLables: [
        { id: 'schoolOnTimeYes', lable: 'כן' },
        { id: 'schoolOnTimeNo', lable: 'לא' },
      ],
      condition: false,
    },
    {
      type: 'textArea',
      id: 'keepFraindsComments',
      lable: 'מדוע ?',
      condition: true,
      conditionById: ['schoolOnTime'],
      conditionByIdValueRadio: ['schoolOnTimeNo'],
    },
    {
      type: 'textArea',
      id: 'otherImportantThings',
      lable: 'הערות',
      condition: false,
    },
  ];
  return arr;
}
