import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { Facebook } from 'react-spinners-css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BaseRequest from '../../../helpers/BaseRequest';
import { baseCrmURL } from '../../../helpers/config';
import { getUser } from '../../utils/user';
import { selectableForms } from '../selectable-forms';
import { FormLink, FormResponse } from '../types';
import { FormResponses } from './form-responses';
import styles from './form-link-info.module.css';

export type FromLinkInfoProps = {
  formLink: FormLink;
} & React.HTMLAttributes<HTMLDivElement>;

export function FormLinkInfo({ formLink }: FromLinkInfoProps) {
  const formPath = selectableForms.find((form) => form.value === formLink.form_id)?.path;
  const formLinkToSend = `${baseCrmURL}/forms/${formPath}?formlinkid=${formLink.form_link_id}`;
  const createdDate = new Date(formLink.created_date);
  const formatedCreatedDate = `${createdDate.getDate()}/${
    createdDate.getMonth() + 1
  }/${createdDate.getFullYear()} - ${createdDate.getHours()}:${
    createdDate.getMinutes() < 10 ? `0${createdDate.getMinutes()}` : createdDate.getMinutes()
  }`;
  const [formResponses, setFormResponses] = useState<FormResponse[]>([]);
  const totalResponses = formResponses.length;
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = getUser();

  useEffect(() => {
    getAllFormResponsesByFormLinkIDFormLinks();
  }, []);

  const getAllFormResponsesByFormLinkIDFormLinks = async () => {
    const formData: FormData = new FormData();
    formData.append('form_link_id', formLink.form_link_id);
    // formData.append('form_link_id', '6');
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    await BaseRequest('getAllFormResponsesByFormLinkID', formData, headersOptions)
      .then((result) => {
        if (result.data) {
          let newFormResponses: FormResponse[] = [];
          for (let index = 0; index < result.data.length; index++) {
            const element = result.data[index];
            const response: FormResponse = {
              categoryOfResponses: JSON.parse(JSON.parse(element.category_of_responses)),
              formId: element.form_id,
              formLinkId: element.form_link_id,
              formResponseId: element.form_response_id,
              studentId: element.student_id,
            };
            newFormResponses.push(response);
          }
          setFormResponses(newFormResponses);
        }
      })
      .catch((error) => {});
  };

  const onSubmitRemoveFormLink = async () => {
    const formData: FormData = new FormData();
    formData.append('form_link_id', formLink.form_link_id);
    formData.append('token', user.token);
    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };
    setLoading(true);
    await BaseRequest('removeFormLink', formData, headersOptions)
      .then((result) => {
        if (result.data) {
          window.location.reload();
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  return (
    <div className={classNames(styles.modalContainer)}>
      <Typography variant="h3">מידע על השאלון</Typography>
      <div className={styles.form}>
        <div>
          <span className={styles.modalInfoHead}>יוצר הלינק:</span>
          <span>{formLink.username}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>תאריך יצירה:</span>
          <span>{formatedCreatedDate}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>שם השאלון:</span>
          <span>{formLink.formName}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>בית הספר:</span>
          <span>{formLink.school}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>שנה:</span>
          <span>{formLink.year}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>כיתה:</span>
          <span>{formLink.className}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>מספר התלמידים:</span>
          <span>{formLink.students_number}</span>
        </div>
        <div>
          <span className={styles.modalInfoHead}>מספר הטפסים שנשלחו:</span>
          <span>{totalResponses}</span>
        </div>
        <div className={styles.copyLinkContainer}>
          <span className={styles.modalInfoHead}>לינק למילוי השאלון:</span>
          <Link href={formLinkToSend} underline="hover" target="_blank">
            {formLinkToSend}
          </Link>
          <CopyToClipboard text={formLinkToSend}>
            <IconButton aria-label="delete" style={{ marginRight: 8 }}>
              <FileCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<DeleteIcon />}
            onClick={() => setOpenRemoveDialog(true)}
          >
            מחיקת שאלון
          </Button>
        </div>
      </div>
      <FormResponses formResponses={formResponses} />
      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.removeDialogContainer}
      >
        <DialogTitle id="alert-dialog-title">האם למחוק את השאלון?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">האם למחוק את השאלון?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)}>ביטול</Button>
          <Button
            onClick={onSubmitRemoveFormLink}
            disabled={loading}
            variant={loading ? 'text' : 'contained'}
            color="secondary"
          >
            {loading ? <Facebook size={40} color="#14738c" style={{ display: loading ? '' : 'none' }} /> : 'מחק'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
