import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EnhancedTable, { HeadCell } from './Table';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { Ring } from 'react-spinners-css';
import { CustomAutoComplete } from './custom-autocomplete';
import BaseRequest from '../helpers/BaseRequest';
import { AutoCompleteList } from './AddCourse';
import {
  FormControl,
  Card,
  Dialog,
  DialogContent,
  Select,
  Grid,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Input,
} from '@material-ui/core';
import EditCourse from './EditCourse';
import { Roles } from './utils/roles';
import { getUser } from './utils/user';
import { useBranches } from '../branches/use-branches';
import type { Branche } from '../branches/use-branches';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  card: {
    margin: '1% 15%',
    padding: '0 2% 2% 2%',
    direction: 'rtl',
  },
  grid: {
    padding: '0 4px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface CourseData {
  childName: string;
  teacherName: string;
  classRoom: string;
  date: string;
  hour: string;
  status: string;
  numberOfTipoulim: string;
  id_interv: string;
  id_elev: string;
  brancheName: string | null;
  item: object;
}
export default function CourseList() {
  const [intervenant, setIntervenant] = useState<AutoCompleteList[]>([]);
  const [students, setStudents] = useState<AutoCompleteList[]>([]);
  const [child, setChild] = useState<AutoCompleteList>();
  const [teacher, setTeacher] = useState<AutoCompleteList>();
  const [course, setCourse] = useState<any>();
  const [showEditCourse, setShowEditCourse] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const clickIcon = (item: any) => {
    setCourse(item);
    setShowEditCourse(true);
  };
  const cells: HeadCell[] = [
    { id: 'childName', label: 'שם ילד', isSortable: true },
    { id: 'teacherName', label: 'שם המטפל', isSortable: true },
    { id: 'classRoom', label: 'חדר', isSortable: true },
    { id: 'date', label: 'תאריך', isSortable: true, isDate: true },
    { id: 'hour', label: 'שעה', isSortable: true },
    { id: 'status', label: 'סטטוס', isSortable: true },
    { id: 'brancheName', label: <div style={{ width: 100 }}>סניף</div>, isSortable: false },
    { id: 'numberOfTipoulim', label: 'מספר טיפולים', isSortable: false },
    {
      id: 'edit',
      label: 'עריכה',
      type: 'icon',
      handleClickIcon: clickIcon,
      isSortable: false,
    },
  ];
  const [displayRows, setDisplayRows] = useState<CourseData[]>([]);
  const [rows, setRows] = useState<CourseData[]>([]);
  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);
  const statusList = ['קיים', 'מבוטל ללא תשלום', 'מבוטל שולם', ''];
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const user = getUser();
  const { branchesList, selectedBranche } = useBranches();
  const brancheOptions = branchesList?.concat([{ id: (branchesList.length + 1).toString(), name: 'לא הוגדר סניף' }]);
  const [selectedBranches, setSelectedBranches] = useState<Branche[]>([]);

  useEffect(() => {
    if (selectedBranche) setSelectedBranches([selectedBranche]);
  }, [selectedBranche]);

  useEffect(() => {
    getIntervant();
    getStudent();
    getCours();
  }, []);

  const handleChangeSelectedBranches = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    const newSelectedBranches = brancheOptions?.filter((branche) => values.includes(branche.name));
    setSelectedBranches(newSelectedBranches || []);
  };

  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  // var date = curr.toISOString().substr(0, 10);

  useEffect(() => {
    let lessons = rows.filter((r) => {
      let date_from_search = new Date(selectedFromDate != null ? selectedFromDate : '');
      let date_to_search = new Date(selectedToDate != null ? selectedToDate : '');
      let date_from_formatted =
        appendLeadingZeroes(date_from_search.getMonth() + 1) +
        '/' +
        appendLeadingZeroes(date_from_search.getDate()) +
        '/' +
        date_from_search.getFullYear();

      let date_to_formatted =
        appendLeadingZeroes(date_to_search.getMonth() + 1) +
        '/' +
        appendLeadingZeroes(date_to_search.getDate()) +
        '/' +
        date_to_search.getFullYear();

      let lessonDate = r.date.split('-');
      const formatedLessonDate = new Date(`${lessonDate[1]}/${lessonDate[0]}/${lessonDate[2]}`);
      const formatedFromDate = new Date(date_from_formatted);
      const formatedToDate = new Date(date_to_formatted);

      let check =
        (child ? r.id_elev === child.id : true) &&
        (teacher ? r.id_interv === teacher.id : true) &&
        (selectedStatus.length > 0 ? selectedStatus.includes(r.status) : true) &&
        (selectedFromDate
          ? isAfter(formatedLessonDate, formatedFromDate) || isEqual(formatedLessonDate, formatedFromDate)
          : true) &&
        (selectedToDate
          ? isBefore(formatedLessonDate, formatedToDate) || isEqual(formatedLessonDate, formatedToDate)
          : true);

      return check;
    });
    setDisplayRows(lessons);
  }, [rows, teacher, child, selectedToDate, selectedFromDate, selectedStatus]);

  useEffect(() => {
    if (selectedBranches.length === 0) {
      setDisplayRows(rows);
    } else {
      const students = rows.filter((s) => {
        return selectedBranches.findIndex((b) => b.name === s.brancheName) > -1;
      });
      setDisplayRows(students);
    }
  }, [selectedBranches, rows]);

  const handleChangeSelectedStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    const newSelectedStatus = statusList?.filter((status) => values.includes(status));
    setSelectedStatus(newSelectedStatus || []);
  };

  const getStudent = () => {
    const formData: FormData = new FormData();
    if (user.role === Roles.Intervenant) {
      const obj = {
        id_interv: user.interv_id,
      };
      formData.append('metapel', JSON.stringify(obj));
    }

    BaseRequest('getStudent', formData)
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({ id: item.id, value: `${item.firstName} ${item.lastName}` });
        });
        setStudents(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getIntervant = () => {
    BaseRequest('getIntervenant')
      .then((res: { data: any[] }) => {
        let i: AutoCompleteList[] = [];
        res.data.forEach((item) => {
          i.push({ id: item.id_interv, value: `${item.prenom} ${item.nom}` });
        });
        setIntervenant(i);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const appendLeadingZeroes = (n: any) => {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  };

  const getCours = () => {
    const obj = {
      id: localStorage.getItem('id'),
      role: user.role,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    BaseRequest('getCours', formData)
      .then((res: any) => {
        let rows: CourseData[] = [];
        res.data.forEach((item: any) => {
          let date_cours_format = new Date(item.date_cours);
          let date_cours =
            appendLeadingZeroes(date_cours_format.getDate()) +
            '-' +
            appendLeadingZeroes(date_cours_format.getMonth() + 1) +
            '-' +
            date_cours_format.getFullYear();
          let status_cours = '';
          if (item.batel === '0') {
            status_cours = 'קיים';
          } else if (item.batel === '1' && item.yeled_tachloum === 'ללא תשלום') {
            status_cours = 'מבוטל ללא תשלום';
          } else if (item.batel === '1' && item.yeled_tachloum === ' 100% תשלום') {
            status_cours = 'מבוטל שולם';
          }
          rows.push(
            createData(
              `${item.student_firstname} ${item.student_lastname}`,
              `${item.interv_prenom} ${item.interv_nom}`,
              item.name_salle,
              // item.date_cours,
              date_cours,
              item.hour_cours,
              // item.batel == 0 ? 'קיים' : 'מבוטל',
              status_cours,
              `${item.number_cours}/${item.total_number_cours}`,
              item.id_elev,
              item.id_interv,
              item.branche_name,
              item,
            ),
          );
        });
        setDisplayRows(rows);
        setRows(rows);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date === null) setSelectedFromDate(null);
    else new Date(date || '').toString() !== 'Invalid Date' && setSelectedFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    if (date === null) setSelectedToDate(null);
    else new Date(date || '').toString() !== 'Invalid Date' && setSelectedToDate(date);
  };

  const CloseEdit = () => {
    setShowEditCourse(false);
    getCours();
  };

  const createData = (
    childName: string,
    teacherName: string,
    classRoom: string,
    date: string,
    hour: string,
    status: string,
    numberOfTipoulim: string,
    id_elev: string,
    id_interv: string,
    brancheName: string | null,
    item: object,
  ): CourseData => {
    return {
      childName,
      teacherName,
      classRoom,
      date,
      hour,
      status,
      numberOfTipoulim,
      id_elev,
      id_interv,
      brancheName: brancheName !== null ? brancheName : 'לא הוגדר סניף',
      item,
    };
  };

  return (
    <>
      <Card className={classes.card}>
        <Grid spacing={2} direction="column" container>
          <Grid item xs={12}>
            <h1 className="primary">רשימת טיפולים</h1>
          </Grid>
          <Grid direction="row" item xs={12} justifyContent="flex-start" container style={{ direction: 'rtl' }}>
            <Grid item xs={3} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  PopoverProps={{ dir: 'rtl' }}
                  color="primary"
                  inputVariant="outlined"
                  id="date-picker-dialog-outlined-from"
                  format="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="מתאריך"
                  variant="inline"
                  className={classes.formControl}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  PopoverProps={{ dir: 'rtl' }}
                  color="primary"
                  inputVariant="outlined"
                  id="date-picker-dialog-outlined-to"
                  format="dd/MM/yyyy"
                  value={selectedToDate}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="עד תאריך"
                  variant="inline"
                  className={classes.formControl}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <CustomAutoComplete label="שם הילד" data={students} setValue={setChild} isLabelShrink={true} />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <CustomAutoComplete label="שם המטפל" data={intervenant} setValue={setTeacher} isLabelShrink={true} />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <InputLabel>סטטוס</InputLabel>
                <Select
                  multiple
                  value={selectedStatus}
                  onChange={handleChangeSelectedStatus}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {statusList.map((status) => (
                    <MenuItem key={status} value={status}>
                      <Checkbox color="primary" checked={selectedStatus.findIndex((s) => s === status) > -1} />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl color="primary" variant="outlined" className={classes.formControl}>
                <InputLabel>סניף</InputLabel>
                <Select
                  multiple
                  value={selectedBranches.map((b) => b.name)}
                  onChange={handleChangeSelectedBranches}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {brancheOptions?.map((branche) => (
                    <MenuItem key={branche.id} value={branche.name}>
                      <Checkbox color="primary" checked={selectedBranches.findIndex((b) => b.id === branche.id) > -1} />
                      <ListItemText primary={branche.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Ring color="#14738c" />
            ) : (
              <EnhancedTable headCells={cells} rows={displayRows} numberRows={20} orderBy="date" />
            )}
          </Grid>
        </Grid>
      </Card>
      <Dialog
        onClose={() => {
          setShowEditCourse(false);
        }}
        open={showEditCourse}
      >
        <DialogContent style={{ width: 500 }}>
          <EditCourse ok={CloseEdit} course={course} />
        </DialogContent>
      </Dialog>
    </>
  );
}
