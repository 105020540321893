import classNames from 'classnames';
import { Routes, Route, Link as RouterLink, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Store as StoreIcon, Person as PersonIcon } from '@material-ui/icons';
import { UnAuthorized } from '../unauthorized';
import { Container } from '../../components/container';
import { BranchesSettings } from '../../branches/branches-settings';
import { SallesSettings } from '../../salles/salles-settings';
import { Members } from '../../components/members';
import { getUser } from '../../components/utils/user';
import { Roles } from '../../components/utils/roles';

import { SettingsItem } from './types';
import styles from './settings.module.css';

const settingsItem: SettingsItem[] = [
  {
    title: 'סניפים',
    path: 'branches',
    icon: () => <StoreIcon />,
    component: () => <BranchesSettings />,
  },
  {
    title: 'חדרים',
    path: 'salles',
    icon: () => <StoreIcon />,
    component: () => <SallesSettings />,
  },
  {
    title: 'משתמשים',
    path: 'members',
    icon: () => <PersonIcon />,
    component: () => <Members />,
  },
];

export function Settings() {
  const user = getUser();
  const location = useLocation();

  const sidebar = () => (
    <div className={styles.sidebar} role="presentation">
      <List>
        {settingsItem.map(({ title, path, icon: Icon }) => {
          const activePath = location.pathname === `/settings/${path}`;
          return (
            <RouterLink key={path} to={path}>
              <ListItem
                selected={activePath}
                className={classNames(styles.itemLink, activePath && styles.active)}
                button
              >
                {Icon && (
                  <ListItemIcon className={classNames(styles.itemLink, activePath && styles.active)}>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={title} />
              </ListItem>
            </RouterLink>
          );
        })}
      </List>
    </div>
  );

  if (user.role !== Roles.Menahel) return <UnAuthorized />;

  return (
    <Container title="הגדרות">
      <div className={styles.settingsPage}>
        <div>{sidebar()}</div>
        <div className={styles.routeComponent}>
          <Routes>
            {settingsItem.map(({ path, component: Component }) => {
              return <Route key={path} path={path} element={<Component />} />;
            })}
          </Routes>
        </div>
      </div>
    </Container>
  );
}
