import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { NavigateBefore, NavigateNext, DoneAll } from '@material-ui/icons';
import { PersonalFile } from './personal-file';
import FamilyFile from './FamilyFile';
import MedicalFile from './MedicalFile';
import AcademicFile from './AcademicFile';
import DocumentFile from './DocumentFile';
import AboutPopup from './AboutPopup';
import BaseRequest from '../helpers/BaseRequest';
import ChildDataService from './ChildDataService';
import { ServerData } from './PersonalData';
import Logo from '../assets/shoshanim_transparent.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['מידע כללי אודות הילד', 'מידע משפחתי', 'מידע רפואי', 'מידע לימודי', 'מסמכים'];
}

function getStepContent(
  stepIndex: number,
  setFinish: Function,
  id: string,
  handleNext: Function,
  isEdit: boolean,
  personalServerData: ServerData[],
  setPersonalServerData: Function,
  familyServerData: ServerData[],
  setFamilylServerData: Function,
  academiclServerData: ServerData[],
  setAcademicServerData: Function,
  medicalServerData: ServerData[],
  setMedicalServerData: Function,
  documentServerData: ServerData[],
  setDocumentServerData: Function,
) {
  const steps = getSteps();
  switch (stepIndex) {
    case 0:
      return (
        <PersonalFile
          id={id}
          isEdit={isEdit}
          handleNext={handleNext}
          title={steps[stepIndex]}
          serverData={personalServerData}
          setServerData={setPersonalServerData}
        />
      );
    case 1:
      return (
        <FamilyFile
          id={id}
          isEdit={isEdit}
          handleNext={handleNext}
          title={steps[stepIndex]}
          serverData={familyServerData}
          setServerData={setFamilylServerData}
        />
      );
    case 2:
      return (
        <MedicalFile
          id={id}
          isEdit={isEdit}
          handleNext={handleNext}
          title={steps[stepIndex]}
          serverData={medicalServerData}
          setServerData={setMedicalServerData}
        />
      );
    case 3:
      return (
        <AcademicFile
          id={id}
          isEdit={isEdit}
          handleNext={handleNext}
          title={steps[stepIndex]}
          serverData={academiclServerData}
          setServerData={setAcademicServerData}
          setFinish={setFinish}
        />
      );
    case 4:
      return (
        <DocumentFile
          id={id}
          isEdit={isEdit}
          handleNext={handleNext}
          title={steps[stepIndex]}
          serverData={documentServerData}
          setServerData={setDocumentServerData}
          setFinish={setFinish}
        />
      );
    default:
      return 'Unknown stepIndex';
  }
}
interface ChildDetailsProps {
  isEdit: boolean;
  id?: string;
  scroll?: Function;
}
export default function ChildDetails(props: ChildDetailsProps) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [personalServerData, setPersonalServerData] = useState<ServerData[]>([]);
  const [familyServerData, setFamilylServerData] = useState<ServerData[]>([]);
  const [medicalServerData, setMedicalServerData] = useState<ServerData[]>([]);
  const [academiclServerData, setAcademicServerData] = useState<ServerData[]>([]);
  const [documentServerData, setDocumentServerData] = useState<ServerData[]>([]);
  const [labelBoutton, setLabelBoutton] = useState<string>();
  const [finish, setFinish] = useState(false);
  const steps = getSteps();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isEdit) {
      setLabelBoutton('שמירת שינויים');
    } else {
      setLabelBoutton('סיום ושליחת הטופס למזכירות המרכז');
    }
  }, [labelBoutton]);

  useEffect(() => {
    if (finish) handleNext();
  }, [finish]);

  function handleNext() {
    window.scrollTo(0, 0);
    if (props.scroll) props.scroll();
    if (activeStep === steps.length - 1 && finish) {
      setFinish(false);
      let firstName = personalServerData.find((p) => p.key === 'firstName')?.value;
      let lastName = personalServerData.find((p) => p.key === 'lastName')?.value;
      let arnona_file = documentServerData.find((p) => p.key === 'arnona_file')?.value;

      const formdata = new FormData();

      if (arnona_file != null) {
        for (var i = 0; i < arnona_file.length; i++) {
          var index = i + 1;
          var file = arnona_file[i];
          formdata.append('arnona_file_' + index, file);
        }
      }

      const json = {
        personal: personalServerData,
        family: familyServerData,
        academic: academiclServerData,
        medical: medicalServerData,
        file: documentServerData,
        firstName: firstName,
        lastName: lastName,
      };

      formdata.append('data', JSON.stringify(json));
      if (props.isEdit) {
        formdata.append('action', 'update');
        formdata.append('id', props.id || '');

        //append childId
      } else {
        formdata.append('action', 'add');
        const tempStudentAvatar = localStorage.getItem('temp_student_avatar_name');
        if (tempStudentAvatar) {
          formdata.append('student_avatar', tempStudentAvatar);
          localStorage.removeItem('temp_student_avatar_name');
        }
      }

      const headersOptions = {
        'Content-Type': 'multipart/form-data',
      };

      BaseRequest('student', formdata, headersOptions)
        .then((res) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (props.isEdit) navigate('/students'); //history.push('/students');
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (!finish && activeStep === steps.length - 1) {
      return;
    } else if (!finish && activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    window.scrollTo(0, 0);
    if (props.scroll) props.scroll();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grid container xs={12}>
      {!props.isEdit ? (
        <>
          <Grid
            container
            justifyContent="flex-start"
            style={{ marginTop: '1%', marginLeft: '1%', position: 'absolute' }}
            className="popUp"
          >
            <AboutPopup />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="center"
            style={{ margin: '0px' }}
            className="formulaire_container"
          >
            <Grid item style={{ paddingBottom: '20px' }}>
              <img style={{ width: 200 }} src={Logo} alt="" />
              <Typography variant="h5" color="secondary">
                {' '}
                ברוכים הבאים <br /> <b>טופס הרשמה למרכז</b>
              </Typography>
            </Grid>
            {/*   <Grid item>
            <Typography variant="h4" color="secondary"> טופס הרשמה למרכז שושנים</Typography>
          </Grid> */}
          </Grid>
        </>
      ) : null}
      <Grid
        item
        lg={8}
        xs={11}
        style={{ marginTop: '1%', marginBottom: '3%', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Card variant="outlined">
          <CardContent>
            <div className={classes.root}>
              <Stepper activeStep={activeStep} alternativeLabel style={{ direction: 'rtl' }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>כל השלבים הושלמו</Typography>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {getStepContent(
                        activeStep,
                        setFinish,
                        props.id ? props.id : '',
                        handleNext,
                        props.isEdit,
                        personalServerData,
                        setPersonalServerData,
                        familyServerData,
                        setFamilylServerData,
                        academiclServerData,
                        setAcademicServerData,
                        medicalServerData,
                        setMedicalServerData,
                        documentServerData,
                        setDocumentServerData,
                      )}
                    </Typography>
                    <Grid
                      item
                      spacing={4}
                      className="mrgTop20"
                      container
                      direction="row"
                      xs={12}
                      justifyContent="center"
                    >
                      <Grid item className={activeStep === steps.length - 1 ? 'endForm' : ''}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            ChildDataService.next$.next();
                          }}
                          style={{ height: '40px', fontSize: '20px' }}
                        >
                          {activeStep === steps.length - 1 ? (
                            <span className="nexticon">
                              <DoneAll />
                            </span>
                          ) : (
                            <span className="nexticon">
                              <NavigateBefore />
                            </span>
                          )}
                          {activeStep === steps.length - 1
                            ? props.isEdit
                              ? 'שמירת השינוים'
                              : 'סיום ושליחת הטופס למזכירות המרכז'
                            : 'המשך לשלב הבא'}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={activeStep === 0}
                          variant="outlined"
                          onClick={handleBack}
                          className={classes.backButton}
                          style={{ width: '200px', height: '40px', fontSize: '20px' }}
                        >
                          לשלב הקודם
                          <span className="previcon">
                            <NavigateNext />
                          </span>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        <Typography style={{ margin: '30px auto 0' }}>טל.02.3502992 - merkaz@shoshanim.net</Typography>
        <Typography style={{ margin: '0 auto' }}>שושנים לילדים - מנהל קהילתי גנים, דהומיי 7, קריית מנחם</Typography>
      </Grid>
    </Grid>
  );
}
