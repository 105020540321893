import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import BaseRequest from '../helpers/BaseRequest';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  icon: {
    marginTop: 2,
  },
  grid: {
    direction: 'rtl',
  },
  mainGrid: {
    marginTop: 3,
  },
  button: {
    marginRight: '8px',
  },
}));

interface HeaderProps {
  ok: Function;
}

export default function MedicalPriceForm(props: HeaderProps) {
  const classes = useStyles();
  const [meuhedetPrice, setMeuhedetPrice] = useState<any>();
  const [klalitPrice, setKlalitPrice] = useState<any>();
  const [leumitPrice, setLeumitPrice] = useState<any>();
  const [makabiPrice, setMakabiPrice] = useState<any>();

  useEffect(() => {
    BaseRequest('getPriceKoupatHolim').then((res) => {
      for (const koupat in res.data) {
        setMeuhedetPrice(res.data['מאוחדת']);
        setKlalitPrice(res.data['כללית']);
        setLeumitPrice(res.data['לאומית']);
        setMakabiPrice(res.data['מכבי']);
      }
    });
  }, []);

  const updateKoupatHolimPrice = () => {
    let priceKoupatHolim: any;
    const obj = {
      מאוחדת: meuhedetPrice,
      כללית: klalitPrice,
      לאומית: leumitPrice,
      מכבי: makabiPrice,
    };
    priceKoupatHolim = { ...obj };
    // let keys = Object.keys(priceKoupatHolim);
    const formData = new FormData();
    formData.append('data', JSON.stringify(priceKoupatHolim));
    BaseRequest('updatePriceKoupatHolim', formData).then((res) => props.ok());
  };

  return (
    <Grid spacing={3} direction="column" container className="rtl">
      <Grid container direction="row-reverse" spacing={3} justifyContent="center" item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            עדכון מימון
          </Typography>
        </Grid>
        <Grid className={classes.mainGrid} item>
          <AddIcon color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מימון של מאוחדת"
          variant="outlined"
          multiline
          value={meuhedetPrice}
          type="number"
          onChange={(e) => setMeuhedetPrice(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מימון של לאומית"
          variant="outlined"
          multiline
          value={leumitPrice}
          type="number"
          onChange={(e) => setLeumitPrice(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מימון של כללית"
          variant="outlined"
          multiline
          value={klalitPrice}
          type="number"
          onChange={(e) => setKlalitPrice(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מימון של מכבי"
          variant="outlined"
          multiline
          value={makabiPrice}
          type="number"
          onChange={(e) => setMakabiPrice(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item container className={classes.grid} direction="row" xs={12}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={updateKoupatHolimPrice}>
            אישור
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              props.ok();
            }}
            className={classes.button}
          >
            ביטול
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
