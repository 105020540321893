import React, { useState } from 'react';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import { Help as HelpIcon, Call as CallIcon, Email as EmailIcon } from '@material-ui/icons';
import { WhatsappButton } from '../whatsapp-button';
import styles from './support-button.module.css';

const registerIssueMessage = `
  שלום, אני מנסה לבצע הרשמה ונתקל בבעייה, אשמח לתמיכה טכנית. תודה
  `;

export function SupportButton() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.options, isOpen && styles.isOpen)}>
        <a href="mailto:merkaz@shoshanim.net">
          <IconButton size="medium">
            <EmailIcon color="primary" fontSize="large" />
          </IconButton>
        </a>
        <a href="tel:02-3502992">
          <IconButton size="medium">
            <CallIcon color="primary" fontSize="large" />
          </IconButton>
        </a>
        {/** phone number of the mazkirout, Liraz */}
        <WhatsappButton phoneNumber="972555002882" message={registerIssueMessage} />
      </div>
      <div className={styles.supportButton}>
        <IconButton size="medium" onClick={() => setOpen(!isOpen)}>
          <HelpIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
}
