import { ChildData } from './PersonalData';
export function FamilyData() {
  const arr: ChildData[] = [
    {
      type: 'check',
      id: 'maman',
      lable: 'אם',
      condition: false,
      textError: 'שדה חובה',
      isRequired: true,
    },
    {
      type: 'text',
      id: 'mName',
      lable: 'שם האם',
      kind: 'text',
      isRequired: true,
      condition: true,
      conditionById: ['maman'],
      conditionByIdValue: true,
    },
    {
      type: 'text',
      id: 'mTz',
      lable: 'תעודת זהות אם',
      kind: 'text',
      isRequired: true,
      condition: true,
      conditionById: ['maman'],
      conditionByIdValue: true,
    },
    {
      type: 'text',
      id: 'mMail',
      lable: 'מייל האם',
      kind: 'text',
      isRequired: true,
      condition: true,
      conditionById: ['maman'],
      conditionByIdValue: true,
    },
    {
      type: 'text',
      id: 'mProfession',
      lable: 'מקצוע אם',
      kind: 'text',
      isRequired: true,
      condition: true,
      conditionById: ['maman'],
      conditionByIdValue: true,
    },
    {
      type: 'check',
      id: 'papa',
      lable: 'אב',
      condition: false,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'fName',
      lable: 'שם האב',
      kind: 'text',
      condition: true,
      conditionById: ['papa'],
      conditionByIdValue: true,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'fTz',
      lable: 'תעודת זהות אב',
      kind: 'text',
      condition: true,
      conditionById: ['papa'],
      conditionByIdValue: true,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'fMail',
      lable: 'מייל האב',
      kind: 'text',
      condition: true,
      conditionById: ['papa'],
      conditionByIdValue: true,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'fProfession',
      lable: 'מקצוע אב',
      kind: 'text',
      condition: true,
      conditionById: ['papa'],
      conditionByIdValue: true,
      isRequired: true,
    },
    {
      type: 'check',
      id: 'tuteur',
      lable: 'אפוטרופוס',
      condition: false,
    },
    {
      type: 'text',
      id: 'TName',
      lable: 'שם אפוטרופוס ',
      kind: 'text',
      condition: true,
      conditionById: ['tuteur'],
      conditionByIdValue: true,
    },
    {
      type: 'text',
      id: 'TTz',
      lable: 'תעודת זהות אפוטרופוס ',
      kind: 'text',
      condition: true,
      conditionById: ['tuteur'],
      conditionByIdValue: true,
    },
    {
      type: 'text',
      id: 'TMail',
      lable: 'מייל אפוטרופוס ',
      kind: 'text',
      condition: true,
      conditionById: ['tuteur'],
      conditionByIdValue: true,
    },

    {
      type: 'text',
      id: 'TProfession',
      lable: 'מקצוע אפוטרופוס ',
      kind: 'text',
      condition: true,
      conditionById: ['tuteur'],
      conditionByIdValue: true,
    },
    {
      type: 'radio',
      id: 'familyState',
      lable: 'מצב משפחתי',
      condition: false,
      childrenLables: [
        { id: 'familyStateDivorcee', lable: 'גרושים' },
        { id: 'familyStateMarried', lable: 'נשואים' },
        { id: 'familyStateSingle', lable: 'רווק' },
      ],
      isRequired: true,
    },
    {
      type: 'textArea',
      id: 'appartenanceChild',
      lable: 'בחזקת מי הילד/ה ?',
      condition: true,
      conditionById: ['familyState'],
      conditionByIdValueRadio: ['familyStateDivorcee'],
    },
    {
      type: 'textArea',
      id: 'meetSecondParent',
      lable: 'כל כמה זמן הילד/ה נפגש/ת עם ההורה השני ובאיזו מתכונת ?',
      condition: true,
      conditionById: ['familyState'],
      conditionByIdValueRadio: ['familyStateDivorcee'],
    },
    {
      type: 'text',
      id: 'childrenCount',
      lable: 'מספר ילדים',
      kind: 'number',
      condition: false,
      isRequired: true,
    },
    {
      type: 'text',
      id: 'childNum',
      lable: 'מיקום הילד במשפחה',
      kind: 'text',
      condition: false,
    },
    {
      type: 'text',
      id: 'lang1',
      lable: 'שפות מדוברות',
      kind: 'text',
      condition: false,
      isRequired: true,
    },
    {
      type: 'textArea',
      id: 'comments',
      lable: 'הערות נוספות',
      condition: false,
    },
  ];
  return arr;
}
