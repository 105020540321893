import React, { useState, useEffect } from 'react';
import GenerateControls from './GenerateControls';
import { FileProps } from './personal-file';
import DocumentObject from './DocumentObject';
import { ServerData, ChildData } from './PersonalData';
import BaseRequest from '../helpers/BaseRequest';
export default function DocumentFile(props: FileProps) {
  const [arr, setArr] = useState<ChildData[]>(DocumentObject());

  const fetchData = () => {
    let server: ServerData[] = [];
    if (!props.isEdit) {
      arr.forEach((item, idx) => {
        item.id && server.push({ key: item.id, value: item.value !== undefined ? item.value : null });
      });
      props.setServerData(server);
    } else {
      let arrHelper = [...arr];
      const formData = new FormData();
      formData.append('student', props.id || '');
      BaseRequest('getStudent', formData)
        .then((res) => {
          let arrS: any[];
          let d = res.data[0].fileanaha.replace(/'/g, '"');
          arrS = [...JSON.parse(d)];
          props.setServerData(arrS);
          arrS.forEach((item, idx) => {
            if (item.key == 'arnona_file') {
              arrHelper[arrHelper.findIndex((i) => i.id === item.key)].valueFile = item.value;
            } else {
              arrHelper[arrHelper.findIndex((i) => i.id === item.key)].value = item.value;
            }
          });
          setArr(arrHelper);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <GenerateControls
      setFinish={props.setFinish}
      handleNext={props.handleNext}
      arr={arr}
      serverData={props.serverData}
      setServerData={props.setServerData}
      fileName="files"
      isEdit={props.isEdit}
      title={props.title}
    />
  );
}
