import { forwardRef, HTMLAttributes } from 'react';
import { Avatar } from '@material-ui/core';
import type { DataType } from './print-options-list';
import styles from './print-child-details.module.css';

export type PrintData = {
  title: string;
  data: DataType[];
};

type ComponentToPrintProps = {
  printData?: PrintData[];
  childImageUrl?: string;
} & HTMLAttributes<HTMLDivElement>;

function parseDataValue(value: string | number) {
  if (typeof value === 'number') {
    return value === 1 ? 'לא' : 'כן';
  }
  return value;
}

export const ComponentToPrint = forwardRef<HTMLDivElement, ComponentToPrintProps>(
  ({ printData, childImageUrl, className, ...rest }: ComponentToPrintProps, ref) => {
    return (
      <div className={styles.printContainer} ref={ref} {...rest}>
        {childImageUrl && <Avatar className={styles.image} alt="תמונת פרופיל" src={childImageUrl} />}
        {printData?.map(({ title, data }, index) => {
          return (
            <div key={`${index}-${title}`} className={styles.printDataHolder}>
              <div className={styles.titlePrint}>{title}</div>
              {data.map((item) => {
                return (
                  <div key={`${item.key}-${item.value}`} className={styles.printDataRow}>
                    <div style={{ fontWeight: 'bold' }}>{item.key}</div>
                    <div>{parseDataValue(item.value)}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  },
);
