import React, { useState, FormEvent } from 'react';
import { Typography, TextField, Button, MenuItem } from '@material-ui/core';
import { Facebook } from 'react-spinners-css';
import { Roles } from '../../utils/roles';
import { getUser } from '../../utils/user';
import BaseRequest from '../../../helpers/BaseRequest';
import { selectableForms } from './selectable-forms';
import styles from './default.module.css';

type CreateFormLinkProps = {
  callbackOnCreatedForm?: () => void;
};

export function CreateFormLink({ callbackOnCreatedForm }: CreateFormLinkProps) {
  const user = getUser();
  const [selectedForm, setSelectedForm] = useState(selectableForms[0].value);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectFormChange = (event: any) => {
    setSelectedForm(event.target.value);
  };

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // @ts-ignore
    const formControlsCollection = event.target.elements;
    const data = {
      school: formControlsCollection.school.value,
      year: formControlsCollection.year.value,
      class: formControlsCollection.class.value,
      studentsnumber: formControlsCollection.studentsnumber.value,
    };
    let allValid = true;
    Object.keys(data).forEach((key) => {
      //@ts-ignore
      if (!data[key]) {
        setError('יש למלא את כל השדות');
        allValid = false;
        return;
      }
    });
    if (allValid) {
      const formData: FormData = new FormData();
      formData.append('user_id', user.user_id);
      formData.append('form_id', selectedForm);
      formData.append('school', data.school);
      formData.append('year', data.year);
      formData.append('class', data.class);
      formData.append('students_number', data.studentsnumber);
      formData.append('created_date', new Date().toString());

      const headersOptions = {
        'Content-Type': 'multipart/form-data',
      };
      setLoading(true);
      await BaseRequest('addFormLink', formData, headersOptions)
        .then((result) => {
          callbackOnCreatedForm?.();
        })
        .catch((error) => {
          setError(error.toString());
        })
        .finally(() => {
          setLoading(false);
          // setNewCommentText('');
          // getStudentComments();
        });
    }
  };

  const handleOnChange = (event: FormEvent<HTMLFormElement>) => {
    setError('');
  };

  return (
    <div className={styles.sectionContainer}>
      <Typography variant="h5" style={{ marginBottom: 8 }}>
        יצירת לינק לטופס
      </Typography>
      <form className={styles.form} onSubmit={handleOnSubmit} onChange={handleOnChange} noValidate autoComplete="off">
        <TextField variant="outlined" select label="שאלון" value={selectedForm} onChange={handleSelectFormChange}>
          {selectableForms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={styles.input}
          label="בית ספר"
          // value={'דוגמה'}
          variant="outlined"
          name="school"
          dir="rtl"
        />
        <TextField
          className={styles.input}
          label="שנה"
          // value={'דוגמה'}
          variant="outlined"
          name="year"
          type="number"
        />
        <TextField
          className={styles.input}
          label="כיתה"
          // value={'דוגמה'}
          variant="outlined"
          name="class"
          dir="rtl"
        />
        <TextField
          className={styles.input}
          label="מספר תלמידים"
          // value={'דוגמה'}
          variant="outlined"
          type="number"
          name="studentsnumber"
        />
        <Button
          color="primary"
          variant={loading ? 'text' : 'contained'}
          type="submit"
          disabled={error !== '' || loading}
        >
          {loading ? <Facebook size={40} color="#14738c" style={{ display: loading ? '' : 'none' }} /> : 'יצירה'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </div>
  );
}
