import classNames from 'classnames';
import { Typography, Container as BaseContainer } from '@material-ui/core';
import type { ContainerProps as BaseContainerProps } from '@material-ui/core';
import styles from './container.module.css';

export type ContainerProps = {
  /**
   * Title of the page.
   */
  title?: string;
} & BaseContainerProps;

export function Container({ title, children, className }: ContainerProps) {
  return (
    <BaseContainer className={classNames(styles.container, className)} component="main">
      {title && (
        <Typography variant="h3" component="h1" className={styles.title}>
          {title}
        </Typography>
      )}
      {children}
    </BaseContainer>
  );
}
