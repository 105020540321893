import React, { useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Select,
  TextField,
  Button,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PersonAdd';
import BaseRequest from '../helpers/BaseRequest';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { Ellipsis } from 'react-spinners-css';
import { Roles } from './utils/roles';

const useStyles = makeStyles({
  direction: {
    direction: 'ltr',
  },
  icon: {
    marginTop: 2,
  },
  control: {
    width: '100%',
  },
});

interface AddTeacherProps {
  ok: Function;
}

export default function AddTeacher(props: AddTeacherProps) {
  const classes = useStyles();
  // const payoptions = ['180', '300'];
  const maavidOptions = ['שושנים', 'התפתחות הילד'];
  const activiteOptions = [
    'אף אוזן גרון',
    'מאבחן',
    'מטפלת קוגניטיבית',
    'מיקוד ראיה',
    'מרפה בעיסוק',
    'ספורט טיפולי',
    'פסיכיאטר',
    'פסיכולוג',
    'קלינאית תקשורת',
  ];
  const arr = ['מכבי', 'כללית', 'לאומית', 'מאוחדת'];
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tz, setTz] = useState('');
  const [kupa, setKupa] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [phone2, setPhone2] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activite, setActivite] = useState<any>();
  const [maavid, setMaavid] = useState<any>();
  const [payPerHouer, setPayPerHouer] = useState<any>();
  const [tarifBilan, setTarifBilan] = useState<any>();
  const [tarifReunion, setTarifReunion] = useState<any>();
  const [tarifBitoul, setTarifBitoul] = useState<any>();
  const [numberLessons, setNumberLessons] = useState<any>();
  const [bank, setBank] = useState('');
  const [info, setInfo] = useState('');
  const [errors, setErrors] = useState({
    email: false,
    tel: false,
    tz: false,
    prenom: false,
    nom: false,
    tarif_interv: false,
    maavid: false,
    password: false,
  });
  const [isLoading, setLoading] = useState(false);

  const createTeacher = async () => {
    let allValid = true;
    let newTeacher: any;
    const obj = {
      titre: '',
      nom: lastName,
      prenom: firstName,
      tz: tz,
      activite: activiteOptions[activite],
      tel: phone,
      tel2: phone2,
      email: email,
      password: password,
      adresse: address,
      iban: bank,
      koupa: kupa,
      maavid: maavidOptions[maavid],
      tarif_interv: payPerHouer,
      tarif_bilan: tarifBilan,
      tarif_reunion: tarifReunion,
      tarif_bitoul: tarifBitoul,
      number_lessons: numberLessons,
      infos: info,
    };
    newTeacher = { ...obj };
    let keys = Object.keys(errors);
    let errorsHelper: any;
    errorsHelper = { ...errors };
    keys.forEach((k) => {
      if (!newTeacher[k]) {
        console.log('error with: ', k);
        errorsHelper[k] = true;
        allValid = false;
      }
    });
    console.log('allValid', allValid);
    if (allValid) {
      setLoading(true);
      const formData = new FormData();
      formData.append('data', JSON.stringify(newTeacher));
      await BaseRequest('CreateInterv', formData).then(async (res) => {
        const newEmployee = {
          email: email,
          password: password,
          name: `${firstName} ${lastName}`,
          role: Roles.Intervenant,
          id_interv: res.id_interv,
        };
        const newEmployeeFormData = new FormData();
        newEmployeeFormData.append('data', JSON.stringify(newEmployee));
        await BaseRequest('CreateUser', newEmployeeFormData).then((res) => {
          setLoading(false);
          props.ok();
        });
      });
    } else setErrors(errorsHelper);
  };

  return (
    <Grid spacing={3} direction="column" container className={classes.direction}>
      <Grid justifyContent="center" container direction="row-reverse" spacing={3} item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            הוספת מטפל
          </Typography>
        </Grid>
        <Grid item>
          <PeopleIcon color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          label="שם פרטי"
          variant="outlined"
          error={!firstName && errors.prenom}
          helperText={!firstName && errors.prenom ? 'שם פרטי חובה' : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="שם משפחה"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          variant="outlined"
          error={!lastName && errors.nom}
          helperText={!lastName && errors.nom ? 'שם משפחה חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="תעודת זהות"
          value={tz}
          onChange={(e) => {
            setTz(e.target.value);
          }}
          variant="outlined"
          error={!tz && errors.tz}
          helperText={!tz && errors.tz ? 'תעודת זהות חובה' : ''}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <TextField className='maxWidth'
          id="outlined-basic"
          label="תחום"
          value={activite}
          onChange={(e) => { setActivite(e.target.value) }}
          variant="outlined" />
      </Grid> */}
      <Grid item xs={12}>
        <FormControl className="maxWidth" color="primary" variant="outlined">
          <InputLabel id="select-outlined-label-thoum">תחום</InputLabel>
          <Select
            labelId="select-outlined-label-thoum"
            label="תחום"
            value={activite}
            // error={item.isError && CheckEmptySelectValue}
            onChange={(e) => {
              setActivite(e.target.value);
            }}
          >
            {activiteOptions.map((p: string, idx: number) => {
              return (
                <MenuItem key={idx} value={idx}>
                  {activiteOptions[idx]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid justifyContent="center" container item xs={12} direction="row">
        {arr.map((a, idx: number) => {
          return (
            <FormControlLabel
              key={idx}
              control={
                <Checkbox
                  checked={kupa == a ? true : false}
                  onChange={(e) => {
                    setKupa(arr[idx]);
                  }}
                  color="primary"
                  value={idx + 1}
                />
              }
              label={arr[idx]}
            />
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          label="כתובת"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מס' טלפון"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          type="number"
          variant="outlined"
          error={!phone && errors.tel}
          helperText={!phone && errors.tel ? "מס' טלפון חובה" : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="טלפון 2"
          value={phone2}
          onChange={(e) => {
            setPhone2(e.target.value);
          }}
          type="number"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="אימייל"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          variant="outlined"
          error={!email && errors.email}
          helperText={!email && errors.email ? 'אימייל חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="סיסמה"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          variant="outlined"
          error={!password && errors.password}
          helperText={!password && errors.password ? 'סיסמה חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className="maxWidth" color="primary" variant="outlined">
          <InputLabel style={{ color: errors.maavid && !maavid ? '#f44336' : '' }} id="select-outlined-label-maavid">
            מעביד
          </InputLabel>
          <Select
            labelId="select-outlined-label-maavid"
            label="מעביד"
            id="select-outlined-maavid"
            value={maavid}
            error={errors.maavid && !maavid}
            onChange={(e) => {
              setMaavid(e.target.value);
            }}
          >
            {maavidOptions.map((p, idx) => {
              return (
                <MenuItem key={idx} value={idx}>
                  {maavidOptions[idx]}
                </MenuItem>
              );
            })}
          </Select>
          {errors.maavid && !maavid && <FormHelperText error={true}>מעביד חובה</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {/* <FormControl className="maxWidth" color="primary" variant="outlined">
          <InputLabel
            style={{ color: errors.tarif_interv && !payPerHouer ? '#f44336' : '' }}
            id="demo-simple-select-outlined-label"
          >
            תשלום לטיפול
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            label="תשלום לטיפול"
            value={payPerHouer}
            error={errors.tarif_interv && !payPerHouer}
            endAdornment={
              <InputAdornment style={{ marginLeft: '15px' }} position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            }
            onChange={(e) => {
              setPayPerHouer(e.target.value);
            }}
          >
            {payoptions.map((p, idx) => {
              return (
                <MenuItem key={idx} value={idx}>
                  {payoptions[idx]}
                </MenuItem>
              );
            })}
          </Select>
          {errors.tarif_interv && !payPerHouer && <FormHelperText error={true}>תשלום לטיפול חובה</FormHelperText>}
        </FormControl> */}
        <TextField
          className="maxWidth"
          label="תשלום לטיפול"
          value={payPerHouer}
          onChange={(e) => {
            setPayPerHouer(e.target.value);
          }}
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            ),
          }}
          error={errors.tarif_interv && !payPerHouer}
        />
        {errors.tarif_interv && !payPerHouer && <FormHelperText error={true}>תשלום לטיפול חובה</FormHelperText>}
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="תשלום לשעה טכנית"
          value={tarifBilan}
          onChange={(e) => {
            setTarifBilan(e.target.value);
          }}
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="תשלום לישיבת צוות"
          value={tarifReunion}
          onChange={(e) => {
            setTarifReunion(e.target.value);
          }}
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="תשלום לביטול"
          value={tarifBitoul}
          onChange={(e) => {
            setTarifBitoul(e.target.value);
          }}
          type="number"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faShekelSign} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מספר טיפולים שבועי"
          value={numberLessons}
          onChange={(e) => {
            setNumberLessons(e.target.value);
          }}
          type="number"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="פרטי חשבון"
          value={bank}
          onChange={(e) => {
            setBank(e.target.value);
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="מידע נוסף"
          value={info}
          multiline
          onChange={(e) => {
            setInfo(e.target.value);
          }}
          variant="outlined"
        />
      </Grid>

      <Grid item container direction="row" xs={12} style={{ direction: 'ltr' }}>
        <Grid item>
          <Button variant="contained" color="primary" disabled={isLoading} onClick={createTeacher}>
            {isLoading ? <Ellipsis size={24} color="white" /> : 'אישור'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            style={{ marginLeft: '8px' }}
            color="primary"
            onClick={() => {
              props.ok();
            }}
          >
            ביטול
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
