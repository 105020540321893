import React from 'react';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles.module.css';

export type HeadingProps = {
  formTitle: string;
  formDescription?: string;
  moreInfo?: string;
};

export function Heading({ formTitle, formDescription, moreInfo }: HeadingProps) {
  return (
    <>
      <Typography variant="h3" className={styles.marginBottom}>
        {formTitle}
      </Typography>
      <Typography variant="subtitle1" className={classNames(styles.marginBottom, styles.rightText)}>
        {formDescription}
      </Typography>
      <Typography variant="subtitle1" className={classNames(styles.marginBottom, styles.rightText)}>
        פרטי השאלון הנוכחי: {moreInfo}
      </Typography>
    </>
  );
}
