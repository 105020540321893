import React, { useState } from 'react';
import { Grid, Typography, Radio, FormControlLabel, TextField, Button } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PersonAdd';
import BaseRequest from '../../helpers/BaseRequest';
import { makeStyles } from '@material-ui/core/styles';
import { Ellipsis } from 'react-spinners-css';
import { Roles } from '../utils/roles';

const useStyles = makeStyles({
  direction: {
    direction: 'ltr',
  },
  icon: {
    marginTop: 2,
  },
  control: {
    width: '100%',
  },
});

type AddEmployeeProps = {
  ok: Function;
};

export function AddEmployee(props: AddEmployeeProps) {
  const classes = useStyles();
  const roleOptions = [
    { value: 'מנהלת ללא כספים', id: Roles.MenahelBliKsafim },
    { value: 'מזכירה', id: Roles.Secretaire },
    { value: 'מנהלת', id: Roles.Menahel },
  ];
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState<Roles>(Roles.Secretaire);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
  });
  const [isLoading, setLoading] = useState(false);

  const createEmployee = async () => {
    let isValidForm = true;
    const newEmployee = {
      email: email,
      password: password,
      name: name,
      role: role,
    };
    let errorsHelper = { ...errors };

    Object.keys(errors).forEach((k) => {
      // @ts-ignore
      if (!newEmployee[k]) {
        // @ts-ignore
        errorsHelper[k] = true;
        isValidForm = false;
      }
    });
    if (isValidForm) {
      setLoading(true);
      const formData = new FormData();
      formData.append('data', JSON.stringify(newEmployee));
      await BaseRequest('CreateUser', formData).then((res) => {
        setLoading(false);
        props.ok();
      });
    } else {
      setErrors(errorsHelper);
      setLoading(false);
    }
  };

  return (
    <Grid spacing={3} direction="column" container className={classes.direction}>
      <Grid justifyContent="center" container direction="row-reverse" spacing={3} item xs={12}>
        <Grid item>
          <Typography color="primary" variant="h5">
            הוספת משתמש
          </Typography>
        </Grid>
        <Grid item>
          <PeopleIcon color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          label="שם מלא"
          variant="outlined"
          error={!name && errors.name}
          helperText={!name && errors.name ? 'שם מלא חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="אימייל"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          variant="outlined"
          error={!email && errors.email}
          helperText={!email && errors.email ? 'אימייל חובה' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="maxWidth"
          id="outlined-basic"
          label="סיסמה"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          variant="outlined"
          error={!password && errors.password}
          helperText={!password && errors.password ? 'סיסמה חובה' : ''}
          type="password"
          autoComplete="new-password"
        />
      </Grid>
      <Grid justifyContent="center" container item xs={12} direction="row">
        {roleOptions.map((option, idx: number) => {
          return (
            <FormControlLabel
              key={idx}
              control={
                <Radio
                  checked={role === option.id ? true : false}
                  onChange={(e) => setRole(option.id)}
                  color="primary"
                  value={idx + 1}
                />
              }
              label={option.value}
            />
          );
        })}
      </Grid>
      <Grid item container direction="row" xs={12} style={{ direction: 'ltr' }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={createEmployee}>
            {isLoading ? <Ellipsis size={24} color="white" /> : 'אישור'}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" style={{ marginLeft: '8px' }} color="primary" onClick={() => props.ok()}>
            ביטול
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
