import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import BaseRequest from '../helpers/BaseRequest';
import AddComment from './AddComment';
import ChildDataService from './ChildDataService';
import EnhancedTable, { HeadCell } from './Table';
import { getUser } from './utils/user';
import { appendLeadingZeroes } from '../times/append-leading-zero';

export interface PastLessonsData {
  fullName: string;
  date_cours: string;
  item: any;
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: 'black',
    textDecoration: 'underline',
  },
  content: {
    // width: 500, height: 210
    overflow: 'hidden',
  },
}));

export default function PastLessons() {
  const [rows, setRows] = useState<PastLessonsData[]>([]);
  const [showAddComment, setShowAddComment] = useState(false);
  const [course, setCourse] = useState<any>();
  const user = getUser();
  const classes = useStyles();

  useEffect(() => {
    getPastCours();
  }, []);

  const getPastCours = async () => {
    const formData = new FormData();
    // showBatel false will not show canceled cours to metapel.
    const json = { option: 'past', id_interv: user.interv_id || '', role: user.role, dontShowBatel: true };
    formData.append('data', JSON.stringify(json));
    await BaseRequest('getCours', formData)
      .then((res: { data: any[] }) => {
        let teacherData: PastLessonsData[] = [];
        res.data.forEach((item) => {
          let date_cours_format = new Date(item.date_cours);
          let date_cours =
            appendLeadingZeroes(date_cours_format.getDate()) +
            '-' +
            appendLeadingZeroes(date_cours_format.getMonth() + 1) +
            '-' +
            date_cours_format.getFullYear();
          teacherData.push(createData(`${item.student_firstname} ${item.student_lastname}`, date_cours, item));
        });
        setRows(teacherData);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const clickIcon = (item: any) => {
    setCourse(item);
    setShowAddComment(true);
  };
  const CloseDialog = () => {
    setShowAddComment(false);
    ChildDataService.commentNext$.next();
  };

  const headCells: HeadCell[] = [
    { id: 'fullName', label: 'שם', isSortable: true },
    { id: 'date_cours', label: 'תאריך', isSortable: true },
    {
      id: 'edit',
      label: 'הערה',
      type: 'icon',
      handleClickIcon: clickIcon,
      icon: <AddCircle />,
      icon_if_comment: <Done />,
      isSortable: false,
    },
  ];

  const createData = (fullName: string, date_cours: string, item: any): PastLessonsData => {
    return {
      fullName,
      date_cours,
      item,
    };
  };
  return (
    <>
      <Grid direction="column" container justifyContent="flex-start">
        <Grid direction="row" item xs={12} justifyContent="flex-end" container>
          <Grid item xs={3}>
            <p className={classes.text}>הטיפולים שעברו</p>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EnhancedTable headCells={headCells} rows={rows} numberRows={10} />
        </Grid>
      </Grid>

      <Dialog onClose={() => setShowAddComment(false)} open={showAddComment}>
        <DialogContent className={classes.content}>
          <AddComment ok={CloseDialog} course={course} />
        </DialogContent>
      </Dialog>
    </>
  );
}
