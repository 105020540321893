import React, { useState, useEffect } from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import BaseRequest from '../../helpers/BaseRequest';
import styles from './personal-file.module.css';

export const TEMP_STUDENT_AVATAR_NAME = 'temp_student_avatar_name';

export function StudentAvatar({
  avatarUrl,
  idElev,
  error = false,
}: {
  avatarUrl: string;
  idElev: string;
  error?: boolean;
}) {
  const [showDropdZone, setShowDropZone] = useState(false);
  const [imageUrl, setImageUrl] = useState(avatarUrl);
  const [avatarError, setAvatarError] = useState(error);

  useEffect(() => {
    setImageUrl(avatarUrl);
  }, [avatarUrl]);

  useEffect(() => {
    setAvatarError(error);
  }, [error]);

  const uploadStudentAvatar = (file: any) => {
    const formData = new FormData();
    formData.append('id_elev', idElev);
    formData.append(`file`, file);

    const headersOptions = {
      'Content-Type': 'multipart/form-data',
    };

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    BaseRequest('uploadStudentAvatar', formData, headersOptions)
      .then((res) => {
        console.log('res', res);
        setImageUrl(`https://backend.shoshanim.net/students-avatar/${res.data}`);
        setShowDropZone(false);
        if (idElev === '') {
          // the image was saved, but not assigned to a student yet, save the name in the local storage and send it with the student request.
          localStorage.setItem(TEMP_STUDENT_AVATAR_NAME, res.data);
        }
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarZone}>
        <Avatar className={styles.image} alt="תמונת פרופיל" src={imageUrl} />
        <div className={styles.editButton}>
          <IconButton aria-label="delete" onClick={() => setShowDropZone(!showDropdZone)}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className={styles.legalText} style={{ color: avatarError ? 'red' : '' }}>
        יש לצרף תמונה עדכנית של הילד ( לשימוש ולזיהוי פנימי בלבד).
      </div>
      {showDropdZone && (
        <DropzoneArea
          dropzoneClass={styles.dropzoneArea}
          dropzoneText="העלה תמונת פרופיל"
          filesLimit={1}
          showAlerts={['error']}
          acceptedFiles={['image/*']}
          alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
          onChange={(e) => {
            uploadStudentAvatar(e[0]);
            setAvatarError(false);
          }}
        />
      )}
    </div>
  );
}
