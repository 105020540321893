import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Roles } from '../../utils/roles';
import { getUser } from '../../utils/user';
import BaseRequest from '../../../helpers/BaseRequest';
import { selectableForms } from './selectable-forms';
import { FormLinkInfo } from '../form-link-info';
import { FormLink } from './types';
import styles from './default.module.css';

function createData(
  form_link_id: string,
  user_id: string,
  username: string,
  form_id: string,
  school: string,
  year: string,
  className: string,
  students_number: string,
  created_date: string,
): FormLink {
  return {
    form_link_id,
    user_id,
    username,
    form_id,
    formName: selectableForms.find((form) => form.value === form_id)?.label,
    school,
    year,
    className,
    students_number,
    created_date: new Date(created_date),
  };
}

type ExistingFormLinksProps = {
  callGetFormLinks?: boolean;
};

export function ExistingFormLinks({ callGetFormLinks }: ExistingFormLinksProps) {
  const user = getUser();
  const [formLinks, setFormLinks] = useState<FormLink[]>([]);
  const [openFormLinkInfoModal, setOpenFormLinkInfoModal] = useState(false);
  const [selectedFormLink, setSelectedFormLink] = useState<any>({});

  useEffect(() => {
    getFormLinks();
  }, [callGetFormLinks]);

  const getFormLinks = async () => {
    await BaseRequest('getFormLinks')
      .then((result) => {
        if (result.data) {
          const rows: FormLink[] = result.data.map((value: any) =>
            createData(
              value.form_link_id,
              value.user_id,
              value.username,
              value.form_id,
              value.school,
              value.year,
              value.class,
              value.students_number,
              value.created_date,
            ),
          );
          setFormLinks(rows);
        }
      })
      .catch((error) => {});
  };

  const handleFormLinkInfoModal = () => {
    setOpenFormLinkInfoModal(!openFormLinkInfoModal);
  };

  return (
    <div className={styles.sectionContainer} style={{ marginTop: 48 }}>
      <Typography variant="h5" style={{ marginBottom: 8 }}>
        שאלונים קיימים
      </Typography>
      <TableContainer dir="rtl" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeadCell}>יוצר הלינק</TableCell>
              <TableCell className={styles.tableHeadCell}>שם השאלון</TableCell>
              <TableCell className={styles.tableHeadCell}>בית הספר</TableCell>
              <TableCell className={styles.tableHeadCell}>שנה</TableCell>
              <TableCell className={styles.tableHeadCell}>כיתה</TableCell>
              <TableCell className={styles.tableHeadCell}>מספר התלמידים</TableCell>
              <TableCell className={styles.tableHeadCell}>מידע</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formLinks
              .sort((a, b) => (a.created_date.getTime() < b.created_date.getTime() ? 1 : -1))
              .map((row) => (
                <TableRow key={row.form_link_id}>
                  <TableCell component="th" scope="row">
                    {row.username}
                  </TableCell>
                  <TableCell>{row.formName}</TableCell>
                  <TableCell>{row.school}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.className}</TableCell>
                  <TableCell>{row.students_number}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => {
                        handleFormLinkInfoModal();
                        setSelectedFormLink(row);
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={openFormLinkInfoModal}
        onClose={handleFormLinkInfoModal}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card style={{ width: '95vw', height: '95vh', display: 'flex', flexDirection: 'column' }}>
          <CardContent style={{ height: 'calc(100% - 47px)' }}>
            <FormLinkInfo key={`form-link-info-${selectedFormLink.form_link_id}`} formLink={selectedFormLink} />
          </CardContent>
          <CardActions>
            <Button variant="contained" size="small" onClick={handleFormLinkInfoModal}>
              סגור
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
}
