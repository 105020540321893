import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, Box, makeStyles, Theme, useTheme, Card, CardContent, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EditTeacher from './EditTeacher';
import { DropzoneArea } from 'material-ui-dropzone';
import BaseRequest from '../helpers/BaseRequest';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  card: {
    width: '70%',
    margin: '4% 15%',
  },
  typography: {
    marginTop: 15,
    marginBottom: 15,
  },
  // content: {
  //   minHeight: "545px",
  //   maxHeight: "545px",
  //   overflowY: "auto"
  // },
  dropZone: {
    direction: 'rtl!important' as any,
  },
  alert_hide: {
    display: 'none',
  },
  alert_display: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },
  alert_hide_empty: {
    display: 'none',
  },
  alert_display_empty: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },
}));

export default function ViewTeacher(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [documents, setDocuments] = useState<any[]>([]);
  const [classeAlert, setClasseAlert] = useState(classes.alert_hide);
  const [classeAlertEmpty, setClasseAlertEmpty] = useState(classes.alert_hide_empty);
  const location = useLocation();
  // @ts-ignore
  let params = location.state.teacher;
  console.log('params', params);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    const formData: FormData = new FormData();
    let data = {
      id_prof: params.id_interv,
      token: localStorage.getItem('token'),
    };
    formData.append('data', JSON.stringify(data));
    BaseRequest('getDocumentProf', formData).then((res) => {
      setDocuments(res.data);
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const scroll = () => {
    document.getElementById('card')?.scrollTo(0, 0);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Card className={classes.card}>
      <CardContent id="card">
        <Grid spacing={2} container>
          <Grid justifyContent="center" item xs={12}>
            <Typography className={classes.typography} color="primary" variant="h5">
              {`${params.nom} ${params.prenom}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              className="rtl"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="נתונים אישיים" {...a11yProps(0)} />
              <Tab label="טפסים" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <EditTeacher scroll={scroll} isEdit={true} id={params} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card variant="outlined">
                <CardContent>
                  <Grid item xs={12} className={classes.dropZone}>
                    <DropzoneArea
                      dropzoneText="להעלות קבצים"
                      onDrop={(e) => {
                        setSelectedFiles(e);
                      }}
                    />
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      {/* {
                          documents.map((file,idx)=>{
                            return <iframe key={idx} src={"http://51.91.110.239/"+file.lien_doc} style={{width:'400px',height: '500px'}}></iframe>
                          })
                        } */}
                    </CardContent>
                  </Card>
                  <Grid item xs={12} style={{ marginTop: '15px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (selectedFiles.length == 0) {
                          setClasseAlertEmpty(classes.alert_display_empty);
                          return;
                        }
                        const json = {
                          id_prof: props.history.location.state.teacher.id_interv,
                          token: localStorage.getItem('token'),
                        };
                        var formData = new FormData();
                        formData.append('data', JSON.stringify(json));
                        for (var i = 0; i < selectedFiles.length; i++) {
                          formData.append(`files[]`, selectedFiles[i]);
                        }
                        const headersOptions = {
                          'Content-Type': 'multipart/form-data',
                        };
                        BaseRequest('uploadFileProf', formData, headersOptions).then((res) => {
                          if (res.success == 1) {
                            setClasseAlert(classes.alert_display);
                          }
                        });
                      }}
                    >
                      אישור
                    </Button>
                    <Alert variant="outlined" severity="success" className={classeAlert}>
                      <span style={{ marginLeft: '3px' }}>הקבצים נשמרו בהצלחה</span>
                    </Alert>
                    <Alert variant="outlined" severity="error" className={classeAlertEmpty}>
                      <span style={{ marginLeft: '3px' }}>אין קבצים</span>
                    </Alert>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
