import { User } from '../../../models/user';
import { getRole } from '../roles';

export const getUser = (): User => {
  const user: User = {
    userName: localStorage.getItem('userName') || '',
    user_id: localStorage.getItem('user_id') || '',
    interv_id: localStorage.getItem('id') || '',
    role: getRole(),
    token: localStorage.getItem('token') || '',
    color: localStorage.getItem('user_color') || '',
  };

  return user;
};
